import React, { Fragment } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import { sideBarNavWidth, sideBarNavMiniWidth } from "../../../assets/theme/generalTheme";
import clsx from "clsx";
import Hidden from "@material-ui/core/Hidden";
// import SelectMUI from "select-mui";
import { useTranslation } from "react-i18next";
import AccessControlIcon from "../../../assets/icons/AccessControlIcon";
import DomCameraIcon from "../../../assets/icons/DomCameraIcon";
import { shallowEqual, useSelector } from "react-redux";
import StoreStateModel from "../../Utils/Models/store";
import { PortalIds } from "../../Utils/Models/account";
import { pathRoute } from "../../Utils/Routes/routes";
import { useHistory } from "react-router-dom";
import SelectMUI from "../../Utils/Custom/Selects/SelectMUI";
import RouterIcon from "@material-ui/icons/Router";

const drawerWidth = sideBarNavWidth;
const drawerMiniWidth = sideBarNavMiniWidth;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerColor: {
      borderRight: 0,
      boxShadow: "none",
      backgroundColor: theme.palette.secondary.main + "E2",
      [theme.breakpoints.up("sm")]: {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: drawerMiniWidth,
      // "&:hover": {
      //   //this is to show the Drawer completely when the mouse is hover the drawer
      //   width: drawerWidth,
      // },
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerContainer: {
      overflowX: "hidden",
    },
    drawerOpenTemporary: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerCloseTemporary: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
    },
    withNavigationBar: {
      marginTop: theme.spacing(2),
    },
  });
});
interface Props {
  openSideBarNav: boolean;
  children: any;
  handleDrawerToggle?: () => void;
  handleOpenSideBarNavClicked: () => void;
  isShowingNavigationBar?: boolean;
}
const PortalSelectComponent = React.memo((props: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const portal = useSelector((state: StoreStateModel) => state.general.portalId, shallowEqual);
  const selectedPortal = {
    label: t(portal),
    value: portal,
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingRight: props.isExpanded ? "10px" : "1px",
        paddingLeft: props.isExpanded ? "10px" : "1px",
      }}
    >
      <div
        style={{
          width: "100%",
          minWidth: "54px",
        }}
      >
        <SelectMUI
          hasIcons={true}
          overrideStyleOptions={
            props.isExpanded
              ? null
              : {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }
          }
          overrideStyleSingleValue={
            props.isExpanded
              ? null
              : {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingLeft: "9px",
                  cursor: "pointer",
                }
          }
          hideIndicatorSeparator={true}
          hideDropdownIndicator={props.isExpanded ? false : true}
          hideSingleValueLabel={props.isExpanded ? false : true}
          variant={"outlined"}
          label={""}
          options={[
            {
              label: props.isExpanded ? t(PortalIds.AccessControl) : "",
              value: PortalIds.AccessControl,
              icon: AccessControlIcon, //'/images/designed/access-control-icon.svg'
            },
            {
              label: props.isExpanded ? t(PortalIds.Cameras) : "",
              value: PortalIds.Cameras,
              icon: DomCameraIcon, //'/images/designed/camera-drawer-icon.svg'
            },
            // {
            //   label: props.isExpanded ? t(PortalIds.Sensors) : "",
            //   value: PortalIds.Sensors,
            //   icon: SensorIcon,//'/images/designed/camera-drawer-icon.svg'
            // }
            // ...(process.env.REACT_APP_HIDE_GATEWAY == "true"
            //   ? []
            //   : [
            //       {
            //         label: props.isExpanded ? t(PortalIds.Gateway) : "",
            //         value: PortalIds.Gateway,
            //         icon: RouterIcon,
            //       },
            //     ]),
          ]}
          isMulti={false}
          backspaceRemovesValue={false}
          escapeClearsValue
          hideSelectedOptions={false}
          noOptionsMessage={() => t("Empty")}
          isLoading={false}
          isClearable={false}
          isSearchable={false}
          closeMenuOnSelect={true}
          //////////
          isDisabled={false}
          value={selectedPortal}
          name="selectedPortal"
          onChangeEvent={async (option: any) => {
            if (option.value === PortalIds.AccessControl) {
              history.replace(pathRoute.Dashboard);
            } else if (option.value === PortalIds.Cameras) {
              history.replace(pathRoute.Cameras.Index);
            } else {
              history.replace(pathRoute.Dashboard);
            }
          }}
        />
      </div>
    </div>
  );
});
PortalSelectComponent.displayName = "PortalSelectComponent";

const SideBarNav = React.memo((props: Props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <Hidden xsDown implementation="js">
        <Drawer
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: props.openSideBarNav,
            [classes.drawerClose]: !props.openSideBarNav,
          })}
          classes={{
            paper: clsx(classes.drawerColor, {
              [classes.drawerOpen]: props.openSideBarNav,
              [classes.drawerClose]: !props.openSideBarNav,
              [classes.withNavigationBar]: props.isShowingNavigationBar,
            }),
          }}
          variant="permanent"
          transitionDuration={400}
          open
          PaperProps={{ elevation: 3 }}
        >
          <Toolbar />

          <div style={{ width: "100%", paddingBottom: "0px", paddingTop: "8px" }}>
            <PortalSelectComponent isExpanded={props.openSideBarNav} />
          </div>
          <div className={classes.drawerContainer}>{props.children}</div>
        </Drawer>
      </Hidden>
      <Hidden smUp implementation="js">
        <Fragment>
          <Drawer
            variant="temporary"
            open={props.openSideBarNav}
            onClose={props.handleDrawerToggle && props.handleDrawerToggle}
            className={clsx(classes.drawer, {
              [classes.drawerOpenTemporary]: props.openSideBarNav,
              [classes.drawerCloseTemporary]: !props.openSideBarNav,
            })}
            classes={{
              paper: clsx(classes.drawerColor, {
                [classes.drawerOpenTemporary]: props.openSideBarNav,
                [classes.drawerCloseTemporary]: !props.openSideBarNav,
                [classes.withNavigationBar]: props.isShowingNavigationBar,
              }),
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            // onClick={props.handleDrawerToggle && props.handleDrawerToggle}
          >
            <div style={{ width: "100%", paddingBottom: "0px", paddingTop: "8px" }}>
              <PortalSelectComponent isExpanded={props.openSideBarNav} />
            </div>
            <div className={classes.drawerContainer}>{<>{props.children}</>}</div>
          </Drawer>
        </Fragment>
      </Hidden>
    </div>
  );
});
SideBarNav.displayName = "SideBarNav";
export default SideBarNav;
