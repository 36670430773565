import React, { ReactElement, useEffect, useRef, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next/";
import Grid from "@material-ui/core/Grid";
import { getCloudStorangeImage, getDeviceWebRTCCredentials } from "../../API/apiDevices";
import { AWSCredentialResponseModel } from "../../Models/devices";
import {
  FirestorePathRoute,
  NotificationConnectionStatusEnum,
  NotificationModel,
} from "../../Models/notifications";
import { shallowEqual, useSelector } from "react-redux";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import StoreStateModel from "../../Models/store";
import Page403 from "../../Custom/Page403";
import useFireStoreSubscription from "../Hooks/useFireStoreSubscription";
import Typography from "@material-ui/core/Typography";
import LiveCamera from "./LiveCamera";
import DialogWrapperVideo from "./DialogWrapperVideo";
import "webrtc-adapter";
import CardContent from "@material-ui/core/CardContent";
import { getCloudVideoCredential } from "../../API/apiAuth";
import { GetObjectOutput } from "aws-sdk/clients/s3";
import Data from "../../Constants/values";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    content: {
      flexGrow: 1,
      // padding: theme.spacing(3),
    },
    video: {
      marginBottom: "-5px",
      objectFit: "contain",
      width: "100%  !important",
      height: "auto   !important",
      // position: "absolute",
      left: 0,
      top: 0,
      display: "inline-block",
    },
    title: {
      marginBottom: theme.spacing(2),
      flex: 1,
    },
    container: {
      // marginTop: theme.spacing(2),
    },
    card: {
      width: "100%",
    },
  })
);
interface Props {
  serial: string;
  name: string;
  onClose: () => void;
  openVideo: boolean;
  hasWebCredential: boolean;
  lastImage?: string;
}
const DoorVideo = ({
  serial: serialNumber,
  name: deviceName,
  openVideo,
  onClose,
  hasWebCredential,
  lastImage,
}: Props): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [webRTCCredentials, setWebRTCCredentials] = useState<AWSCredentialResponseModel>();

  const [isConnected, setIsConnected] = useState(true);

  const hasPermissionDoorRead = useSelector(
    (state: StoreStateModel) => state.auth.hasPermissionDoorRead,
    shallowEqual
  );
  const accountId = useSelector((state: StoreStateModel) => state.auth.accountId, shallowEqual);

  const [lastDeviceImage, setLastDeviceImage] = useState<string | undefined>(lastImage || "");
  const [webRTCDisabled, setWebRTCDisabled] = useState(false);
  const [reloadLiveWhenTheReconnectButtonPressed, setReloadLiveWhenTheReconnectButtonPressed] =
    useState<boolean>(true);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const load = async () => {
      try {
        setLoading(true);
        const responseDevice = await getDeviceWebRTCCredentials(serialNumber, signal);
        if (!responseDevice.data.hasErrors) {
          const aWSCredentialResponseModel: AWSCredentialResponseModel =
            responseDevice.data.payload;

          if (!aWSCredentialResponseModel.isVideoEnabled) {
            setWebRTCDisabled(true);
          } else {
            setWebRTCCredentials(aWSCredentialResponseModel);
          }
        }
      } catch (error) {
        console.error("error");
      } finally {
        setLoading(false);
      }
    };
    if (serialNumber && isConnected && openVideo) {
      load();
    }
    if (!isConnected) {
      setWebRTCCredentials(null as any);
    }
    return () => {
      controller.abort();
    };
  }, [serialNumber, isConnected, openVideo]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const load = async () => {
      try {
        setLoading(true);
        try {
          const response = await getCloudVideoCredential(signal);
          if (!response.data.hasErrors) {
            const credential: AWSCredentialResponseModel = response.data.payload;
            const lastImageURL = await getTheBase64Image(
              "snapshot_high",
              credential as AWSCredentialResponseModel
            );
            setLastDeviceImage(lastImageURL);
          }
        } catch (error) {
          //
        }
      } catch (error) {
        console.error("error");
      } finally {
        setLoading(false);
      }
    };
    if (serialNumber && isConnected && openVideo) {
      load();
    }

    return () => {
      controller.abort();
    };
  }, [serialNumber, isConnected, openVideo]);

  const getTheBase64Image = async (imageTitle: string, credentials: AWSCredentialResponseModel) => {
    const isCacheControl = true;
    try {
      const response: GetObjectOutput = await getCloudStorangeImage(
        accountId as unknown as string,
        serialNumber,
        imageTitle,
        credentials,
        isCacheControl
      );
      const imageBase64 = Buffer.from(response.Body as any).toString("base64");
      const imageUrl = `data:image/jpeg;base64,${imageBase64}`;
      return imageUrl;
    } catch (err: any) {
      return undefined;
      //
    } finally {
      //
    }
  };

  const refIsStepToFiveSeconds = useRef<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      refIsStepToFiveSeconds.current = true;
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useFireStoreSubscription(
    FirestorePathRoute.Devices.Index,
    FirestorePathRoute.Devices.ConnectionStatus,
    (data: NotificationModel[] | NotificationModel) =>
      newConnectionStatusNotification(data as NotificationModel),
    serialNumber
  );

  const newConnectionStatusNotification = (data: NotificationModel) => {
    if (refIsStepToFiveSeconds.current) {
      setIsConnected(
        data.Code === NotificationConnectionStatusEnum.NotificationDeviceConnected ? true : false
      );
    }
  };
  if (!hasPermissionDoorRead) {
    return <Page403 />;
  }

  const prefix = "INT";
  const isDeviceIntercom = serialNumber.split("-")[1].startsWith(prefix);

  return (
    <DialogWrapperVideo
      title={deviceName}
      open={openVideo}
      onClose={() => {
        onClose();
      }}
      fullScreen={false}
      isDeviceIntercom={isDeviceIntercom}
    >
      <Grid className={classes.card}>
        {loading ? (
          <Grid container>
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          </Grid>
        ) : null}

        <Grid item xs={12} className={classes.container}>
          {webRTCCredentials && reloadLiveWhenTheReconnectButtonPressed ? (
            <LiveCamera
              serialNumber={serialNumber}
              deviceName={deviceName}
              isConnected={isConnected}
              webRTCCredentials={webRTCCredentials as any}
              hasWebCredential={hasWebCredential}
              posterB64={lastDeviceImage}
              localMedia={undefined}
              onClose={onClose}
              onReloadLive={() => {
                setLoading(true);
                setReloadLiveWhenTheReconnectButtonPressed(false);
                setTimeout(() => {
                  setReloadLiveWhenTheReconnectButtonPressed(true);
                  setLoading(false);
                }, 400);
              }}
            />
          ) : webRTCDisabled ? (
            <Grid container justify="center" className={classes.title}>
              <Typography variant="body1" color="error">
                {t("error_device_webrtc_disabled")}
              </Typography>
            </Grid>
          ) : null}
          {!isConnected ? (
            <Grid container>
              <Grid item>
                <CardContent>
                  <Grid container justify="center">
                    <Typography variant="body1" color="error">
                      {t("LostConnectionWithTheDoor")}
                    </Typography>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </DialogWrapperVideo>
  );
};
export default DoorVideo;
