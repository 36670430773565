import {
  Credentials,
  SignalingClient,
  SignalingClientConfig,
} from "amazon-kinesis-video-streams-webrtc/lib/SignalingClient";
import { getDeviceWebRTCCredentials } from "../../../API/apiDevices";
import { AWSCredentialResponseModel, RTCPeerViewer } from "../../../Models/devices";
import {
  ChannelProtocol,
  DescribeSignalingChannelCommand,
  GetSignalingChannelEndpointCommand,
  KinesisVideoClient,
  ResourceEndpointListItem,
} from "@aws-sdk/client-kinesis-video";
import { Role } from "amazon-kinesis-video-streams-webrtc";
import { v4 as uuid } from "uuid";

export async function openDataChannelPeerConnection(
  serialNumber: string,
  userId: string,
  onError: (code: string) => void
): Promise<RTCPeerViewer | undefined> {
  const region = process.env.REACT_APP_AWS_REGION as string;

  const channelName = serialNumber;
  const clientId = userId + "_" + uuid();
  let credentials: Credentials | undefined = undefined;
  const controller = new AbortController();
  const signal = controller.signal;
  const response = await getDeviceWebRTCCredentials(serialNumber, signal);
  if (!response.data.hasErrors) {
    credentials = (response.data.payload as AWSCredentialResponseModel).credential;
  } else {
    if (!response.data.payload.isVideoEnabled) {
      onError("error_device_webrtc_disabled");
    }
  }
  const rtcPeerViewer: RTCPeerViewer = {
    peerConnection: null,
    signalingClient: null,
    serialNumber: serialNumber,
    dataChannel: null,
  };
  const kinesisClient = new KinesisVideoClient({
    region,
    credentials,
  });
  const channelArn = await obtainChannelARN(kinesisClient, channelName);
  if (channelArn !== "error") {
    const endpoints = await obtainChannelEndpoints(kinesisClient, channelArn);
    if (endpoints.length) {
      const iceServers = await obtainIceServers(region, credentials);
      if (iceServers.length && credentials) {
        settingPeerViewer(
          rtcPeerViewer,
          endpoints,
          iceServers,
          channelArn,
          clientId,
          region,
          credentials
        );
        await addingListeners(rtcPeerViewer, channelName, onError);
        return rtcPeerViewer;
      } else {
        onError("Error_iceServers");
      }
    } else {
      onError("Error_channelEndpoints");
    }
  } else {
    onError("Error_channelArn");
  }
}

async function obtainChannelARN(client: KinesisVideoClient, channelName: string): Promise<string> {
  const params = {
    ChannelName: channelName,
  };
  const command = new DescribeSignalingChannelCommand(params);
  try {
    const response = await client.send(command);
    if (
      response.ChannelInfo?.ChannelARN !== "" &&
      response.ChannelInfo?.ChannelStatus === "ACTIVE"
    ) {
      return response.ChannelInfo?.ChannelARN as string;
    } else {
      throw new Error("The requested channel is not found or not active");
    }
  } catch (error: any) {
    // onError(ErrorType.ObtainChannelARN, error?.message);
    return "error";
  }
}

async function obtainChannelEndpoints(
  client: KinesisVideoClient,
  channelArn: string
): Promise<ResourceEndpointListItem[]> {
  const params = {
    ChannelARN: channelArn,
    SingleMasterChannelEndpointConfiguration: {
      Protocols: ["HTTPS", "WSS"],
      Role: Role.VIEWER,
    },
  };
  const command = new GetSignalingChannelEndpointCommand(params);
  try {
    const response = await client.send(command);
    if (response.ResourceEndpointList?.length) {
      return response.ResourceEndpointList;
    } else {
      throw new Error("The requested channel is not found or not active");
    }
  } catch (error: any) {
    // onError(ErrorType.ObtainEndpoints, error?.message);
    return [];
  }
}

function obtainIceServers(region: string, credentials: AWSCredentialResponseModel) {
  return [
    { urls: process.env.REACT_APP_STUN as string },
    { urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443` },
    {
      urls: credentials.webRTCSettings.urls,
      username: credentials.webRTCSettings.username,
      credential: credentials.webRTCSettings.credential,
    },
  ] as RTCIceServer[];
}

function settingPeerViewer(
  rtcPeerViewer: RTCPeerViewer,
  endpoints: ResourceEndpointListItem[],
  rtcIceServers: RTCIceServer[],
  channelArn: string,
  clientId: string,
  region: string,
  credentials: Credentials
) {
  const endpointWSS = endpoints.find(
    (endpoint) => endpoint.Protocol === ChannelProtocol.WSS
  ) as ResourceEndpointListItem;
  const rtcConfiguration: RTCConfiguration = {
    iceServers: rtcIceServers,
    iceTransportPolicy: "all",
  };

  rtcPeerViewer.peerConnection = new RTCPeerConnection(rtcConfiguration);
  rtcPeerViewer.signalingClient = new SignalingClient({
    channelARN: channelArn,
    channelEndpoint: endpointWSS.ResourceEndpoint,
    clientId: clientId,
    role: Role.VIEWER,
    region: region,
    credentials: credentials,
  } as SignalingClientConfig);

  rtcPeerViewer.signalingClient.open();
}

function addingListeners(
  rtcPeerViewer: RTCPeerViewer,
  channelName: string,
  onError: (code: string) => void
) {
  const trickleICE = true;

  rtcPeerViewer.signalingClient?.on("open", async () => {
    try {
      const offer: RTCSessionDescriptionInit = (await rtcPeerViewer.peerConnection?.createOffer({
        // iceRestart: false, //https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/createOffer
        offerToReceiveVideo: false,
        offerToReceiveAudio: false,
      } as RTCOfferOptions)) as RTCSessionDescriptionInit;
      await rtcPeerViewer.peerConnection?.setLocalDescription(offer);
      if (trickleICE) {
        const sdpOffer: RTCSessionDescription = rtcPeerViewer.peerConnection
          ?.localDescription as RTCSessionDescription;
        rtcPeerViewer.signalingClient?.sendSdpOffer(sdpOffer);
      }
    } catch (error: any) {
      console.error("ErrorType.SendSDPOffer", error);
      onError("ErrorType.SendSDPOffer");
      //error?.message
    }
  });
  ////
  rtcPeerViewer.signalingClient?.on(
    "iceCandidate",
    async (candidate: RTCIceCandidateInit | RTCIceCandidate) => {
      // Add the ICE candidate received from the MASTER to the peer connection
      try {
        await rtcPeerViewer.peerConnection?.addIceCandidate(candidate);
      } catch (error: any) {
        console.error("ErrorType.AddIceCandidate", error);
        onError("ErrorType.AddIceCandidate");
      }
    }
  );
  ////
  rtcPeerViewer.signalingClient?.on("sdpAnswer", async (answer: RTCSessionDescriptionInit) => {
    try {
      await rtcPeerViewer.peerConnection?.setRemoteDescription(answer);
    } catch (error: any) {
      console.error("ErrorType.SetRemoteDescription", error);
      onError("ErrorType.SetRemoteDescription");
    }
  });
  ////
  rtcPeerViewer.peerConnection &&
    (rtcPeerViewer.peerConnection.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
      if (event.candidate) {
        const iceCandidate: RTCIceCandidate = new RTCIceCandidate(event.candidate);
        if (trickleICE) {
          rtcPeerViewer.signalingClient?.sendIceCandidate(iceCandidate);
        }
      } else {
        if (!trickleICE) {
          const sdpOffer: RTCSessionDescription = rtcPeerViewer.peerConnection
            ?.localDescription as RTCSessionDescription;
          rtcPeerViewer.signalingClient?.sendSdpOffer(sdpOffer);
        }
      }
    });
  ////
  rtcPeerViewer.peerConnection &&
    (rtcPeerViewer.peerConnection.ontrack = (event: RTCTrackEvent) => {
      console.log("track", event);
    });
  ////
  rtcPeerViewer.peerConnection &&
    (rtcPeerViewer.peerConnection.oniceconnectionstatechange = (event: Event) => {
      switch (rtcPeerViewer.peerConnection?.iceConnectionState) {
        case "failed":
          // One or more transports has terminated unexpectedly or in an error
          //   onError(ErrorType.Failed, "Failed");
          // console.log("failed --- iceConnectionState");

          break;
        case "disconnected":
          // console.log("disconnected --- iceConnectionState");
          //   onDisconnected();
          onError("disconnected");
          break;
        case "closed":
          // The connection has been closed
          //   onDisconnected();
          // console.log("closed --- iceConnectionState");

          break;
        case "checking":
          // console.log("checking --- iceConnectionState");

          //
          break;
        case "connected":
          // The connection has become fully connected
          // console.log("connected --- iceConnectionState");

          break;
      }
    });
  ////
  const temp1 = channelName.replaceAll("-", "_");
  // info: Im not using this datachannel, i create this to avoid an error in the master when i create a connection without video, audio.
  rtcPeerViewer.dataChannel =
    rtcPeerViewer.peerConnection &&
    rtcPeerViewer.peerConnection.createDataChannel("chat_" + temp1, {
      ordered: true,
      // negotiated: true,
    });
  rtcPeerViewer.dataChannel &&
    (rtcPeerViewer.dataChannel.onerror = () => {
      //
    });
  ////
  rtcPeerViewer.peerConnection &&
    (rtcPeerViewer.peerConnection.onconnectionstatechange = (event: Event) => {
      switch (rtcPeerViewer.peerConnection?.connectionState) {
        case "new":
          //
          // console.log("new --- onconnectionstatechange");

          break;
        case "connected":
          // The connection has become fully connected
          //   onConnected();
          // console.log("connected --- onconnectionstatechange");

          break;
        case "failed":
          // One or more transports has terminated unexpectedly or in an error
          //   rtcPeerViewer.peerConnection?.close();
          //   rtcPeerViewer.signalingClient?.close();
          //   onError(ErrorType.Failed, "Failed");
          // console.log("failed --- onconnectionstatechange");
          onError("failed");

          break;
        case "disconnected":
          //   onDisconnected();
          //   rtcPeerViewer.peerConnection?.close();
          //   rtcPeerViewer.signalingClient?.close();
          // console.log("disconnected --- onconnectionstatechange");
          onError("disconnected");

          break;
        case "closed":
          // The connection has been closed
          // console.log("closed - onconnectionstatechange ");
          //   rtcPeerViewer.peerConnection?.close();
          //   rtcPeerViewer.signalingClient?.close();
          //   onDisconnected();
          // console.log("closed --- onconnectionstatechange");

          rtcPeerViewer.peerConnection?.close();
          rtcPeerViewer.signalingClient?.close();
          rtcPeerViewer.dataChannel?.close();
          break;
      }
    });
  ////
  rtcPeerViewer.peerConnection &&
    (rtcPeerViewer.peerConnection.onsignalingstatechange = (event: Event) => {
      //https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/signalingState
      // "closed" | "have-local-offer" | "have-local-pranswer" | "have-remote-offer" | "have-remote-pranswer" | "stable"
      switch (rtcPeerViewer.peerConnection?.signalingState) {
        case "stable":
          // One or more transports has terminated unexpectedly or in an error
          // console.log("stable");
          break;
        case "have-remote-pranswer":
          // console.log("have-remote-pranswer");
          break;
        case "have-remote-offer":
          // console.log("have-remote-offer");
          break;
        case "have-local-offer":
          // console.log("have-local-offer");
          /*The local peer has called RTCPeerConnection.setLocalDescription(), passing in SDP representing an offer (usually created by calling RTCPeerConnection.createOffer()), and the offer has been applied successfully. */
          // onError(ErrorType.NoMasterConnected, "have-local-offer");

          break;
        case "have-local-pranswer":
          // console.log("have-local-pranswer");
          break;
        case "closed":
          // console.log("closed onsignalingstatechange");
          //   rtcPeerViewer.peerConnection?.close();
          //   rtcPeerViewer.signalingClient?.close();
          break;
      }
    });
  ////
  rtcPeerViewer.signalingClient &&
    rtcPeerViewer.signalingClient.on("error", (error) => {
      console.error("ErrorType.SignalingClientError", error);
      //   onError(ErrorType.SignalingClientError, error?.message);
    });
  ////
  rtcPeerViewer.signalingClient &&
    rtcPeerViewer.signalingClient.on("close", () => {
      rtcPeerViewer.peerConnection = null;
      rtcPeerViewer.signalingClient = null;
      rtcPeerViewer.dataChannel?.close();
    });
}
