import React from "react";
import * as actionTypes from "./actionTypes";
import { firebase } from "../../components/Auth/firebase";
import { axiosInstance } from "../../components/Utils/API/axiosConfig";
import {
  createAccount,
  saveTheSignInError,
  sendEmailVerificationAPI,
} from "../../components/Utils/API/apiAuth";
import { QueryFilter, Response } from "../../components/Utils/Models/api";
import {
  resetGeneralStoreValues,
  showErrorInterceptor,
  showLoadingComponent,
  updateDidCallAPILockdownInfo,
} from "./general_actions";
import * as Sentry from "@sentry/react";
import { Severity } from "@sentry/react";
import StoreStateModel from "../../components/Utils/Models/store";
import { skipAPIErrorCode } from "../../components/Utils/Constants/skipApiErrorCode";
import { UserAccessModel, UserModel } from "../../components/Utils/Models/users";
import { getLoggedUserDetails, getUserAccess } from "../../components/Utils/API/apiUsers";
import { hideElementBecauseLocationIsPublic } from "../../components/Utils/Custom/HelperCode";
import { EScopesType } from "../../components/Utils/Models/auth";
import { getAllDevices } from "../../components/Utils/API/apiDevices";
import { DeviceType } from "../../components/Utils/Models/devices";
import axios from "axios";
import {
  AccountSettingsModel,
  AddonsEnum,
  AddonsModel,
  StatusType,
} from "../../components/Utils/Models/account";
import { getAccountAddons } from "../../components/Utils/API/apiRoles";
import { getAccountSettings } from "../../components/Utils/API/apiSettings";
import { pathRoute } from "../../components/Utils/Routes/routes";

const requestSignIn = () => {
  return {
    type: actionTypes.SIGNIN_REQUEST,
  };
};

const receiveSignIn = (decodedToken: any) => {
  return {
    type: actionTypes.SIGNIN_SUCCESS,
    decodedToken,
  };
};

const updateToken = (refreshToken: any) => {
  return {
    type: actionTypes.UPDATE_TOKEN,
    refreshToken,
  };
};
const updateUserAccess = (userAccess: UserAccessModel) => {
  return {
    type: actionTypes.UPDATE_USER_ACCESS,
    userAccess,
  };
};
const signInError = (errorCode: string) => {
  return {
    type: actionTypes.SIGNIN_FAILURE,
    errorCode,
  };
};

const requestSignOut = () => {
  return {
    type: actionTypes.SIGNOUT_REQUEST,
  };
};

const receiveSignOut = () => {
  return {
    type: actionTypes.SIGNOUT_SUCCESS,
  };
};

const signOutError = (errorCode: string) => {
  return {
    type: actionTypes.SIGNOUT_FAILURE,
    errorCode,
  };
};

const verifyRequest = () => {
  return {
    type: actionTypes.VERIFY_REQUEST,
  };
};

const verifySuccess = () => {
  return {
    type: actionTypes.VERIFY_SUCCESS,
  };
};

export const reauthenticateCredentialRequest = () => {
  return {
    type: actionTypes.REAUTHENTICATE_CREDENTIAL_REQUEST,
  };
};

const reauthenticateCredentialSuccess = () => {
  return {
    type: actionTypes.REAUTHENTICATE_CREDENTIAL_SUCCESS,
  };
};

const reauthenticateCredentialFailure = (errorCode: string) => {
  return {
    type: actionTypes.REAUTHENTICATE_CREDENTIAL_FAILURE,
    errorCode,
  };
};

const updatePasswordRequest = () => {
  return {
    type: actionTypes.UPDATE_PASSWORD_REQUEST,
  };
};

const updatePasswordSuccess = () => {
  return {
    type: actionTypes.UPDATE_PASSWORD_SUCCESS,
  };
};

const updatePasswordFailure = (errorCode: string) => {
  return {
    type: actionTypes.UPDATE_PASSWORD_FAILURE,
    errorCode,
  };
};

const signUpRequest = () => {
  return {
    type: actionTypes.SIGNUP_REQUEST,
  };
};

const signUpSuccess = () => {
  return {
    type: actionTypes.SIGNUP_SUCCESS,
  };
};

const signUpFailure = (errorCode: string) => {
  return {
    type: actionTypes.SIGNUP_FAILURE,
    errorCode,
  };
};

const verifyEmailRequest = () => {
  return {
    type: actionTypes.VERIFY_EMAIL_REQUEST,
  };
};

const verifyEmailSuccess = () => {
  return {
    type: actionTypes.VERIFY_EMAIL_SUCCESS,
  };
};

const verifyEmailFailure = (errorCode: string) => {
  return {
    type: actionTypes.VERIFY_EMAIL_FAILURE,
    errorCode,
  };
};

const verifyPasswordResetCodeRequest = () => {
  return {
    type: actionTypes.VERIFY_PASSWORD_RESETCODE_REQUEST,
  };
};

const verifyPasswordResetCodeSuccess = (email: string) => {
  return {
    type: actionTypes.VERIFY_PASSWORD_RESETCODE_SUCCESS,
    email,
  };
};

const verifyPasswordResetCodeFailure = (errorCode: string) => {
  return {
    type: actionTypes.VERIFY_PASSWORD_RESETCODE_FAILURE,
    errorCode,
  };
};

const confirmPasswordResetRequest = () => {
  return {
    type: actionTypes.CONFIRM_PASSWORD_RESET_REQUEST,
  };
};

const confirmPasswordResetSuccess = () => {
  return {
    type: actionTypes.CONFIRM_PASSWORD_RESET_SUCCESS,
  };
};

const confirmPasswordResetFailure = (errorCode: string) => {
  return {
    type: actionTypes.CONFIRM_PASSWORD_RESET_FAILURE,
    errorCode,
  };
};

const sendVerificationEmailRequest = () => {
  return {
    type: actionTypes.SEND_VERIFICATION_EMAIL_REQUEST,
  };
};

const sendVerificationEmailSuccess = () => {
  return {
    type: actionTypes.SEND_VERIFICATION_EMAIL_SUCCESS,
  };
};

const sendVerificationEmailFailure = (errorCode: string) => {
  return {
    type: actionTypes.SEND_VERIFICATION_EMAIL_FAILURE,
    errorCode,
  };
};

export const changeLanguage = (locale: string) => {
  return {
    type: actionTypes.CHANGE_LANGUAGE,
    locale,
  };
};

const verifyTypeOfDevice = () => {
  return {
    type: actionTypes.VERIFY_TYPE_OF_DEVICE,
  };
};

export const updateApprovalSettings = (isApproved: boolean) => {
  return {
    type: actionTypes.UPDATE_APPROVAL_SETTINGS,
    isApproved,
  };
};

// Dispatch section
///////////////////
async function obtainTheUserAccess(token: string): Promise<UserAccessModel> {
  let userAccess: UserAccessModel = {
    role: {
      id: 0,
      displayName: "",
      description: "",
      rank: 0,
      isAdmin: false,
    },
    sites: [],
    scopes: [],
    hasSensors: false,
    hasMasNotification: false,
    hasElevator: false,
    hasPassRequiresApprovalSettings: false,
    hasIntercom: false,
    ///
    mobileCredential: undefined,
    avatar: undefined,
    mobileId: undefined,
    firstName: "",
    lastName: "",
    fullName: "",
    localeISO2: "en",
    phoneNumber: undefined,
    email: "",
    hasEnrolledFace: false,
    status: StatusType.Active,
  };
  try {
    const response = await getUserAccess(token);
    if (!response.data.hasErrors) {
      userAccess = response.data.payload;

      if (
        userAccess.scopes.length === 2 &&
        userAccess.scopes.find((scope: EScopesType) => scope === EScopesType.mobile_r)
      ) {
        return userAccess;
      }

      const responseUser = await getLoggedUserDetails(token);
      if (!responseUser.data.hasErrors) {
        const temp: UserModel = responseUser.data.payload;
        userAccess.mobileCredential = temp.mobileCredential;
        userAccess.avatar = temp.picture;
        userAccess.firstName = temp.firstName;
        userAccess.lastName = temp.lastName;
        userAccess.fullName = temp.fullName;
        userAccess.localeISO2 = temp.localeISO2;
        userAccess.phoneNumber = temp.phoneNumber;
        userAccess.email = temp.email;
        userAccess.hasEnrolledFace = temp.hasEnrolledFace;
        userAccess.status = temp.status;
        userAccess.mobileId = temp.mobileId;
      }
      const responseDevices = await getAllDevices(
        {
          pageNumber: 1,
          pageSize: 100,
          deviceType: [DeviceType.Intercoms],
        } as QueryFilter,
        undefined,
        token
      );
      if (!responseDevices.data.hasErrors) {
        const listDevices = responseDevices.data.payload;
        for (let index = 0; index < listDevices.length; index++) {
          if (listDevices[index].deviceType === DeviceType.Intercoms && !userAccess.hasIntercom) {
            userAccess.hasIntercom = true;
          }
        }
      }

      const responseAddons = await getAccountAddons(token);
      if (!responseAddons.data.hasErrors) {
        const temp: AddonsModel[] = responseAddons.data.payload;
        temp.forEach((addon) => {
          if (
            addon.code === AddonsEnum.MASSNOTIFY &&
            userAccess.scopes.find((scope) => scope === EScopesType.lockdown_w)
          ) {
            userAccess.hasMasNotification = true;
          }
          if (
            addon.code === AddonsEnum.ELEVATOR &&
            userAccess.scopes.find((scope) => scope === EScopesType.lockdown_w)
          ) {
            userAccess.hasElevator = true;
          }
        });
      }
      const controllerSettings = new AbortController();
      const signalSettings = controllerSettings.signal;
      const responseSettings = await getAccountSettings(signalSettings, token);
      if (!responseSettings.data.hasErrors) {
        const temp: AccountSettingsModel = responseSettings.data.payload;
        userAccess.hasPassRequiresApprovalSettings = temp.passRequiresApproval;
      }
    }
    return userAccess;
  } catch (error) {
    return userAccess;
  }
}
export const getIdTokenResult = async (forceRefresh?: boolean) => {
  //https://firebase.google.com/docs/reference/js/firebase.auth.IDTokenResult#properties
  const token: any = firebase
    .auth()
    .currentUser?.getIdTokenResult(forceRefresh)
    .then((token: any) => {
      return token;
    })
    .catch((error) => {
      return undefined;
    });
  return token;
};

export const refreshToken = () => {
  return async (dispatch: any, getState: any) => {
    dispatch(showLoadingComponent(true));
    const refreshToken = await getIdTokenResult(true);
    if (refreshToken) {
      const userAccess = await obtainTheUserAccess(refreshToken.token);

      if (
        userAccess.scopes.length === 2 &&
        userAccess.scopes.find((scope: EScopesType) => scope === EScopesType.mobile_r)
      ) {
        // dispatch(signOutUser());
        window.location.replace(pathRoute.SignIn.MobileCredential);
        return;
      }
      dispatch(updateUserAccess(userAccess));
      dispatch(updateToken(refreshToken));
      dispatch(showLoadingComponent(false));
      dispatch(updateDidCallAPILockdownInfo(false));
    } else {
      dispatch(showErrorInterceptor("SessionExpire"));
      dispatch(signOutUser());
    }
  };
};

export const signInUser = (email: string, password: string) => {
  statusIfUserAccessWasCalled = false;
  return async (dispatch: any, getState: any) => {
    dispatch(requestSignIn());
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password) //https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithemailandpassword
      .then(async (user: any) => {
        // const decodedToken = await user.user.getIdTokenResult().then((token: any) => {
        //   return token;
        // });
        // let tempUser: any = JSON.parse(JSON.stringify(user.user))
        // dispatch(receiveSignIn(tempUser, decodedToken)) // this not necessary because Ill get with verifyAuth
      })
      .catch((error: any) => {
        dispatch(signInError(error.code));
        saveTheSignInError(error); //Saved the error in the backend
      });
  };
};
let statusIfUserAccessWasCalled = false;

export const verifyAuth = () => {
  return async (dispatch: any, getState: () => StoreStateModel) => {
    dispatch(verifyRequest());
    dispatch(verifyTypeOfDevice());
    //onAuthStateChanged method which looks for a preexisting user session and re-establishes it. In our case this will happen on refresh. This method also sets up a listener while the app is running to change user session tokens when they expire.
    //https://firebase.google.com/docs/reference/android/com/google/firebase/auth/FirebaseAuth.AuthStateListener
    firebase.auth().onAuthStateChanged(async (user: any) => {
      if (user !== null) {
        const decodedToken = await user.getIdTokenResult().then((token: any) => {
          return token;
        });
        // const tempUser: any = JSON.parse(JSON.stringify(user))
        if (
          (!getState().auth.scopes || !getState().auth.scopes?.length) &&
          !hideElementBecauseLocationIsPublic(window.location.pathname) &&
          !statusIfUserAccessWasCalled
        ) {
          statusIfUserAccessWasCalled = true;
          const userAccess = await obtainTheUserAccess(decodedToken.token);
          if (
            userAccess.scopes.length === 2 &&
            userAccess.scopes.find((scope: EScopesType) => scope === EScopesType.mobile_r)
          ) {
            window.location.replace(pathRoute.SignIn.MobileCredential);
            return;
          }
          dispatch(updateUserAccess(userAccess));
        }
        dispatch(receiveSignIn(decodedToken));
        // Start token refresh monitoring
        dispatch(monitorTokenRefresh());
      }
      dispatch(verifySuccess());
    });
  };
};

export const signOutUser = () => {
  statusIfUserAccessWasCalled = false;
  return (dispatch: any) => {
    dispatch(requestSignOut());
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(receiveSignOut());
        dispatch(resetGeneralStoreValues());
      })
      .catch((error: any) => {
        dispatch(signOutError(error.code));
      });
  };
};
////////////////////
//Axios interceptors

export const interceptors1 = (store: any) => {
  axiosInstance.interceptors.request.use(
    (conf: any) => {
      // I can attach the token here in each request but Im doing in the reducer
      // conf.headers['Auth'] = 'some token'

      //TODO: refresh token in each request if firebase dont handle it
      return conf;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (response: Response) => {
      const data = response.data;
      if (data.hasErrors) {
        if (data.applicationEvents[0].code.length) {
          if (!skipAPIErrorCode.some((errorCode) => errorCode === data.applicationEvents[0].code)) {
            store.dispatch(showErrorInterceptor(data.applicationEvents[0].code));
          }

          Sentry.captureMessage("HasError:" + data.applicationEvents[0].code, {
            level: Severity.Log,
            contexts: {
              errorInfo: {
                response: JSON.stringify(response),
                applicationEvents: JSON.stringify(data.applicationEvents),
              },
            },
          });
        } else {
          //show the "message" text, if "hasError" and the "code" is empty
          store.dispatch(showErrorInterceptor(data.applicationEvents[0].message));
          Sentry.captureMessage("HasError:" + data.applicationEvents[0].message, {
            level: Severity.Log,
            contexts: {
              errorInfo: {
                response: JSON.stringify(response),
                applicationEvents: JSON.stringify(data.applicationEvents),
              },
            },
          });
        }
      }
      return Promise.resolve(response);
    },
    (error: any) => {
      Sentry.captureMessage(error.response?.status || error.message, {
        level: Severity.Log,
        contexts: {
          errorInfo: {
            response: JSON.stringify(error.response),
          },
        },
      });
      if (error.response?.status === 401) {
        store.dispatch(signOutUser());
        store.dispatch(showErrorInterceptor("SessionExpire"));
      } else if (error.response?.status === 403) {
        store.dispatch(showErrorInterceptor("ForbiddenAccess"));
      } else if (error.response?.status === 404) {
        store.dispatch(showErrorInterceptor("ResourceNotFound"));
      } else if (error.response?.status >= 500) {
        store.dispatch(showErrorInterceptor("SystemError"));
      } else if (error.response?.status === 415) {
        store.dispatch(showErrorInterceptor("SystemError"));
      } else if (error.message === "CancelAPIRequest") {
        // This empty statement prevents from returning
        // 'Unknown Error' when canceling requests on account switch
      } else if (axios.isCancel(error)) {
        //cancelled by controller.abort();
        //[CanceledError: canceled]
      } else {
        console.log("error.response?.data", error.response?.data);
        if (error.response?.data) {
          store.dispatch(showErrorInterceptor(error.response?.data.applicationEvents[0].code));
        } else {
          // store.dispatch(showErrorInterceptor("UnknownError"));
        }
      }
      return Promise.reject(error);
    }
  );
};
////////////////

export const reauthenticateWithCredential = (oldPassword: string) => {
  return async (dispatch: any, getState: any) => {
    dispatch(reauthenticateCredentialRequest());
    const user: any = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email as string,
      oldPassword
    );
    //https://firebase.google.com/docs/reference/js/firebase.User#reauthenticatewithcredential
    user
      .reauthenticateWithCredential(credential)
      .then(async (user: any) => {
        const token = await getIdTokenResult();

        dispatch(updateToken(token));
        dispatch(reauthenticateCredentialSuccess());
      })
      .catch((error: any) => {
        dispatch(reauthenticateCredentialFailure(error.code));
      });
  };
};

export const updatePassword = (newPassword: string) => {
  return async (dispatch: any, getState: any) => {
    dispatch(updatePasswordRequest());
    const user: any = firebase.auth().currentUser;
    //https://firebase.google.com/docs/reference/js/firebase.User#updatepassword
    user
      .updatePassword(newPassword)
      .then((user: any) => {
        dispatch(updatePasswordSuccess());
      })
      .catch((error: any) => {
        dispatch(updatePasswordFailure(error.code));
      });
  };
};

export const signUpUser = (data: any) => {
  return async (dispatch: any, getState: any) => {
    dispatch(signUpRequest());
    createAccount(data)
      .then((response: Response) => {
        try {
          const data = response.data;
          if (data.hasErrors) {
            dispatch(signUpFailure(data.applicationEvents[0].code));
          } else {
            dispatch(signUpSuccess());
          }
        } catch (err) {
          dispatch(signUpFailure("InternalError"));
        }
      })
      .catch((error: any) => {
        try {
          dispatch(signUpFailure(error.response.applicationEvents[0].code));
          const errorResponse: Response = error.response;
          console.error(errorResponse);
        } catch (err) {
          dispatch(signUpFailure("InternalError"));
        }
      });
  };
};

export const verifyEmailUser = (oobCode: string, tenantId: string) => {
  return (dispatch: any) => {
    dispatch(verifyEmailRequest());
    if (tenantId && tenantId.length) {
      firebase.auth().tenantId = tenantId;
    }
    //https://firebase.google.com/docs/reference/js/firebase.auth.Auth#applyactioncode
    firebase
      .auth()
      .applyActionCode(oobCode)
      .then(() => {
        dispatch(verifyEmailSuccess());
      })
      .catch((error: any) => {
        dispatch(verifyEmailFailure(error.code));
      });
  };
};

export const verifyPasswordResetCode = (oobCode: string, tenantId: string) => {
  return (dispatch: any) => {
    dispatch(verifyPasswordResetCodeRequest());
    if (tenantId && tenantId.length) {
      firebase.auth().tenantId = tenantId;
    }
    // Verify the password reset code is valid.
    // https://firebase.google.com/docs/auth/custom-email-handler
    // success => return the email
    firebase
      .auth()
      .verifyPasswordResetCode(oobCode)
      .then((email: string) => {
        dispatch(verifyPasswordResetCodeSuccess(email));
      })
      .catch((error: any) => {
        dispatch(verifyPasswordResetCodeFailure(error.code));
      });
  };
};

export const confirmPasswordReset = (oobCode: string, tenantId: string, newPassword: string) => {
  return (dispatch: any) => {
    dispatch(confirmPasswordResetRequest());
    if (tenantId && tenantId.length) {
      firebase.auth().tenantId = tenantId;
    }
    // https://firebase.google.com/docs/auth/custom-email-handler
    firebase
      .auth()
      .confirmPasswordReset(oobCode, newPassword)
      .then(() => {
        dispatch(confirmPasswordResetSuccess());
      })
      .catch((error: any) => {
        dispatch(confirmPasswordResetFailure(error.code));
      });
  };
};

export const sendVerificationEmail = () => {
  return async (dispatch: any, getState: any) => {
    dispatch(sendVerificationEmailRequest());
    sendEmailVerificationAPI()
      .then((response: Response) => {
        try {
          const data = response.data;
          if (data.hasErrors) {
            dispatch(sendVerificationEmailFailure(data.applicationEvents[0].code));
          } else {
            dispatch(sendVerificationEmailSuccess());
          }
        } catch (err) {
          dispatch(sendVerificationEmailFailure("InternalError"));
        }
      })
      .catch((error: any) => {
        try {
          dispatch(sendVerificationEmailFailure(error.response.applicationEvents[0].code));
        } catch (err) {
          dispatch(signUpFailure("InternalError"));
        }
      })
      .finally(() => {
        signOutUser();
      });
  };
};

export const verifyPaswword = (password: string) => {
  return async (dispatch: any, getState: any) => {
    const user: any = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email as string, password);
    //https://firebase.google.com/docs/reference/js/firebase.User#reauthenticatewithcredential
    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        dispatch(reauthenticateCredentialSuccess());
      })
      .catch((error: any) => {
        dispatch(reauthenticateCredentialFailure(error.message));
      });
  };
};

//refreshToken
export const monitorTokenRefresh = () => {
  return async (dispatch: any) => {
    firebase.auth().onIdTokenChanged(async (user: firebase.User | null) => {
      if (user) {
        const refreshToken: firebase.auth.IdTokenResult = await user.getIdTokenResult();
        if (refreshToken) {
          const userAccess = await obtainTheUserAccess(refreshToken.token);

          if (
            userAccess.scopes.length === 2 &&
            userAccess.scopes.find((scope: EScopesType) => scope === EScopesType.mobile_r)
          ) {
            // dispatch(signOutUser());
            window.location.replace(pathRoute.SignIn.MobileCredential);
            return;
          }
          dispatch(updateUserAccess(userAccess));
          dispatch(updateToken(refreshToken));
          dispatch(updateDidCallAPILockdownInfo(false));
        } else {
          dispatch(showErrorInterceptor("SessionExpire"));
          dispatch(signOutUser());
        }
      }
    });
  };
};
