import React, { useEffect, useRef, useState } from "react";
import { KinesisVideo } from "@aws-sdk/client-kinesis-video";
import { Role, SignalingClient } from "amazon-kinesis-video-streams-webrtc";
import { v4 as uuid } from "uuid";
import { useIceServers } from "./useIceServers";
import { useSignalingChannelEndpoints } from "./useSignalingChannelEndpoints";
import { useSignalingClient } from "./useSignalingClient";
import { useChannelARN } from "./useChannelARN";

import {
  ERROR_ICE_CANDIDATE_NOT_FOUND,
  ERROR_PEER_CONNECTION_LOCAL_DESCRIPTION_REQUIRED,
  ERROR_PEER_CONNECTION_NOT_INITIALIZED,
  ERROR_SIGNALING_CLIENT_NOT_CONNECTED,
} from "../constants";
import { PeerConfigOptions, Peer } from "../model";
import { getLogger } from "../../../../Models/commons";
import Data from "../../../../Constants/values";
// import { getLogger } from "../logger";

// let dataChannel: RTCDataChannel = undefined as any;

export function useViewer(config: Omit<PeerConfigOptions, "channelARN" | "channelEndpoint">): {
  _signalingClient: SignalingClient | undefined;
  error: Error | undefined;
  // localMediaStream: MediaStream | undefined;
  peer: Peer | undefined;
  setStartWebRTC: (value: boolean) => void;
  remoteDataMessage: string;
  connectionStateStatus: string;
  setReload: () => void;
  closeConnection: () => void;
} {
  const {
    channelName,
    credentials,
    region,
    clientName,
    trickleICE = true,
    offerToReceiveAudio,
    offerToSendAudio,
    localMediaStream,
    requestAuthorizationToTalk,
    messageToDevice,
    webRTCSettings,
  } = config;
  const [connectionStateStatus, setConnectionStateStatus] = useState("");
  const [isDataChannelOpen, setIsDataChannelOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [loadAgain, setLoadAgain] = useState(false);
  const [dataChannel, setDataChannel] = useState<RTCDataChannel>();
  const setStartWebRTC = (value: boolean) => {
    setActive(value);
  };
  const setReload = () => {
    setLoadAgain((prev) => !prev);
  };
  const [dataChannelMessage, setDataChannelMessage] = useState("");
  const logger = useRef(getLogger());
  const role = Role.VIEWER;
  const clientId = useRef<string>(clientName + "_" + uuid());

  const kinesisVideoClientRef = useRef<KinesisVideo>(
    new KinesisVideo({
      region,
      credentials,
    })
  );
  const connectedBeforeRef = useRef(false);
  useEffect(() => {
    if (connectedBeforeRef.current) {
      setPeerConnection(undefined);
      setPeerMedia(undefined);
      setConnectionStateStatus("");
    }
  }, [loadAgain]);
  const kinesisVideoClient = kinesisVideoClientRef.current;
  const [peerConnection, setPeerConnection] = useState<RTCPeerConnection>();
  const [peerMedia, setPeerMedia] = useState<MediaStream>();
  const [peerError, setPeerError] = useState<Error>();

  const { error: channelARNError, channelARN } = useChannelARN({
    channelName,
    kinesisVideoClient,
  });

  const { error: signalingChannelEndpointsError, signalingChannelEndpoints } =
    useSignalingChannelEndpoints({
      channelARN,
      kinesisVideoClient,
      role,
      active,
    });

  // commented to avoid make API call to obtain the ice servers of amazon because we are using a fixed iceServers
  // const { error: iceServersError, iceServers } = useIceServers({
  //   channelARN,
  //   channelEndpoint: signalingChannelEndpoints?.HTTPS as string,
  //   credentials,
  //   region,
  //   role,
  //   loadAgain,
  // });
  const [iceServers, setIceServers] = useState<RTCIceServer[]>([]);

  useEffect(() => {
    if (signalingChannelEndpoints) {
      setIceServers([
        { urls: process.env.REACT_APP_STUN as string },
        { urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443` },
        {
          urls: webRTCSettings?.urls || [],
          username: webRTCSettings?.username,
          credential: webRTCSettings?.credential,
        },
      ]);
    }
  }, [signalingChannelEndpoints]);

  const { error: signalingClientError, signalingClient } = useSignalingClient({
    channelARN,
    channelEndpoint: signalingChannelEndpoints?.WSS as string,
    clientId: clientId.current,
    credentials,
    region,
    role,
    systemClockOffset: kinesisVideoClient.config.systemClockOffset,
  });

  let depsError = channelARNError || signalingChannelEndpointsError || signalingClientError; //|| iceServersError

  const peer: Peer = {
    id: clientId.current,
    connection: peerConnection,
    remoteMediaStream: peerMedia,
  };

  /** Initialize the peer connection with ice servers. */
  useEffect(() => {
    if (!iceServers.length) {
      return;
    }

    // in order to prevent certain race conditions, ensure the local media stream is active
    // before initializing the peer connection (one-way viewers are exempt)
    // if (!viewerOnly && !localMediaStreamIsActive) {
    //   return;
    // }
    if (iceServers.length) {
      setPeerConnection(
        new RTCPeerConnection({
          iceServers,
          iceTransportPolicy: "all",
        })
      );
    }
  }, [iceServers]);

  /** Handle signaling client and remote peer lifecycle. */
  useEffect(() => {
    if (!peerConnection || !signalingClient) {
      return;
    }
    // if (peerConnection?.connectionState === "connected") {
    //   //avoid execute signalingClient.open(); when is connected
    //   return;
    // }
    if (peerMedia?.active) {
      return;
    }
    async function handleSignalingClientOpen() {
      logger.current.logViewer(`[${clientId.current}] signaling client opened`);

      //   if (viewerOnly) {
      //     peerConnection?.addTransceiver("video");
      //     peerConnection?.getTransceivers().forEach((t) => (t.direction = "recvonly"));
      //   }

      const sessionDescription = await peerConnection?.createOffer({
        offerToReceiveAudio: offerToReceiveAudio,
        offerToReceiveVideo: true,
      });

      try {
        await peerConnection?.setLocalDescription(sessionDescription);
        if (!peerConnection?.localDescription) {
          return setPeerError(new Error(ERROR_PEER_CONNECTION_LOCAL_DESCRIPTION_REQUIRED));
        }
        if (trickleICE) {
          logger.current.logViewer(
            `[${clientId.current}] sending sdp offer---[${JSON.stringify(
              peerConnection.localDescription
            )}]`
          );
          signalingClient?.sendSdpOffer(peerConnection.localDescription);
        }
      } catch (error) {
        logger.current.logError(error);
        return setPeerError(error as Error);
      }
    }

    async function handleSignalingClientSdpAnswer(answer: RTCSessionDescriptionInit) {
      logger.current.logViewer(
        `[${clientId.current}] received sdp answer---[${JSON.stringify(answer)}]`
      );
      try {
        if (!peerConnection) {
          throw new Error(ERROR_PEER_CONNECTION_NOT_INITIALIZED);
        }
        await peerConnection.setRemoteDescription(answer);
      } catch (error) {
        logger.current.logError(error);
        return setPeerError(error as Error);
      }
    }

    function handleSignalingChannelIceCandidate(candidate: RTCIceCandidate) {
      logger.current.logViewer(`[${clientId.current}] received signaling channel ice candidate`);
      try {
        if (!candidate) {
          throw new Error(ERROR_ICE_CANDIDATE_NOT_FOUND);
        }

        if (!peerConnection) {
          throw new Error(ERROR_PEER_CONNECTION_NOT_INITIALIZED);
        }

        peerConnection?.addIceCandidate(candidate);
      } catch (error) {
        logger.current.logError(error);
        return setPeerError(error as Error);
      }
    }

    function handlePeerIceCandidate({ candidate }: RTCPeerConnectionIceEvent) {
      logger.current.logViewer(`[${clientId.current}] received peer ice candidate`);

      try {
        if (!signalingClient) {
          throw new Error(ERROR_SIGNALING_CLIENT_NOT_CONNECTED);
        }

        if (candidate) {
          if (trickleICE) {
            signalingClient.sendIceCandidate(candidate);
          } else {
            if (!trickleICE) {
              const sdpOffer: RTCSessionDescription =
                peerConnection?.localDescription as RTCSessionDescription;
              signalingClient?.sendSdpOffer(sdpOffer);
            }
          }
        }
      } catch (error) {
        logger.current.logError(error);
        return setPeerError(error as Error);
      }
    }

    function handlePeerTrack({ streams = [] }: RTCTrackEvent) {
      logger.current.logViewer(`[${clientId.current}] received peer track`);
      setPeerMedia(streams[0]);
    }

    signalingClient.on("open", handleSignalingClientOpen);
    signalingClient.on("sdpAnswer", handleSignalingClientSdpAnswer);
    signalingClient.on("iceCandidate", handleSignalingChannelIceCandidate);
    signalingClient.on("goAway", (aa: any) => {
      // console.log("idle", aa);
    });

    signalingClient.open();

    peerConnection.addEventListener("icecandidate", handlePeerIceCandidate);
    peerConnection.addEventListener("track", handlePeerTrack);
    /////////////////////////////////////////////
    //Data Channel
    const openDataChannel = true;

    if (openDataChannel) {
      const temp1 = channelName.replaceAll("-", "_");
      const dataChannelTemp = peerConnection.createDataChannel("chat_" + temp1, {
        ordered: true,
        // negotiated: true,
      });
      setDataChannel(dataChannelTemp as any);
      peerConnection.ondatachannel = (event: RTCDataChannelEvent) => {
        event.channel.onmessage = (event: MessageEvent) => {
          setDataChannelMessage(event.data);
        };
      };
      dataChannelTemp.onopen = (event: Event) => {
        setIsDataChannelOpen(true);
      };
      dataChannelTemp.onerror = () => {
        logger.current.logViewer(`[${clientId.current}] dataChannel failed`);
      };
    }
    ///////////////////////////////////////
    peerConnection &&
      (peerConnection.onconnectionstatechange = (event: Event) => {
        setConnectionStateStatus(peerConnection?.connectionState || "");
        switch (peerConnection?.connectionState) {
          //type RTCPeerConnectionState = "closed" | "connected" | "connecting" | "disconnected" | "failed" | "new";
          case "new":
            //
            depsError = undefined;
            setPeerError(undefined);
            logger.current.logViewer(`[${clientId.current}] onconnectionstatechange NEW`);
            break;
          case "connected":
            logger.current.logViewer(`[${clientId.current}] onconnectionstatechange connected`);
            // The connection has become fully connected
            setPeerError(undefined);
            depsError = undefined;
            connectedBeforeRef.current = true;
            break;
          case "failed":
            logger.current.logViewer(`[${clientId.current}] onconnectionstatechange failed`);
            //   onError(ErrorType.Failed, "Failed");
            break;
          case "disconnected":
            logger.current.logViewer(`[${clientId.current}] onconnectionstatechange disconnected`);
            //   onDisconnected();
            break;
          case "closed":
            logger.current.logViewer(`[${clientId.current}] onconnectionstatechange closed`);
            //   onDisconnected();
            break;
        }
      });
    peerConnection &&
      (peerConnection.onsignalingstatechange = (event: Event) => {
        //https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/signalingState
        // "closed" | "have-local-offer" | "have-local-pranswer" | "have-remote-offer" | "have-remote-pranswer" | "stable"
        switch (peerConnection?.signalingState) {
          case "stable":
            // One or more transports has terminated unexpectedly or in an error
            // console.log("stable");
            logger.current.logViewer(`[${clientId.current}] onsignalingstatechange stable`);

            break;
          case "have-remote-pranswer":
            // console.log("have-remote-pranswer");
            logger.current.logViewer(
              `[${clientId.current}] onsignalingstatechange have-remote-pranswer`
            );

            break;
          case "have-remote-offer":
            // console.log("have-remote-offer");
            logger.current.logViewer(
              `[${clientId.current}] onsignalingstatechange have-remote-offer`
            );

            break;
          case "have-local-offer":
            // console.log("have-local-offer");
            /*The local peer has called RTCPeerConnection.setLocalDescription(), passing in SDP representing an offer (usually created by calling RTCPeerConnection.createOffer()), and the offer has been applied successfully. */
            // onError(ErrorType.NoMasterConnected, "have-local-offer");
            logger.current.logViewer(
              `[${clientId.current}] onsignalingstatechange have-local-offer`
            );
            break;
          case "have-local-pranswer":
            // console.log("have-local-pranswer");
            logger.current.logViewer(
              `[${clientId.current}] onsignalingstatechange have-local-pranswer`
            );
            break;
          case "closed":
            logger.current.logViewer(`[${clientId.current}] onsignalingstatechange closed`);
            // console.log("closed onsignalingstatechange");
            // rtcPeerViewer.peerConnection?.close();
            // rtcPeerViewer.signalingClient?.close();
            break;
        }
      });
    peerConnection &&
      (peerConnection.oniceconnectionstatechange = (event: Event) => {
        switch (peerConnection?.iceConnectionState) {
          case "failed":
            logger.current.logViewer(`[${clientId.current}] oniceconnectionstatechange failed`);

            // One or more transports has terminated unexpectedly or in an error
            // onError(ErrorType.Failed, "Failed");

            //TODO: Need connect,
            //https://stackoverflow.com/questions/35577657/detect-offline-peer-in-webrtc-connection
            // https://stackoverflow.com/questions/70497170/how-can-webrtc-reconnect-to-the-same-peer-after-internet-disconnect
            //https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/restartIce
            // peerConnection.restartIce()
            //
            break;
          case "disconnected":
            logger.current.logViewer(
              `[${clientId.current}] oniceconnectionstatechange disconnected`
            );
            // onDisconnected();
            break;
          case "closed":
            logger.current.logViewer(`[${clientId.current}] oniceconnectionstatechange closed`);

            // The connection has been closed
            // onDisconnected();
            break;
          case "checking":
            logger.current.logViewer(`[${clientId.current}] oniceconnectionstatechange checking`);
            break;
          case "connected":
            logger.current.logViewer(`[${clientId.current}] oniceconnectionstatechange connected`);
            break;
          case "completed":
            logger.current.logViewer(`[${clientId.current}] oniceconnectionstatechange completed`);
            break;
          case "new":
            logger.current.logViewer(`[${clientId.current}] oniceconnectionstatechange new`);
            break;
        }
      });
    peerConnection &&
      (peerConnection.onnegotiationneeded = async (event: Event) => {
        //aws doesnt have this feature
        logger.current.logViewer(`[${clientId.current}] onnegotiationneeded`);
      });
    return function cleanup() {
      logger.current.logViewer(`[${clientId.current}] cleanup`);

      signalingClient.off("open", handleSignalingClientOpen);
      signalingClient.off("sdpAnswer", handleSignalingClientSdpAnswer);
      signalingClient.off("iceCandidate", handleSignalingChannelIceCandidate);
      signalingClient.close();

      peerConnection.removeEventListener("icecandidate", handlePeerIceCandidate);
      peerConnection.removeEventListener("track", handlePeerTrack);
      (dataChannel as any)?.close();
      peerConnection.close();
    };
  }, [clientId, peerConnection, signalingClient]);

  function closeConnection() {
    logger.current.logViewer(`[${clientId.current}] cleanup`);

    signalingClient?.off("open", () => {
      //
    });
    signalingClient?.off("sdpAnswer", () => {
      //
    });
    signalingClient?.off("iceCandidate", () => {
      //
    });

    signalingClient?.removeAllListeners();

    signalingClient?.close();

    peerConnection?.removeEventListener("icecandidate", () => {
      //
    });
    peerConnection?.removeEventListener("track", () => {
      //
    });
    (dataChannel as any)?.close();
    peerConnection?.close();
    setPeerConnection(undefined);
    setPeerMedia(undefined);
    setConnectionStateStatus("");
  }

  /** Handle peer media lifecycle. */
  useEffect(() => {
    return function cleanup() {
      peerMedia?.getTracks().forEach((track) => track.stop());
    };
  }, [peerMedia]);

  /** Send local media stream to remote peer. */
  useEffect(() => {
    if (!localMediaStream || !peerConnection) {
      return;
    }
    if (peerConnection?.connectionState === "closed") {
      return;
    }
    if (offerToSendAudio) {
      localMediaStream.getTracks().forEach((track: MediaStreamTrack) => {
        (peerConnection as RTCPeerConnection).addTrack(track, localMediaStream);
      });
    }
  }, [peerConnection]);

  function sendViewerMessage(message: string) {
    if (dataChannel) {
      try {
        (dataChannel as any).send(message);
      } catch (e: any) {
        logger.current.logViewer(`[${clientId.current}] failed send data channel`);
      }
    }
  }
  useEffect(() => {
    if (isDataChannelOpen) {
      const object = {
        status: requestAuthorizationToTalk,
      };
      // console.log("requestAuthorizationToTalk: ", JSON.stringify(object));
      sendViewerMessage(JSON.stringify(object));
    }
  }, [requestAuthorizationToTalk, isDataChannelOpen]);

  useEffect(() => {
    if (isDataChannelOpen && messageToDevice) {
      // console.log("Requesting: ", messageToDevice);
      sendViewerMessage(messageToDevice);
    }
  }, [messageToDevice, isDataChannelOpen]);

  return {
    _signalingClient: signalingClient,
    error: depsError || peerError, //streamError managed outside
    // localMediaStream,
    peer,
    setStartWebRTC,
    remoteDataMessage: dataChannelMessage,
    connectionStateStatus,
    setReload,
    closeConnection,
  };
}
