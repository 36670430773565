import { LockdownPlanInfo } from "./workflow";
export interface NotificationModel {
  Code: string;
  Data: any; //JSON
  isNoRead?: boolean;
  id?: string;
  isConnected?: boolean;
}

// export enum DeviceNotificationEnum {
//   NotificationDeviceConnected = "notification_status_connected",
//   NotificationDeviceDisconnected = "notification_status_disconnected"
// }

export enum NotificationConnectionStatusEnum {
  NotificationDeviceConnected = "notification_status_connected",
  NotificationDeviceDisconnected = "notification_status_disconnected",
  NotificationStatusVideoSource = "videosources_healthcheck_response",
}

export enum NotificationVideoSourcesScannedResponse {
  NotificationVideoSourcesScannedResponse = "videosources_scanned_response",
}

export enum NotificationCameraOnvifScannedResponse {
  NotificationCameraOnvifScannedResponse = "camera_onvif_info_response",
}

export enum NotificationVideoSourcesSavedResponse {
  NotificationVideoSourcesSavedResponse = "videosources_save_response",
  videosources_save_playlist_response = "videosources_save_playlist_response",
}

export enum NotificationDoorStateEnum {
  NotificationDoorState = "notification_door_state",
}

export enum NotificationLockStateEnum {
  NotificationLockState = "notification_lock_state",
}

export enum NotificationFaceEnrollmentResult {
  NotificationFaceEnrollmentResult = "notification_face_enrollment_result",
}

export enum NotificationAccessLogs {
  NotificationAccessLogs = "notification_access_log",
  NotificationAccessLogsUpdateHasVideo = "notification_access_log_uploaded_video_success",
}
export enum NotificationNetworkSettings {
  success = "device_network_setting_success",
  failure = "device_network_setting_failure",
}
export enum NotificationDoorBell {
  NotificationDoorBellRing = "notification_door_bell_ring",
}
export enum NotificationLockdownPlan {
  NotificationLockdownPlan = "notification_lockdown_plan",
}
export enum NotificationDeviceCallRequest {
  NotificationDeviceCallRequest = "notification_device_call_request",
}
export enum NotificationFirmwareUpdate {
  NotificationFirmwareUpdate = "notification_firmware_upgrade",
}
export enum NotificationApprovalRequest {
  NotificationUserApprovalRequest = "notification_user_approval_request",
}
export interface NotificationLockdownPlanModel extends LockdownPlanInfo {
  timestamp: number;
}
export enum NotificationDeviceSensor {
  SensorJoined = "notification_sensor_joined",
  SensorState = "notification_sensor_state_change",
  BatteryPercent = "notification_battery_percent",
  SignalIntensity = "notification_signal_intensity",
}
export enum NotificationResync {
  ResyncNextTime = "resync_next_time",
}

export enum NotificationConnectionStatusRelayPanelEnum {
  NotificationPanelConnectionStatus = "notification_panel_connection_status",
}

export interface NotificationFaceEnrollmentResultDataModel {
  fullName: string;
  userId: string;
  success: boolean;
  failureReason:
    | "None"
    | "Unknown"
    | "TimedOut"
    | "Cancelled"
    | "FaceAlreadyEnrolled"
    | "NoFacesInImage"
    | "TooManyFacesInImage"
    | "FaceTooClose"
    | "FaceTooFar"
    | "FaceNotStraightForward"
    | "CardAlreadyEnrolled"
    | "FacilityCodeNotAllowed"
    | "CardFormatNotAllowed"
    | "ImageReadFailed"
    | "ImageConversionFailed"
    | "SDKWrapperNotInit"
    | "DetectFacesFailed"
    | "CalculateFacesQualityFailed"
    | "GetMostProminentFacesFailed"
    | "DiscardFacesProminenceFailed"
    | "DiscardFacesAcceptabilityFailed"
    | "DiscardFacesQualityFailed"
    | "DiscardFacesWayTooSmallFailed"
    | "DiscardFacesTooSmallFailed"
    | "DiscardFacesTooSmall"
    | "CalculateFacesLandmarksFailed "
    | "CalculateFacesEmbeddingFailed"
    | "CalculateFrontalityFailed"
    | "GetMostProminentFacesSecondFailed"
    | "NotFrontFacing";
}

export enum FailureReasonStringType {
  None = "None",
  Unknown = "Unknown",
  TimedOut = "TimedOut",
  Cancelled = "Cancelled",
  FaceAlreadyEnrolled = "FaceAlreadyEnrolled",
  NoFacesInImage = "NoFacesInImage",
  TooManyFacesInImage = "TooManyFacesInImage",
  FaceTooClose = "FaceTooClose",
  FaceTooFar = "FaceTooFar",
  FaceNotStraightForward = "FaceNotStraightForward",
  CardAlreadyEnrolled = "CardAlreadyEnrolled",
  CardFormatNotAllowed = "CardFormatNotAllowed",
  FacilityCodeNotAllowed = "FacilityCodeNotAllowed",
  ImageReadFailed = "ImageReadFailed",
  ImageConversionFailed = "ImageConversionFailed",
  SDKWrapperNotInit = "SDKWrapperNotInit",
  DetectFacesFailed = "DetectFacesFailed",
  CalculateFacesQualityFailed = "CalculateFacesQualityFailed",
  GetMostProminentFacesFailed = "GetMostProminentFacesFailed",
  DiscardFacesProminenceFailed = "DiscardFacesProminenceFailed",
  DiscardFacesAcceptabilityFailed = "DiscardFacesAcceptabilityFailed",
  DiscardFacesQualityFailed = "DiscardFacesQualityFailed",
  DiscardFacesWayTooSmallFailed = "DiscardFacesWayTooSmallFailed",
  DiscardFacesTooSmallFailed = "DiscardFacesTooSmallFailed",
  DiscardFacesTooSmall = "DiscardFacesTooSmall",
  CalculateFacesLandmarksFailed = "CalculateFacesLandmarksFailed",
  CalculateFacesEmbeddingFailed = "CalculateFacesEmbeddingFailed",
  CalculateFrontalityFailed = "CalculateFrontalityFailed",
  GetMostProminentFacesSecondFailed = "GetMostProminentFacesSecondFailed",
  NotFrontFacing = "NotFrontFacing",
}

export enum FailureReasonType {
  None = 0,
  Unknown = 1,
  TimedOut = 2,
  Cancelled = 3,
  FaceAlreadyEnrolled = 4,
  NoFacesInImage = 5,
  TooManyFacesInImage = 6,
  FaceTooClose = 7,
  FaceTooFar = 8,
  FaceNotStraightForward = 9,
  CardAlreadyEnrolled = 10,
  CardFormatNotAllowed = 11,
  FacilityCodeNotAllowed = 12,
  ImageReadFailed = 13,
  ImageConversionFailed = 14,
  SDKWrapperNotInit = 15,
  DetectFacesFailed = 16,
  CalculateFacesQualityFailed = 17,
  GetMostProminentFacesFailed = 18,
  DiscardFacesProminenceFailed = 19,
  DiscardFacesAcceptabilityFailed = 20,
  DiscardFacesQualityFailed = 21,
  DiscardFacesWayTooSmallFailed = 22,
  DiscardFacesTooSmallFailed = 23,
  DiscardFacesTooSmall = 24,
  CalculateFacesLandmarksFailed = 25,
  CalculateFacesEmbeddingFailed = 26,
  CalculateFrontalityFailed = 27,
  GetMostProminentFacesSecondFailed = 28,
  NotFrontFacing = 29,
}

export const FirestorePathRoute = {
  Devices: {
    Index: "Devices",
    AccessLog: "AccessLog",
    ConnectionStatus: "ConnectionStatus",
    DoorState: "DoorState",
    LockState: "LockState",
    NetworkSettings: "NetworkSettings",
    DoorBellRing: "DoorBellRing",
    SensorPaired: "SensorJoined",
    VideoSources: "VideoSources",
    NextResync: "NextResync",
    RelayPanelConnectionStatus: "RelayPanelConnectionStatus",
    CameraOnvifDetails: "CameraOnvifDetails",
  },
  Users: {
    Index: "Users",
    FaceEnrollmentResult: "FaceEnrollmentResult",
    CardEnrollmentResult: "CardEnrollmentResult",
    MobileCredential: "MobileCredential",
    DeviceCallRequest: "DeviceCallRequest",
    VideoSources: "VideoSources",
  },
  AccountSupport: {
    Index: "AccountSupport",
    FirmwareUpgrade: "FirmwareUpgrade",
  },
  ProvisioningFaceInvitation: {
    Index: "ProvisioningInvitation",
    Results: "FaceEnrollmentResult",
  },
  LockdownPlan: {
    Index: "LockdownPlan",
  },
  Sensors: {
    Index: "Sensors",
    SensorState: "SensorState",
    BatteryPercent: "BatteryPercent",
    SignalIntensity: "SignalIntensity",
  },
  ApprovalRequests: {
    Index: "ApprovalRequests",
    Requests: {
      Index: "Requests",
      approval_requests: "approval_requests",
    },
  },
};

export enum ApprovalRequestType {
  PassApproval = 0,
}
export enum ApprovalRequestStatus {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
  Cancelled = 3,
}
export interface NotificationLocalPlaylist {
  VideoSourceOperation: {
    video_source_id: string;
    from_date: string;
    to_date: string;
    playlist_name: string;
    user_id: string;
    processed: boolean; //false => means no video
  };
  timestamp: number;
}
