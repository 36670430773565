import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./PlaybackCloud.module.css";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  AWSCredentialResponseModel,
  DeviceEventLogModel,
  InfoToDownloadFromCloud,
  ObjectDetectionType,
  OperationDataChannelType,
  RTCPeerViewer,
  ReceiveDataChannelWebRTC,
  SendDataChannelWebRTC,
} from "../../../Models/devices";
import { getCloudVideoCredential } from "../../../API/apiAuth";
import AlocityLoading from "../../CustomLoading/AlocityLoading";
import {
  ClientConfiguration,
  GetObjectOutput,
  ManagedUpload,
  PutObjectRequest,
} from "aws-sdk/clients/s3";
import AWS from "aws-sdk";
import {
  getCloudStorangeImage,
  getCloudStorangeVideo,
  getCompleteEvents,
  getEventArtifactsVideoSource,
} from "../../../API/apiDevices";
import { useTranslation } from "react-i18next";
import { Check, CloseOutlined, SlowMotionVideo } from "@material-ui/icons";
import Replay10Icon from "@material-ui/icons/Replay10";
import Forward10Icon from "@material-ui/icons/Forward10";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import {
  SettingsOverscan as SettingsOverscanIcon,
  CameraEnhance as CameraEnhanceIcon,
  VolumeUp as VolumeUpIcon,
  VolumeOff as VolumeOffIcon,
} from "@material-ui/icons";

import Hls from "hls.js";
import moment, { Moment } from "moment";
import ReplayIcon from "@material-ui/icons/Replay";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { QueryFilter } from "../../../Models/api";
import {
  generateColorPerObjectDetection,
  generateMarginTopPerObjectDetection,
} from "../../HelperCode";
import { v4 as uuid } from "uuid";
import { cloneDeep } from "lodash";
import LayersIcon from "@material-ui/icons/Layers";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import DialogWrapper from "../../DialogWrapper";
import ShareEventVideoComponent from "../ShareEventVideoComponent";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import StoreStateModel from "../../../Models/store";
import * as actions from "../../../../../store/actions";
import { toast } from "react-toastify";
import { openDataChannelPeerConnection } from "./helper";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import Draggable from "react-draggable";
import OperationsOverTimespanSelection from "./OperationsOverTimespanSelection";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CalendarFromPlaybackCloud from "./CalendarFromPlaybackCloud";
import * as Sentry from "@sentry/react";
import { Severity } from "@sentry/react";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import { connectToLocalPlayback } from "../../../API/apiVideosource";
import useFireStoreSubscription from "../../Hooks/useFireStoreSubscription";
import {
  FirestorePathRoute,
  NotificationLocalPlaylist,
  NotificationModel,
  NotificationVideoSourcesSavedResponse,
} from "../../../Models/notifications";

interface Props {
  startDateTime: string; //in ISO format
  endDateTime: string; //in ISO format
  serialNumber: string;
  deviceName: string;
  onClose: () => void;
  showEvents?: boolean;
  onNoVideo?: () => void;
  seekToThisTime?: string; //in ISO format
  isPublic?: boolean;
  publicUrl?: string;
  customWidthProp?: number;
  customHeightProp?: number;
  sdPlaybackMode?: boolean;
  initPlayListReaders?: boolean;
  isThisClipSelectedTheNearestToTheActualRealDateTime?: boolean; //this is to know if the clip selected is the more near to the actual time, and using this we will avoid to show blackscreen in future date-times
  timeZoneOffset: string;
  clipsTime?: number; //seconds of the jump
  isPlaybackHistory?: boolean;
  identifierId: string;
}
interface TimePathBlobRelation {
  time: string;
  pathname: string;
  duration: number;
  id: string;
}
interface ThumbnailTimeObject {
  time: Moment;
  image?: string;
}
interface DynamicSegment extends TimePathBlobRelation {
  uploaded: boolean;
  processed: boolean;
  sentToDevice: boolean;
}

interface TimesVideoWhitoutVideo {
  startTime: string;
  endTime: string;
}

const progressBarNormalHeight = 50;
const progressBarFullscreenHeight = 50;
const progressBarMiniHeight = 10;
const extraBottomSpace = 50;
const expirationTime = 3600 * 4;

let totalSegmentsToRequestToDevice = 5;
let counterFatalMediaErrors = 0;

const PlaybackCloud = ({
  startDateTime,
  endDateTime,
  serialNumber,
  deviceName,
  onClose,
  showEvents = true,
  seekToThisTime,
  isPublic,
  publicUrl,
  customWidthProp,
  customHeightProp,
  sdPlaybackMode,
  initPlayListReaders = false,
  isThisClipSelectedTheNearestToTheActualRealDateTime,
  timeZoneOffset,
  isPlaybackHistory,
  clipsTime = 600, //10 min by default,
  identifierId,
}: Props) => {
  const defaultStringSizeForKnowItsIsVideoSource = 30; //  string size to know if it is video source or not
  // Define the duration for each segment in seconds
  const IdealDurationOfSegment: number = initPlayListReaders ? 20 : 1; //parseInt(process.env.REACT_APP_VideoEvent_DurationInSecond || "20");

  const isVideoSource =
    serialNumber.length >= defaultStringSizeForKnowItsIsVideoSource ? true : false;

  const extensionSegmentVideoM4s = useRef<boolean>(false);

  const refHLS = useRef<Hls | undefined>();
  const { t } = useTranslation();
  const initPlayList = initPlayListReaders
    ? process.env.REACT_APP_Init_file_Readers
    : process.env.REACT_APP_Init_file_Cameras;

  const prefix = "INT";
  const isIntercom = serialNumber.split("-")[1].startsWith(prefix);

  const shortBlackScreenVideoUrl_1: string = initPlayListReaders
    ? (process.env.REACT_APP_CloudStorange_BlackScreenReader1S_1_M4S as string)
    : (process.env.REACT_APP_CloudStorange_BlackScreenCamera1S_1_M4S as string);
  const shortBlackScreenVideoUrl_2: string = initPlayListReaders
    ? (process.env.REACT_APP_CloudStorange_BlackScreenReader1S_2_M4S as string)
    : (process.env.REACT_APP_CloudStorange_BlackScreenCamera1S_2_M4S as string);
  const largeBlackScreenVideoUrl_1: string = initPlayListReaders
    ? (process.env.REACT_APP_CloudStorange_BlackScreenReader8S_1_M4S as string)
    : (process.env.REACT_APP_CloudStorange_BlackScreenCamera20S_1_M4S as string);
  const largeBlackScreenVideoUrl_2: string = initPlayListReaders
    ? (process.env.REACT_APP_CloudStorange_BlackScreenReader8S_2_M4S as string)
    : (process.env.REACT_APP_CloudStorange_BlackScreenCamera20S_2_M4S as string);

  let largeBlackScreenVideoUrl_TS: string = initPlayListReaders
    ? isIntercom
      ? (process.env.REACT_APP_CloudStorange_BlackScreenIntercom8S_1_TS as string)
      : (process.env.REACT_APP_CloudStorange_BlackScreenReader8S_1_TS as string)
    : (process.env.REACT_APP_CloudStorange_BlackScreenCamera20S_1_TS as string);
  let shortBlackScreenVideoUrl_1S_TS: string = initPlayListReaders
    ? isIntercom
      ? (process.env.REACT_APP_CloudStorange_BlackScreenIntercom1S_1_TS as string)
      : (process.env.REACT_APP_CloudStorange_BlackScreenReader1S_1_TS as string)
    : (process.env.REACT_APP_CloudStorange_BlackScreenCamera1S_1_TS as string);

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  //timespan variables
  const [isClickedTimespanSelectionButton, setIsClikedTimespanSelectionButton] = useState(false);
  const [isActivedBarDrag, setIsActivatedBarDrag] = useState(false);
  const [isClickedIconButtonExpandLess, setIsClickedIconButtonExpandLess] =
    useState<boolean>(false);

  const nodeRefTimespanSelectionStart = React.useRef(null);
  const nodeRefTimespanSelectionEnd = React.useRef(null);
  const nodeRefBarDrag = React.useRef(null);

  const [dragPixelValueTimespanSelectionStart, setDragPixelValueTimespanSelectionStart] = useState({
    x: 0,
    y: 0,
  });
  const [dragPixelValueTimespanSelectionEnd, setDragPixelValueTimespanSelectionEnd] = useState({
    x: 0,
    y: 0,
  });
  const [dragPixelValueBar, setDragPixelValueBar] = useState(0);
  const [
    showComponentToSelectOperationAfterTimespanSelection,
    setShowComponentToSelectOperationAfterTimespanSelection,
  ] = useState(false);
  //
  const [isDynamicPlaylist, setIsDynamicPlaylist] = useState(sdPlaybackMode || false);

  const [startDate, setStartDate] = useState(
    Math.abs(moment(startDateTime).diff(moment(endDateTime), "seconds")) < 5
      ? moment(startDateTime).subtract(30, "seconds").toISOString()
      : startDateTime
  );
  const [endDate, setEndDate] = useState(
    Math.abs(moment(startDateTime).diff(moment(endDateTime), "seconds")) < 5
      ? moment(startDateTime).add(30, "seconds").toISOString()
      : endDateTime
  );

  const dispatch = useDispatch();
  let pendingDeviceInfoDownloadFromCloud: InfoToDownloadFromCloud | undefined = undefined;
  let downloadingPercent = 0;
  let accountId = -1;
  let userId = "";
  if (!isPublic) {
    pendingDeviceInfoDownloadFromCloud = useSelector(
      (state: StoreStateModel) => state.general.pendingDeviceInfoDownloadFromCloud,
      shallowEqual
    );

    downloadingPercent = useSelector(
      (state: StoreStateModel) => state.general.downloadingPercent,
      shallowEqual
    );

    accountId = useSelector((state: StoreStateModel) => state.auth.accountId, shallowEqual);

    userId = useSelector((state: StoreStateModel) => state.auth.uid, shallowEqual);
  }
  //////////////////////////////////////////////////
  // webrtc
  const refTheTimeInSecondsJumped = useRef(0);
  const [loadingWebRtcInfo, setLoadingWebRtcInfo] = useState(false);
  const isProcessingNewSegments = useRef(false);
  const [receiveDeviceMessage, setReceiveDeviceMessage] = useState<string>("");
  const [rtcPeerViewer, setRtcPeerViewer] = useState<RTCPeerViewer | undefined>();

  const [deviceChannel, setDeviceChannel] = useState<RTCDataChannel | undefined>();

  const [showDialogToShareUnderDynamicPlaylist, setShowDialogToShareUnderDynamicPlaylist] =
    useState(false);
  const [showDialogToDownloadUnderDynamicPlaylist, setShowDialogToDownloadUnderDynamicPlaylist] =
    useState(false);
  const handleErrorInWebRTC = (code: string) => {
    if (code === "disconnected" || code === "failed") {
      setFatalErrorMessage(t("FailedConnectionWithDevice"));
    } else if (code === "error_device_webrtc_disabled") {
      setFatalErrorMessage(t("error_device_webrtc_disabled"));
    } else {
      setFatalErrorMessage(t("Error"));
    }
  };

  const [loading, setLoading] = useState(false);
  const [isHasAudio, setIsHasAudio] = useState<boolean>(false);

  useEffect(() => {
    const load = async () => {
      const rtcPeerViewerTemp: RTCPeerViewer | undefined = await openDataChannelPeerConnection(
        serialNumber,
        userId,
        handleErrorInWebRTC
      );
      setRtcPeerViewer(rtcPeerViewerTemp);
    };
    if (isDynamicPlaylist) {
      load();
    }
  }, [isDynamicPlaylist]);

  useEffect(() => {
    if (rtcPeerViewer) {
      rtcPeerViewer.peerConnection &&
        (rtcPeerViewer.peerConnection.ondatachannel = (event: RTCDataChannelEvent) => {
          //waiting that the master create the channel. If i create the channel after certain time the system close automatically
          //
          const channel: RTCDataChannel = event.channel;

          // const temp: SendDataChannelWebRTC = {
          //   operation: OperationDataChannelType.Playback,
          //   startTime: startDateTime,
          //   endTime: endDateTime,
          //   timestamp: "",
          // };
          // channel.send(JSON.stringify(temp));
          channel.onopen = (event) => {
            setDeviceChannel(channel);
          };
          channel.onmessage = (event: MessageEvent) => {
            setReceiveDeviceMessage(event.data);
          };
          channel.onclose = (event) => {
            //
          };
          channel.onerror = (error) => {
            console.error("Data channel error:", error);
            toast.error(t("Error"));
          };
        });
    }
    return function cleanup() {
      if (rtcPeerViewer) {
        rtcPeerViewer.signalingClient &&
          rtcPeerViewer.signalingClient.off("open", () => {
            //
          });
        rtcPeerViewer.signalingClient &&
          rtcPeerViewer.signalingClient.off("sdpAnswer", () => {
            //
          });
        rtcPeerViewer.signalingClient &&
          rtcPeerViewer.signalingClient.off("iceCandidate", () => {
            //
          });
        rtcPeerViewer.signalingClient && rtcPeerViewer.signalingClient.close();

        rtcPeerViewer.peerConnection &&
          rtcPeerViewer.peerConnection.removeEventListener("icecandidate", () => {
            //
          });
        rtcPeerViewer.dataChannel && rtcPeerViewer.dataChannel.close();
        rtcPeerViewer.peerConnection && rtcPeerViewer.peerConnection.close();
      }
    };
  }, [rtcPeerViewer]);

  useEffect(() => {
    if (deviceChannel) {
      const temp: SendDataChannelWebRTC = {
        operation: OperationDataChannelType.Playback,
        startTime: startDateTime,
        endTime: endDateTime,
        timestamp: "",
      };
      deviceChannel.send(JSON.stringify(temp));
    }
  }, [deviceChannel]);

  const deviceDynamicSegmentListRef = useRef<DynamicSegment[]>([]);
  const [arrivedNewSegment, setArrivedNewSegment] = useState<boolean>(false);
  const dynamicTotalDuration = useRef(0);
  // const waitUntilCounterOfSegmentsUploadedRef = useRef<number>(0);
  const isProcessedAllDynamic = useRef(false);
  const isUploadedAllDynamic = useRef(false);
  const refGroupRequestedOfTimesToUpload = useRef<string[]>([]);
  const segmentsInDeviceInDynamic = useRef<string[]>([]);
  const [finishedToReadPathNameInDynamic, setFinishedToReadPathNameInDynamic] = useState(false);
  const rangeTimesWithoutVideo = useRef<TimesVideoWhitoutVideo[]>([]);

  useEffect(() => {
    //receive message from device
    if (receiveDeviceMessage && receiveDeviceMessage.length && isDynamicPlaylist) {
      const data: ReceiveDataChannelWebRTC = JSON.parse(receiveDeviceMessage);
      switch (data.operation) {
        case OperationDataChannelType.Playback:
          if (data.segmentList && data.segmentList?.length) {
            // const deviceDynamicSegmentListRef = cloneDeep(deviceDynamicSegmentList);

            if (data.startTime && data.endTime) {
              const tempInit = moment(data.startTime).isBefore(moment(startDate))
                ? data.startTime
                : startDate;

              const tempEnd = moment(data.endTime).isAfter(moment(endDate))
                ? data.endTime
                : isThisClipSelectedTheNearestToTheActualRealDateTime
                ? data.endTime
                : endDate;
              setStartDate(tempInit);
              setEndDate(tempEnd);

              dynamicTotalDuration.current = moment(tempEnd).diff(moment(tempInit), "seconds");
              //read from cloud to know if there is uploaded already
              segmentsInDeviceInDynamic.current = data.segmentList;

              //Obtain the time ranges that are not on the device to show in the playback bar that there is not video
              let prevSegmentTime = segmentsInDeviceInDynamic.current[0].split("_")[0]; // 2024-01-05T15:09:32.000Z
              let prevSegmentDuration = segmentsInDeviceInDynamic.current[0]
                .split("_")[1]
                .split(".")[0]; //duration 20

              for (let i = 1; i < segmentsInDeviceInDynamic.current.length; i++) {
                const segmentTime = segmentsInDeviceInDynamic.current[i].split("_")[0];
                const segmentDuration = segmentsInDeviceInDynamic.current[i]
                  .split("_")[1]
                  .split(".")[0];

                const differenceSeconds = moment(segmentTime).diff(
                  moment(prevSegmentTime).add(prevSegmentDuration, "seconds"),
                  "seconds"
                );

                if (differenceSeconds > 1) {
                  const startTime = moment(prevSegmentTime)
                    .add(prevSegmentDuration, "seconds")
                    .toISOString();
                  const endTime = moment(segmentTime).toISOString();

                  rangeTimesWithoutVideo.current.push({ startTime, endTime });
                }
                prevSegmentTime = segmentTime;
                prevSegmentDuration = segmentDuration;
              }

              //////////////////////

              listVideos("", tempInit, tempEnd);
            } else {
              toast.error(t("Error"));
            }
          } else {
            setLoadingWebRtcInfo(false);
            setLoadingTheInfo(false);
            toast.error(t("NotFound"));
          }
          break;
        case OperationDataChannelType.Error:
          toast.error(t("Error"));
          break;
        case OperationDataChannelType.UploadedToCloud:
          if (data.segmentList && data.segmentList?.length) {
            // const deviceDynamicSegmentListRef = cloneDeep(deviceDynamicSegmentList);
            data.segmentList.forEach((segment, index) => {
              if (
                deviceDynamicSegmentListRef.current.findIndex((value) => value.id == segment) > -1
              ) {
                deviceDynamicSegmentListRef.current[
                  deviceDynamicSegmentListRef.current.findIndex((value) => value.id == segment)
                ].uploaded = true;
                //i repeat this because in share and download i dont set the property in the case the user cancel the operation
                deviceDynamicSegmentListRef.current[
                  deviceDynamicSegmentListRef.current.findIndex((value) => value.id == segment)
                ].sentToDevice = true;
              }
            });

            let allRequestedWasUploaded = false;
            let count = 0;
            for (let i = 0; i < refGroupRequestedOfTimesToUpload.current.length; i++) {
              if (
                deviceDynamicSegmentListRef.current.find(
                  (segment) =>
                    segment.id === refGroupRequestedOfTimesToUpload.current[i] &&
                    segment.uploaded == true
                )
              ) {
                count++;
              }
            }
            if (count === refGroupRequestedOfTimesToUpload.current.length) {
              allRequestedWasUploaded = true;
            }
            // waitUntilCounterOfSegmentsUploadedRef.current <=
            //   deviceDynamicSegmentListRef.current.filter(
            //     (value) => value.uploaded == true && value.processed == false
            //   ).length &&
            if (isProcessingNewSegments.current && allRequestedWasUploaded) {
              if (showDialogToShareUnderDynamicPlaylist) {
                setOpenShareDialog(true);
                setLoadingWebRtcInfo(false);
                isProcessingNewSegments.current = false;
                setShowDialogToShareUnderDynamicPlaylist(false);
              }
              // if (showDialogToDownloadUnderDynamicPlaylist) {
              //isProcessingNewSegments.current = false;
              // setLoadingWebRtcInfo(false);
              // setShowDialogToDownloadUnderDynamicPlaylist(false);
              // startDownloadTask();
              // }
              setArrivedNewSegment((prev) => !prev);
            }
          } else {
            toast.error(t("Error"));
          }
          break;
      }
    }
  }, [receiveDeviceMessage, isDynamicPlaylist]);
  useEffect(() => {
    if (finishedToReadPathNameInDynamic && startDate) {
      for (let i = 0; i < segmentsInDeviceInDynamic.current.length; i++) {
        const temp = segmentsInDeviceInDynamic.current[i].split("_");
        const time = temp[0];
        const duration = parseInt(temp[1].split(".")[0] || IdealDurationOfSegment.toString());
        const id = segmentsInDeviceInDynamic.current[i];
        const inTheCloud = refTimePathBlob.current.find(
          (segment) => segment.id == segmentsInDeviceInDynamic.current[i]
        )
          ? true
          : false;
        deviceDynamicSegmentListRef.current.push({
          id: id,
          time: time,
          duration: duration,
          uploaded: inTheCloud,
          pathname: accountId + "/" + serialNumber + "/videos/" + id,
          processed: false,
          sentToDevice: inTheCloud,
        });
      }

      const counter = deviceDynamicSegmentListRef.current.filter(
        (value) => value.uploaded === true
      ).length;
      if (counter == deviceDynamicSegmentListRef.current.length) {
        //("all in cloud");
        setLoadingWebRtcInfo(true);
        setArrivedNewSegment((prev) => !prev);
        isUploadedAllDynamic.current = true;
      } else if (counter > 0) {
        //("somes in cloud");
        setLoadingWebRtcInfo(true);
        setArrivedNewSegment((prev) => !prev);
        if (deviceDynamicSegmentListRef.current.length <= 10) {
          totalSegmentsToRequestToDevice = deviceDynamicSegmentListRef.current.length;
        }
        requestDynamicUpload(startDate);
      } else {
        //("nothing in cloud");
        if (deviceDynamicSegmentListRef.current.length <= 10) {
          totalSegmentsToRequestToDevice = deviceDynamicSegmentListRef.current.length;
        }
        requestDynamicUpload(startDate);
      }
    }
  }, [finishedToReadPathNameInDynamic, startDate]);
  useEffect(() => {
    if (showDialogToShareUnderDynamicPlaylist || showDialogToDownloadUnderDynamicPlaylist) {
      const theIds: string[] = [];
      //requesting upload the rest of videos
      deviceDynamicSegmentListRef.current.forEach((segment) => {
        if (!segment.uploaded) {
          theIds.push(segment.id);
        }
      });

      if (theIds.length) {
        const temp: SendDataChannelWebRTC = {
          operation: OperationDataChannelType.StartUploadToCloud,
          segmentList: theIds,
        };

        if (deviceChannel && deviceChannel.readyState === "open") {
          // The channel is open, you can safely send data
          deviceChannel && deviceChannel.send(JSON.stringify(temp));
          setLoadingWebRtcInfo(true);
          // waitUntilCounterOfSegmentsUploadedRef.current = theIds.length;
          refGroupRequestedOfTimesToUpload.current = theIds;
          isProcessingNewSegments.current = true;
          videoRef.current?.pause();
        } else {
          console.error("Channel closed");
          toast.error(t("Error"));
        }
      } else {
        if (showDialogToShareUnderDynamicPlaylist) {
          setOpenShareDialog(true);
          setShowDialogToShareUnderDynamicPlaylist(false);
        } else if (showDialogToDownloadUnderDynamicPlaylist) {
          setShowDialogToDownloadUnderDynamicPlaylist(false);
          startDownloadTask();
        }
      }
    }
  }, [showDialogToShareUnderDynamicPlaylist, showDialogToDownloadUnderDynamicPlaylist]);
  useEffect(() => {
    let hls: Hls | undefined;

    if (deviceDynamicSegmentListRef.current.length) {
      if (refHLS.current) {
        hls = refHLS.current; //new Hls();
      } else {
        hls = new Hls(
          initPlayListReaders
            ? {
                maxBufferLength: 40,
                backBufferLength: 20,
                maxBufferSize: 100, //MB
                nudgeMaxRetry: 10,
                maxMaxBufferLength: 3600,
              }
            : {
                enableWorker: true,
                lowLatencyMode: false,
                maxBufferLength: 40,
                maxBufferSize: 50 * 1024 * 1024,
                backBufferLength: 10,
                nudgeMaxRetry: 10,
                maxMaxBufferLength: 3600,
              }
        );
        refHLS.current = hls;
      }

      let isLastSegment = false; // this is to avoid left the playlist in VOD mode in the last part of the video
      const listOfSegments: DynamicSegment[] = [];
      for (let i = 0; i < deviceDynamicSegmentListRef.current.length; i++) {
        if (
          i + 1 == deviceDynamicSegmentListRef.current.length &&
          deviceDynamicSegmentListRef.current[i].uploaded
        ) {
          isLastSegment = true;
          deviceDynamicSegmentListRef.current[i].processed = true; // to mark the last one because in creatingHLSPlaylist will not be marked
        }
        if (deviceDynamicSegmentListRef.current[i].uploaded) {
          listOfSegments.push(deviceDynamicSegmentListRef.current[i]);
        }
      }
      isProcessedAllDynamic.current = !deviceDynamicSegmentListRef.current.find(
        (value) => value.processed === false
      );
      if (deviceDynamicSegmentListRef.current.length == listOfSegments.length) {
        isUploadedAllDynamic.current = true;
        isProcessedAllDynamic.current = true;
      }
      creatingHLSPlaylist(hls, listOfSegments, !isUploadedAllDynamic.current || !isLastSegment);
      videoRef.current?.addEventListener("timeupdate", (event: any) => timeUpdateEvent());
      videoRef.current?.addEventListener("play", () => playEvent());
      videoRef.current?.addEventListener("pause", () => pauseEvent());
      videoRef.current?.addEventListener("ended", () => endedEvent());
      videoRef.current?.addEventListener("waiting", () => waitingEvent());
      videoRef.current?.addEventListener("playing", () => playingEvent());
      videoRef.current?.addEventListener("progress", () => progressEvent());
      document.addEventListener("keydown", documentKeyDownEvent);
      videoRef.current?.addEventListener("canplay", handleCanPlay);
    }
    // Clean up  when the component unmounts.
    return () => {
      if (hls && Hls.isSupported()) {
        hls.destroy();
        refHLS.current = undefined;
      }
      videoRef.current?.removeEventListener("timeupdate", timeUpdateEvent);
      videoRef.current?.removeEventListener("play", playEvent);
      videoRef.current?.removeEventListener("pause", pauseEvent);
      videoRef.current?.removeEventListener("ended", endedEvent);
      videoRef.current?.removeEventListener("loadedmetadata", loadedmetadataEvent);
      videoRef.current?.removeEventListener("waiting", waitingEvent);
      videoRef.current?.removeEventListener("playing", playingEvent);
      videoRef.current?.removeEventListener("progress", progressEvent);
      document.removeEventListener("keydown", documentKeyDownEvent);
      videoRef.current?.removeEventListener("canplay", handleCanPlay);
    };
  }, [arrivedNewSegment]);

  function requestDynamicUpload(time: string) {
    const theIds: string[] = [];
    let refIndex = 0;
    let hasTheSegment = false;
    deviceDynamicSegmentListRef.current.forEach((segment, index) => {
      if (
        moment(time).add(IdealDurationOfSegment, "seconds").isSameOrAfter(moment(segment.time)) &&
        !theIds.length &&
        !segment.processed &&
        !segment.sentToDevice
      ) {
        if (moment(time).diff(moment(segment.time), "seconds") <= IdealDurationOfSegment) {
          theIds.push(segment.id);
          refIndex = index;
        }
      } else if (theIds.length && refIndex + totalSegmentsToRequestToDevice > index) {
        theIds.push(segment.id);
      }

      ///////
      if (
        moment(time).diff(moment(segment.time), "seconds") <= IdealDurationOfSegment &&
        (segment.processed || segment.sentToDevice)
      ) {
        hasTheSegment = true;
      }
    });
    if (
      theIds.length == 0 &&
      deviceDynamicSegmentListRef.current.length &&
      !deviceDynamicSegmentListRef.current.find((segment) => segment.processed == true) &&
      !deviceDynamicSegmentListRef.current.find((segment) => segment.uploaded == true)
    ) {
      //at the beggining or first time, in the case doesnt have anything or the difference is greater than IdealDurationOfSegment, and there arent any uploaded or proccesed, will find what is the next ones
      deviceDynamicSegmentListRef.current.forEach((segment, index) => {
        if (!segment.processed && !segment.sentToDevice && totalSegmentsToRequestToDevice > index) {
          theIds.push(segment.id);
        }
      });
    }
    if (theIds.length) {
      const temp: SendDataChannelWebRTC = {
        operation: OperationDataChannelType.StartUploadToCloud,
        segmentList: theIds,
      };
      for (let i = 0; i < theIds.length; i++) {
        if (deviceDynamicSegmentListRef.current.find((segment) => segment.id == theIds[i])) {
          (
            deviceDynamicSegmentListRef.current.find(
              (segment) => segment.id == theIds[i]
            ) as DynamicSegment
          ).sentToDevice = true;
        }
      }

      if (deviceChannel && deviceChannel.readyState === "open") {
        // The channel is open, you can safely send data
        deviceChannel && deviceChannel.send(JSON.stringify(temp));
        refTheTimeInSecondsJumped.current = refTheTimeInSecondsJumped.current
          ? refTheTimeInSecondsJumped.current
          : videoRef.current?.currentTime || 0;

        setLoadingWebRtcInfo(true);
        refGroupRequestedOfTimesToUpload.current = theIds;
        isProcessingNewSegments.current = true;
        videoRef.current?.pause();
      } else {
        console.error("Channel closed");
        toast.error(t("Error"));
      }
    } else if (!hasTheSegment && refTheTimeInSecondsJumped.current > 0) {
      toast.info(t("VideoNotFoundInDevice"));
    } else if (refTheTimeInSecondsJumped.current > 0 && videoRef.current?.paused) {
      //jumped to a time that is already in the playlist (processed) if the video is paused
      (videoRef.current as any).currentTime = refTheTimeInSecondsJumped.current;
      refTheTimeInSecondsJumped.current = 0;
    }
  }

  useEffect(() => {
    //analyze if need to request more segments and currect where the cursor is located
    if (isDynamicPlaylist) {
      if (refTheTimeInSecondsJumped.current) {
        (videoRef.current as any).currentTime = refTheTimeInSecondsJumped.current;
        refTheTimeInSecondsJumped.current = 0;
      } else {
        const time = moment(startDate).add(currentTime, "seconds").toISOString();
        requestDynamicUpload(time);
      }
    }
  }, [currentTime, isDynamicPlaylist]);

  ///////////////////////////////////////////////////

  const [loadedTheList, setLoadedTheList] = useState(false);

  const videoRef = useRef<HTMLVideoElement>();
  const [open, setOpen] = useState(true);
  const [credentialCloudStorange, setCredentialCloudStorange] =
    useState<AWSCredentialResponseModel>();
  const [loadingTheInfo, setLoadingTheInfo] = useState(false);
  const [loadingCredentials, setLoadingCredentials] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [fatalErrorMessage, setFatalErrorMessage] = useState("");
  let hasFatalErrorMessage = Boolean(fatalErrorMessage);
  const [loadedTheVideo, setLoadedTheVideo] = useState(false);
  const [openShareDialog, setOpenShareDialog] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const loadCredentialsStorange = async () => {
      try {
        setLoadingCredentials(true);
        const response = await getCloudVideoCredential(signal);
        if (!response.data.hasErrors) {
          setCredentialCloudStorange(response.data.payload);
        }
      } catch (error) {
        //
      } finally {
        setLoadingCredentials(false);
      }
    };
    if (!isPublic) {
      loadCredentialsStorange();
    }
    return () => {
      controller.abort();
    };
  }, [isPublic]);

  const folderPath = accountId + "/" + serialNumber + "/videos/";
  const refTimePathBlob = useRef<TimePathBlobRelation[]>([]);
  const refSignedUrls = useRef<string[]>([]); //the first value must be init
  const refPlaylistToDownload = useRef<string>("");

  const [finishedToReadPathName, setFinishedToReadPathName] = useState(false);
  const [progressBarBufferList, setProgressBarBufferList] = useState<any[]>([]);

  ////////////LOCAL PLAYLIST////////////////////////////////////////////
  const [isPlaylistFromLocal, setISPlaylistFromLocal] = useState(true);
  const [urlPlaylistLocal, setUrlPlaylistLocal] = useState<string | undefined>();
  const refUrlPlaylistLocal = useRef<string | undefined>();

  useEffect(() => {
    const load = async () => {
      try {
        const identifier = `${identifierId}_${startDate}_${endDate}`;
        const response = await connectToLocalPlayback(serialNumber, identifier, startDate, endDate);
        if (!response.data.hasErrors) {
          if (response.data.payload) {
            setUrlPlaylistLocal(response.data.payload);
            refUrlPlaylistLocal.current = response.data.payload;
          } else {
            setFatalErrorMessage(t("NotFound"));
          }
        }
      } catch (error) {
        //
      } finally {
        //
      }
    };
    if (isPlaylistFromLocal) {
      load();
    }
  }, [startDate, endDate, isPlaylistFromLocal]);

  useEffect(() => {
    let hls: Hls | undefined;
    if (urlPlaylistLocal) {
      if (refHLS.current) {
        hls = refHLS.current; //new Hls();
      } else {
        hls = new Hls(
          initPlayListReaders
            ? {
                maxBufferLength: 40,
                backBufferLength: 20,
                maxBufferSize: 100, //MB
                nudgeMaxRetry: 10,
                maxMaxBufferLength: 3600,
              }
            : {
                enableWorker: true,
                lowLatencyMode: false,
                maxBufferLength: 40,
                maxBufferSize: 50 * 1024 * 1024,
                backBufferLength: 10,
                nudgeMaxRetry: 10,
                maxMaxBufferLength: 3600,
              }
        );
        refHLS.current = hls;
        videoRef.current?.addEventListener("timeupdate", (event: any) => timeUpdateEvent());
        videoRef.current?.addEventListener("play", () => playEvent());
        videoRef.current?.addEventListener("pause", () => pauseEvent());
        videoRef.current?.addEventListener("ended", () => endedEvent());
        videoRef.current?.addEventListener("waiting", () => waitingEvent());
        videoRef.current?.addEventListener("playing", () => playingEvent());
        videoRef.current?.addEventListener("progress", () => progressEvent());
        document.addEventListener("keydown", documentKeyDownEvent);
        videoRef.current?.addEventListener("canplay", handleCanPlay);
      }
    }
    return () => {
      if (hls && Hls.isSupported()) {
        hls.destroy();
        refHLS.current = undefined;
      }
      videoRef.current?.removeEventListener("timeupdate", timeUpdateEvent);
      videoRef.current?.removeEventListener("play", playEvent);
      videoRef.current?.removeEventListener("pause", pauseEvent);
      videoRef.current?.removeEventListener("ended", endedEvent);
      videoRef.current?.removeEventListener("loadedmetadata", loadedmetadataEvent);
      videoRef.current?.removeEventListener("waiting", waitingEvent);
      videoRef.current?.removeEventListener("playing", playingEvent);
      videoRef.current?.removeEventListener("progress", progressEvent);
      document.removeEventListener("keydown", documentKeyDownEvent);
      videoRef.current?.removeEventListener("canplay", handleCanPlay);
    };
  }, [urlPlaylistLocal]);
  useFireStoreSubscription(
    isPlaylistFromLocal ? FirestorePathRoute.Users.Index : undefined,
    FirestorePathRoute.Users.VideoSources,
    (data: NotificationModel[] | NotificationModel) =>
      notificationOfSuccessLocalPlaylistCreation(data as NotificationModel),
    userId
  );

  const notificationOfSuccessLocalPlaylistCreation = (data: NotificationModel) => {
    if (data.Code === NotificationVideoSourcesSavedResponse.videosources_save_playlist_response) {
      const value: NotificationLocalPlaylist = data.Data;

      if (refUrlPlaylistLocal.current) {
        if (value.VideoSourceOperation.processed) {
          if (refHLS.current) {
            createHls(refHLS.current, refUrlPlaylistLocal.current);
          }
        } else {
          setFatalErrorMessage(t("NotFound"));
        }
        setLoadingTheInfo(false);
      }
    }
  };
  /////////////////////////////////////////////////////

  function listVideos(marker: string, startD: string, endD: string) {
    const awsConfig: ClientConfiguration = {
      region: credentialCloudStorange?.credential.region,
      credentials: {
        accessKeyId: credentialCloudStorange?.credential.accessKeyId as string,
        secretAccessKey: credentialCloudStorange?.credential.secretAccessKey as string,
        sessionToken: credentialCloudStorange?.credential.sessionToken,
      },
      endpoint: process.env.REACT_APP_CloudStorange_URL,
    };
    const s3 = new AWS.S3(awsConfig);
    let timePrefix = "";

    const temp = startD.split(":");
    //split T => //['2023-09-01', '18:00:20.000Z'] //after this tempstart should add days
    //split : => ['2023-09-01T18', '00','20.000Z'] //after this tempstart should add hours
    timePrefix = temp[0];

    const params = {
      Bucket:
        credentialCloudStorange?.credential.bucketName ||
        (process.env.REACT_APP_CloudStorange_BucketName as string),
      Prefix: folderPath + timePrefix,
      Marker: marker,
    };
    s3.listObjects(params, (err, data) => {
      if (err) {
        // toast.error(t("Error"));
        console.error("Error listing objects:", err);
        setFatalErrorMessage(t("Error"));

        Sentry.captureMessage("Error Playback: cannot list objects from provider", {
          level: Severity.Critical,
          contexts: {
            errorInfo: {
              error: JSON.stringify(err),
            },
          },
        });
      } else {
        // 4/000558734115-11101985/videos/2023-09-12T18:00:00:000Z_20.m4s
        // 4/3C62F0077B49-2111DLT000112/videos/2024-04-25T14:31:18.000Z_8.ts
        if (data?.Contents && data?.Contents?.length) {
          const paths: TimePathBlobRelation[] = [];

          data.Contents.forEach((value) => {
            const path: string = value.Key as string;
            const time = path.split("/")[path.split("/").length - 1].split(".")[0] + ".000Z";
            const id = path.split("/")[path.split("/").length - 1];
            const duration = path
              .split("/")
              [path.split("/").length - 1].split(".")[1]
              .split("_")[1];
            if (
              moment(time).isBetween(
                moment(startD).subtract(IdealDurationOfSegment, "second"),
                moment(endD).add(IdealDurationOfSegment, "seconds"),
                undefined,
                "[]"
              )
            ) {
              paths.push({
                duration: parseInt(duration) || IdealDurationOfSegment,
                time: time,
                pathname: path,
                id: id,
              });
            }
          });

          refTimePathBlob.current = refTimePathBlob.current.concat(paths);
        }

        const contents: any = data?.Contents;
        const path: any = contents.length && contents[contents.length - 1]?.Key;
        const time = path && path?.split("/")[path.split("/").length - 1].split(".")[0] + ".000Z";
        if (contents.length && moment(time).isBefore(moment(endD))) {
          // If there are more objects to paginate, call listObjects again with a new marker
          if (data.IsTruncated) {
            const contents: any = data?.Contents;
            const temp: any = contents[contents.length - 1];
            listVideos(temp?.Key || "", startD, endD);
            setFinishedToReadPathName(false);
          } else {
            const tempStart = moment(startD).add(1, "hours").toISOString();
            const contents: any = data?.Contents;
            const tempMarker: any = contents[contents.length - 1];
            listVideos(tempMarker?.Key, tempStart, endD);
          }
        } else {
          if (isDynamicPlaylist) {
            setFinishedToReadPathNameInDynamic(true);
          } else {
            setFinishedToReadPathName(true);
            if (refTimePathBlob.current.length) {
              const timesBetweenTheStartDateTimeAndEndDateTime: TimePathBlobRelation[] = [];

              const starDateMoment = moment(startDate);
              const endDateMoment = moment(endDate);

              for (let index = 0; index < refTimePathBlob.current.length; index++) {
                if (refTimePathBlob.current[index] && refTimePathBlob.current[index].time) {
                  const timeMoment = moment(refTimePathBlob.current[index].time);
                  if (timeMoment.isBetween(starDateMoment, endDateMoment, undefined, "[]")) {
                    timesBetweenTheStartDateTimeAndEndDateTime.push(refTimePathBlob.current[index]);
                  } else if (
                    !timeMoment.isAfter(endDateMoment) &&
                    timeMoment
                      .add(refTimePathBlob.current[index].duration ?? 0, "seconds")
                      .isSameOrAfter(starDateMoment)
                  ) {
                    timesBetweenTheStartDateTimeAndEndDateTime.push(refTimePathBlob.current[index]);
                  }
                }
              }

              refTimePathBlob.current = timesBetweenTheStartDateTimeAndEndDateTime;

              if (refTimePathBlob.current.length) {
                setLoadedTheList(true);
                const tempInit = moment(refTimePathBlob.current[0].time).isBefore(moment(startDate))
                  ? refTimePathBlob.current[0].time
                  : startDate;
                const tempEnd = moment(
                  refTimePathBlob.current[refTimePathBlob.current.length - 1].time
                ).isAfter(moment(endDate))
                  ? refTimePathBlob.current[refTimePathBlob.current.length - 1].time
                  : endDate;
                setStartDate(tempInit);
                setEndDate(tempEnd);
              } else {
                setFatalErrorMessage(t("NotFound"));
                Sentry.captureMessage("Error Playback: not found any segment", {
                  level: Severity.Critical,
                  contexts: {
                    errorInfo: {
                      error: "Not Found",
                    },
                  },
                });
              }
            } else {
              setFatalErrorMessage(t("NotFound"));
              Sentry.captureMessage("Error Playback: not found any segment", {
                level: Severity.Critical,
                contexts: {
                  errorInfo: {
                    error: "Not Found",
                  },
                },
              });
            }
          }
        }
      }
    });
  }
  //read the pathName
  useEffect(() => {
    if (
      credentialCloudStorange &&
      !finishedToReadPathName &&
      startDate.length &&
      endDate.length &&
      !isPublic &&
      !isDynamicPlaylist
    ) {
      setLoadingTheInfo(true);
      if (!isPlaylistFromLocal) {
        listVideos("", startDate, endDate);
      }
    }
  }, [
    credentialCloudStorange,
    startDate,
    endDate,
    isPublic,
    isDynamicPlaylist,
    isPlaylistFromLocal,
  ]);

  //download to Blob the videos
  const getTheBlobVideo = async (pathName: string, credentials: AWSCredentialResponseModel) => {
    try {
      const response = await getCloudStorangeVideo(pathName, credentials);
      const videoBlob = new Blob([response.Body], { type: "video/mp4" });
      const blobUrl = URL.createObjectURL(videoBlob);
      return blobUrl;
      // videoRef.current.src = blobUrl;
    } catch (err) {
      //
    } finally {
      //
    }
  };
  const getTheBase64Image = async (time: string, credentials: AWSCredentialResponseModel) => {
    try {
      const response: GetObjectOutput = await getCloudStorangeImage(
        accountId as unknown as string,
        serialNumber,
        time,
        credentials
      );
      const imageBase64 = Buffer.from(response.Body as any).toString("base64");
      const imageUrl = `data:image/jpeg;base64,${imageBase64}`;
      return imageUrl;
    } catch (err: any) {
      //
    } finally {
      //
    }
  };
  const createHls = (hls: Hls, playlistUrl: string) => {
    const video: any = videoRef.current;
    // video.load();
    if (Hls.isSupported()) {
      try {
        hls.loadSource(playlistUrl); //"https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8");
        hls.attachMedia(video);
        hls.startLoad();
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          if (isPublic) {
            video.muted = true;
            video.play();
          }
        });
        hls.on(Hls.Events.MANIFEST_LOADED, function () {
          loadedmetadataEvent();
        });
        hls.on(Hls.Events.FRAG_CHANGED, (event, data) => {
          // console.log("Playing fragment:", data.frag.start);
        });

        hls.on(Hls.Events.ERROR, function (event: any, data: any) {
          console.error("Error");
          Sentry.captureMessage("Error Playback: from HLS library", {
            level: Severity.Critical,
            contexts: {
              errorInfo: {
                error: JSON.stringify(data),
              },
            },
          });
          if (data.response && data.response.code === 403) {
            setFatalErrorMessage(t("TheLinkExpired"));
            hasFatalErrorMessage = true;
          } else if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.MEDIA_ERROR:
                console.error("fatal media error encountered, try to recover");
                if (counterFatalMediaErrors < 5) {
                  counterFatalMediaErrors++;
                  hls.recoverMediaError();
                } else {
                  setFatalErrorMessage(t("ErrorHLSSupport"));
                }
                break;
              case Hls.ErrorTypes.NETWORK_ERROR:
                console.error("fatal network error encountered");
                // All retries and media options have been exhausted.
                setFatalErrorMessage(t("ErrorHLSSupport"));
                hasFatalErrorMessage = true;
                break;
              case Hls.ErrorTypes.OTHER_ERROR:
                console.log("HLS.Events.Other Error ");
                hls.startLoad();
                break;
              default:
                // cannot recover
                setFatalErrorMessage(t("ErrorHLSSupport"));
                hasFatalErrorMessage = true;
                hls.destroy();
                break;
            }
          } else if (
            data.details === Hls.ErrorDetails.INTERNAL_EXCEPTION &&
            data.type === Hls.ErrorTypes.OTHER_ERROR
          ) {
            console.warn("internalException");
            hls.startLoad();
          } else if (data.type === Hls.ErrorTypes.MEDIA_ERROR) {
            console.warn("mediaError");
          }
        });
      } catch (error) {
        console.error("internal");
        hls.startLoad();
      }
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = playlistUrl;
      video.addEventListener("loadedmetadata", loadedmetadataEvent);
    } else {
      setFatalErrorMessage(t("ErrorHLSSupport"));
      hasFatalErrorMessage = true;
      console.error("HLS is not supported in this browser");
    }
  };

  function verifyExtensionSegment(listOfSegments: DynamicSegment[] | TimePathBlobRelation[]) {
    const pathname = listOfSegments[0].pathname;
    const extensionSegment = pathname?.split(".")[2];
    if (extensionSegment === "m4s") {
      return true;
    }
    return false;
  }

  function creatingHLSPlaylist(
    hls: Hls,
    listOfSegments: DynamicSegment[] | TimePathBlobRelation[],
    isDynamic?: boolean
  ) {
    if (!isPublic) {
      extensionSegmentVideoM4s.current = verifyExtensionSegment(listOfSegments);

      const awsConfig: ClientConfiguration = {
        region: credentialCloudStorange?.credential.region,
        credentials: {
          accessKeyId: credentialCloudStorange?.credential.accessKeyId as string,
          secretAccessKey: credentialCloudStorange?.credential.secretAccessKey as string,
          sessionToken: credentialCloudStorange?.credential.sessionToken,
        },
        endpoint: process.env.REACT_APP_CloudStorange_URL,
      };
      const s3 = new AWS.S3(awsConfig);

      // Create an array to store the playlist entries
      const playlistEntries = [];
      const playlistToDownload = [];

      // Add the HLS header
      playlistEntries.push(`#EXTM3U`);
      playlistEntries.push(`#EXT-X-VERSION:7`);
      playlistEntries.push(`#EXT-X-TARGETDURATION:${Math.ceil(IdealDurationOfSegment)}`);

      playlistToDownload.push(`#EXTM3U`);
      playlistToDownload.push(`#EXT-X-VERSION:7`);
      playlistToDownload.push(`#EXT-X-TARGETDURATION:${Math.ceil(IdealDurationOfSegment)}`);
      // playlistEntries.push(`#EXT-X-MEDIA-SEQUENCE:0`);
      // playlistEntries.push(`#EXT-X-CACHE:MAX-AGE=60`);
      let durationOfPlaylist = 0;
      if (isDynamic) {
        playlistEntries.push(`#EXT-X-START:TIME-OFFSET=${refTheTimeInSecondsJumped.current}`);
        playlistEntries.push(`#EXT-X-PLAYLIST-TYPE:VOD`); //https://datatracker.ietf.org/doc/html/rfc8216#page-23
      }
      // const init = s3.getSignedUrl("getObject", {
      //   Bucket: process.env.REACT_APP_CloudStorange_BucketName,
      //   Key: accountId + "/" + serialNumber + "/init.mp4",
      //   Expires: expirationTime,
      // });
      if (extensionSegmentVideoM4s.current) {
        const init: string = initPlayList as string;
        playlistEntries.push(`#EXT-X-MAP:URI="${init}"`);
        refSignedUrls.current.push(init);
        playlistToDownload.push(`#EXT-X-MAP:URI="init.mp4"`);
      }
      // Add segment entries to the playlist
      let previousSegmentTime = moment(listOfSegments[0].time).isBefore(moment(startDate))
        ? listOfSegments[0].time
        : startDate;
      let previousSegmentDuration = moment(listOfSegments[0].time).isBefore(moment(startDate))
        ? listOfSegments[0].duration
        : 0;

      let previousSegmentUrl = "";
      const temp = listOfSegments.find((elem) => elem.duration === 1);
      let showVirtualBlackscreenBecauseHasSegmentOf1second = true;
      if (!initPlayListReaders) {
        if (temp) {
          const signedSegment1 = s3.getSignedUrl("getObject", {
            Bucket: credentialCloudStorange?.credential.bucketName,
            Key: temp.pathname,
            Expires: expirationTime,
          });
          previousSegmentUrl = signedSegment1;
        } else {
          //playlist doesnt have any 1 second segment
          showVirtualBlackscreenBecauseHasSegmentOf1second = false;
          const signedSegment1 = s3.getSignedUrl("getObject", {
            Bucket: credentialCloudStorange?.credential.bucketName,
            Key: listOfSegments[0].pathname,
            Expires: expirationTime,
          });
          previousSegmentUrl = signedSegment1;
        }
      }

      if (!isDynamic) {
        rangeTimesWithoutVideo.current = [];
      }
      let isEnteredToBalckScreen = false;

      listOfSegments.forEach((segment, index) => {
        const segmentUrl = segment.pathname;
        const segmentDuration = segment.duration;
        const segmentTime = segment.time;

        if (!initPlayListReaders) {
          shortBlackScreenVideoUrl_1S_TS = previousSegmentUrl;
          largeBlackScreenVideoUrl_TS = previousSegmentUrl;
        }
        if (
          moment(segmentTime).isAfter(
            moment(previousSegmentTime).add(previousSegmentDuration, "seconds"),
            "seconds"
          )
        ) {
          const differenceSeconds = moment(segmentTime).diff(
            moment(previousSegmentTime).add(previousSegmentDuration, "seconds"),
            "seconds"
          );

          if (differenceSeconds > 1) {
            //there is problem
            if (!isDynamic) {
              const startTime = moment(previousSegmentTime)
                .add(previousSegmentDuration, "seconds")
                .toISOString();
              const endTime = moment(segmentTime).toISOString();

              rangeTimesWithoutVideo.current.push({ startTime, endTime });
            }

            const howManyBigOne = Math.floor(differenceSeconds / IdealDurationOfSegment);
            const howManySmallOne = differenceSeconds % IdealDurationOfSegment;

            if (howManyBigOne && showVirtualBlackscreenBecauseHasSegmentOf1second) {
              for (let i = 1; i <= howManyBigOne; i++) {
                durationOfPlaylist = durationOfPlaylist + IdealDurationOfSegment;
                const time = moment(previousSegmentTime)
                  .add(previousSegmentDuration, "seconds")
                  .toISOString();

                if (moment(segmentTime).isAfter(time)) {
                  playlistEntries.push(`#EXT-X-DISCONTINUITY`);
                  // playlistToDownload.push(`#EXT-X-DISCONTINUITY`);
                  isEnteredToBalckScreen = true;

                  playlistEntries.push(`#EXTINF:${IdealDurationOfSegment}.0,`);
                  // playlistToDownload.push(`#EXTINF:${IdealDurationOfSegment}.0,`);

                  playlistEntries.push(`#EXT-X-PROGRAM-DATE-TIME:${time}`);
                  // playlistToDownload.push(`#EXT-X-PROGRAM-DATE-TIME:${time}`);

                  if (extensionSegmentVideoM4s.current) {
                    playlistEntries.push(
                      i % 2 == 0 ? largeBlackScreenVideoUrl_1 : largeBlackScreenVideoUrl_2
                    );
                  } else {
                    playlistEntries.push(largeBlackScreenVideoUrl_TS);
                  }

                  if (extensionSegmentVideoM4s.current) {
                    // playlistToDownload.push(
                    //   i % 2 == 0
                    //     ? largeBlackScreenVideoUrl_1?.split("/")[5]
                    //     : largeBlackScreenVideoUrl_2?.split("/")[5]
                    // );
                  } else {
                    // playlistToDownload.push(largeBlackScreenVideoUrl_TS?.split("/")[5]);
                  }

                  if (extensionSegmentVideoM4s.current) {
                    refSignedUrls.current.push(
                      (i % 2 == 0
                        ? (largeBlackScreenVideoUrl_1 as string)
                        : (largeBlackScreenVideoUrl_2 as string)) +
                        "?time=" +
                        time
                    );
                  } else {
                    refSignedUrls.current.push(largeBlackScreenVideoUrl_TS + "?time=" + time);
                  }
                  previousSegmentTime = moment(previousSegmentTime)
                    .add(previousSegmentDuration, "seconds")
                    .toISOString();
                  previousSegmentDuration = IdealDurationOfSegment;
                }
              }
            }
            if (howManySmallOne && showVirtualBlackscreenBecauseHasSegmentOf1second) {
              for (let i = 0; i < howManySmallOne; i++) {
                durationOfPlaylist = durationOfPlaylist + 1;
                const time = moment(previousSegmentTime)
                  .add(previousSegmentDuration, "seconds")
                  .toISOString();

                if (moment(segmentTime).isAfter(time)) {
                  playlistEntries.push(`#EXT-X-DISCONTINUITY`);
                  // playlistToDownload.push(`#EXT-X-DISCONTINUITY`);
                  isEnteredToBalckScreen = true;

                  playlistEntries.push("#EXTINF:1.0,");
                  // playlistToDownload.push("#EXTINF:1.0,");

                  playlistEntries.push(`#EXT-X-PROGRAM-DATE-TIME:${time}`);
                  // playlistToDownload.push(`#EXT-X-PROGRAM-DATE-TIME:${time}`);

                  if (extensionSegmentVideoM4s.current) {
                    playlistEntries.push(
                      i % 2 == 0 ? shortBlackScreenVideoUrl_1 : shortBlackScreenVideoUrl_2
                    );
                  } else {
                    playlistEntries.push(shortBlackScreenVideoUrl_1S_TS);
                  }

                  if (extensionSegmentVideoM4s.current) {
                    // playlistToDownload.push(
                    //   i % 2 == 0
                    //     ? shortBlackScreenVideoUrl_1?.split("/")[5]
                    //     : shortBlackScreenVideoUrl_2?.split("/")[5]
                    // );
                  } else {
                    // playlistToDownload.push(shortBlackScreenVideoUrl_1S_TS?.split("/")[5]);
                  }

                  if (extensionSegmentVideoM4s.current) {
                    refSignedUrls.current.push(
                      (i % 2 == 0
                        ? (shortBlackScreenVideoUrl_1 as string)
                        : (shortBlackScreenVideoUrl_2 as string)) +
                        "?time=" +
                        time
                    );
                  } else {
                    refSignedUrls.current.push(shortBlackScreenVideoUrl_1S_TS + "?time=" + time);
                  }
                  previousSegmentTime = moment(previousSegmentTime)
                    .add(previousSegmentDuration, "seconds")
                    .toISOString();
                  previousSegmentDuration = 1;
                }
              }
            }
          }

          /////////////////////////////////////
        }
        if (
          isDynamic &&
          deviceDynamicSegmentListRef.current.find((value) => value.time == segment.time)
        ) {
          (
            deviceDynamicSegmentListRef.current.find(
              (value) => value.time == segment.time
            ) as DynamicSegment
          ).processed = true;
        }
        previousSegmentTime = segmentTime;
        previousSegmentDuration = segmentDuration;
        durationOfPlaylist = durationOfPlaylist + segmentDuration;

        if ((!extensionSegmentVideoM4s.current && index !== 0) || isEnteredToBalckScreen) {
          isEnteredToBalckScreen = false;
          if (!initPlayListReaders) {
            playlistEntries.push(`#EXT-X-DISCONTINUITY`);
            // playlistToDownload.push(`#EXT-X-DISCONTINUITY`);
          }
        }

        playlistEntries.push(`#EXTINF:${segmentDuration.toFixed(1)}0,`);
        playlistToDownload.push(`#EXTINF:${segmentDuration.toFixed(1)}0,`);

        playlistEntries.push(`#EXT-X-PROGRAM-DATE-TIME:${moment(segmentTime).toISOString()}`);
        playlistToDownload.push(`#EXT-X-PROGRAM-DATE-TIME:${moment(segmentTime).toISOString()}`);

        const signedSegment = s3.getSignedUrl("getObject", {
          Bucket: credentialCloudStorange?.credential.bucketName,
          Key: segmentUrl,
          Expires: expirationTime,
        });
        previousSegmentUrl = signedSegment;

        playlistEntries.push(signedSegment);

        if (extensionSegmentVideoM4s.current) {
          playlistToDownload.push(
            `${segmentTime.split(".")[0].replaceAll("-", "").replaceAll(":", "")}.m4s`
          );
        } else {
          playlistToDownload.push(
            `${segmentTime.split(".")[0].replaceAll("-", "").replaceAll(":", "")}.ts`
          );
        }

        refSignedUrls.current.push(signedSegment);
      });

      if (!isDynamic) {
        // Add the end of playlist marker
        playlistEntries.push(`#EXT-X-ENDLIST`);
        playlistToDownload.push(`#EXT-X-ENDLIST`);
      } else {
        //fill the rest of the video with blackscreen
        if (dynamicTotalDuration.current > durationOfPlaylist) {
          const count = dynamicTotalDuration.current - durationOfPlaylist;
          for (let i = 0; i < count; i++) {
            const time = moment(previousSegmentTime)
              .add(previousSegmentDuration, "seconds")
              .toISOString();

            playlistEntries.push("#EXTINF:1.0,");
            // playlistToDownload.push("#EXTINF:1.0,");

            playlistEntries.push(`#EXT-X-PROGRAM-DATE-TIME:${time}`);
            // playlistToDownload.push(`#EXT-X-PROGRAM-DATE-TIME:${time}`);

            playlistEntries.push(
              i % 2 == 0 ? shortBlackScreenVideoUrl_1 : shortBlackScreenVideoUrl_2
            );

            // playlistToDownload.push(
            //   i % 2 == 0
            //     ? shortBlackScreenVideoUrl_1?.split("/")[5]
            //     : shortBlackScreenVideoUrl_2?.split("/")[5]
            // );

            refSignedUrls.current.push(
              (i % 2 == 0
                ? (shortBlackScreenVideoUrl_1 as string)
                : (shortBlackScreenVideoUrl_2 as string)) +
                "?time=" +
                time
            );

            previousSegmentTime = moment(previousSegmentTime)
              .add(previousSegmentDuration, "seconds")
              .toISOString();
            previousSegmentDuration = 1;
          }
        }
      }

      // Join the playlist entries into a single string
      const playlistContent = playlistEntries.join("\n");
      refPlaylistToDownload.current = playlistToDownload.join("\n");
      const blobPlaylistContent = new Blob([playlistContent], {
        type: "application/vnd.apple.mpegurl",
      });

      let playlistUrl = "";
      let playlistNameForIos = "";
      // if (isIOS) {
      playlistNameForIos = uuid() + "-rangeDate-" + startDate + "-" + endDate;
      //https://knowledgebase.wasabi.com/hc/en-us/articles/115002350192-How-do-I-use-AWS-SDK-for-JavaScript-with-Wasabi-
      const object_upload_params: PutObjectRequest = {
        Bucket: credentialCloudStorange?.credential.bucketName as string,
        Key: accountId + "/misc/" + serialNumber + "/" + playlistNameForIos + ".m3u8",
        Body: blobPlaylistContent,
      };
      console.time("time");
      s3.upload(object_upload_params, async function (err: any, data: ManagedUpload.SendData) {
        if (err) {
          // an error occurred
          setFatalErrorMessage(t("Error"));
          Sentry.captureMessage("Error Playback: uploading playlist", {
            level: Severity.Critical,
            contexts: {
              errorInfo: {
                error: JSON.stringify(err),
              },
            },
          });
          hasFatalErrorMessage = true;
          console.error(err, err.stack);
        } else {
          playlistUrl = s3.getSignedUrl("getObject", {
            Bucket: credentialCloudStorange?.credential.bucketName,
            Key: data.Key,
            Expires: expirationTime,
          });
          console.timeEnd("time");
          createHls(hls, playlistUrl);
        }
      });
      console.log("---------------------------------------------------------");
      if (!showVirtualBlackscreenBecauseHasSegmentOf1second) {
        rangeTimesWithoutVideo.current = [];
      }
    } else {
      createHls(hls, publicUrl as string);
    }
  }

  const handleCanPlay = () => {
    setLoadingTheInfo(false);
    setLoadingWebRtcInfo(false);
  };
  const refShowNoVideoDesign = useRef(false);
  const timeUpdateEvent = () => {
    if (!isProcessedAllDynamic.current && isDynamicPlaylist) {
      if (!isProcessingNewSegments.current) {
        setCurrentTime(videoRef.current?.currentTime || 0);
        // setLoadingWebRtcInfo(false);
      }
      setDuration(dynamicTotalDuration.current);
    } else {
      setDuration(videoRef.current?.duration || 0);
      setCurrentTime(videoRef.current?.currentTime || 0);
      // setLoadingWebRtcInfo(false);
    }
    // setLoadingTheInfo(false);
    if (checkIfTimeIsInsideAnyRangeOfBlackScreen(videoRef.current?.currentTime || 0)) {
      refShowNoVideoDesign.current = true;
    } else {
      refShowNoVideoDesign.current = false;
    }
  };
  function checkIfTimeIsInsideAnyRangeOfBlackScreen(time1: number) {
    if (rangeTimesWithoutVideo.current) {
      const time = moment(startDate).add(time1, "seconds");
      rangeTimesWithoutVideo.current.sort(
        (a, b) => moment(a.startTime).valueOf() - moment(b.startTime).valueOf()
      );

      for (const dateObjectString of rangeTimesWithoutVideo.current) {
        const dateStart = moment(dateObjectString.startTime);
        const dateEnd = moment(dateObjectString.endTime);
        if (moment(time).isBetween(dateStart, dateEnd)) {
          return true;
        }
      }
    }
  }

  const playEvent = () => {
    setPlaying(true);
    setVideoEnded(false);
  };
  const pauseEvent = () => {
    setPlaying(false);
    setLoadingTheInfo(false);
  };
  const endedEvent = () => {
    setVideoEnded(true);
    setLoadingTheInfo(false);
  };
  const waitingEvent = () => {
    setLoadingTheInfo(true);
  };
  const playingEvent = () => {
    setLoadingTheInfo(false);
  };
  const loadedmetadataEvent = () => {
    setLoadedTheVideo(true);
    if (isDynamicPlaylist) {
      if (!showDialogToShareUnderDynamicPlaylist && !showDialogToDownloadUnderDynamicPlaylist) {
        videoRef.current?.play();
      }
      if (showDialogToDownloadUnderDynamicPlaylist) {
        startDownloadTask();
        setLoadingWebRtcInfo(false);
        setShowDialogToDownloadUnderDynamicPlaylist(false);
      }
      isProcessingNewSegments.current = false;
    } else {
      jumpTime(0); //TODO

      // In this part, the time is set to zero so that when the movement is made and the drag is released
      // to advance or delay the playback time, the video starts at zero. However, if the drag is not touched
      //  and I have seekToThisTime, the playback does not start at that time because it is set to zero.
      //  Please review this issue carefully.
    }
    if (videoRef.current) {
      const aspectR = videoRef.current.videoWidth / videoRef.current.videoHeight;
      setAspectRatio(aspectR);
    }
  };
  const documentKeyDownEvent = (e: any) => {
    if (e.code === "Space") {
      handleClickPlayPauseButton();
    } else if ((e.code === "ArrowRight" || e.code === "Numpad6") && videoRef.current) {
      jumpTime(videoRef.current.currentTime + 10);
    } else if ((e.code === "ArrowLeft" || e.code === "Numpad4") && videoRef.current) {
      jumpTime(videoRef.current.currentTime - 10);
    }
  };
  const progressEvent = () => {
    const videoElement = videoRef.current;
    // const bufferElement = document.getElementById("progressBar_buffer");
    if (!videoElement?.buffered) return; //|| !bufferElement
    if (!videoElement.buffered.length) return;

    const temp = cloneDeep(progressBarBufferList);
    for (let i = 0; i < videoElement.buffered.length; i++) {
      const start = videoElement.buffered.start(i);
      const end = videoElement.buffered.end(i);

      temp.push({ start, end });
    }
    setProgressBarBufferList(temp);
    // const bufferedEnd = videoElement.buffered.end(videoElement.buffered.length - 1);
    // const duration = videoElement.duration;
    // if (bufferElement && duration > 0) {
    //   bufferElement.style.width = (bufferedEnd / duration) * 100 + "%";
    // }
  };
  useEffect(() => {
    let hls: Hls | undefined;

    if (
      (finishedToReadPathName &&
        refTimePathBlob.current.length &&
        credentialCloudStorange &&
        loadedTheList &&
        !isDynamicPlaylist) ||
      isPublic
    ) {
      if (refHLS.current) {
        hls = refHLS.current;
      } else {
        hls = new Hls({
          maxBufferLength: 40,
          backBufferLength: 20,
          maxBufferSize: 100, //MB
          nudgeMaxRetry: 10,
          maxMaxBufferLength: 3600,
        });
        refHLS.current = hls;
      }
      if (videoRef.current) {
        creatingHLSPlaylist(
          hls,
          refTimePathBlob.current.map((value) => value)
        );
        videoRef.current?.addEventListener("timeupdate", (event: any) => timeUpdateEvent());
        videoRef.current?.addEventListener("play", () => playEvent());
        videoRef.current?.addEventListener("pause", () => pauseEvent());
        videoRef.current?.addEventListener("ended", () => endedEvent());
        videoRef.current?.addEventListener("waiting", () => waitingEvent());
        videoRef.current?.addEventListener("playing", () => playingEvent());
        videoRef.current?.addEventListener("progress", () => progressEvent());
        document.addEventListener("keydown", documentKeyDownEvent);
        videoRef.current?.addEventListener("canplay", handleCanPlay);
      }
    }

    // Clean up  when the component unmounts.
    return () => {
      if (hls && Hls.isSupported()) {
        hls.destroy();
        refHLS.current = undefined;
      }
      videoRef.current?.removeEventListener("timeupdate", timeUpdateEvent);
      videoRef.current?.removeEventListener("play", playEvent);
      videoRef.current?.removeEventListener("pause", pauseEvent);
      videoRef.current?.removeEventListener("ended", endedEvent);
      videoRef.current?.removeEventListener("loadedmetadata", loadedmetadataEvent);
      videoRef.current?.removeEventListener("waiting", waitingEvent);
      videoRef.current?.removeEventListener("playing", playingEvent);
      videoRef.current?.removeEventListener("progress", progressEvent);
      document.removeEventListener("keydown", documentKeyDownEvent);
      videoRef.current?.removeEventListener("canplay", handleCanPlay);
    };
  }, [
    finishedToReadPathName,
    refTimePathBlob.current.length,
    credentialCloudStorange,
    isPublic,
    videoRef.current,
    loadedTheList,
    isDynamicPlaylist,
  ]);

  const [playing, setPlaying] = useState(true);
  const [videoEnded, setVideoEnded] = useState(false); //seconds
  const handleClickPlayPauseButton = () => {
    if (videoRef.current?.paused || videoRef.current?.ended) {
      if (seekToThisTime && videoRef.current?.ended) {
        jumpTime(moment(seekToThisTime).diff(moment(startDate), "seconds"));
        videoRef.current?.play();
      } else {
        videoRef.current?.play();
      }
    } else {
      videoRef.current?.pause();
    }
  };

  const [playbackRate, setPlaybackRate] = useState(1);
  const [openSelectPlaybackRate, setOpenSelectPlaybackRate] = useState<null | HTMLElement>(null);
  const [openSettings, setOpenSetting] = useState<null | HTMLElement>(null);
  const [selectedEventsToSee, setSelectedEventsToSee] = useState<ObjectDetectionType[]>([
    ObjectDetectionType.Person,
    ObjectDetectionType.Vehicle,
    ObjectDetectionType.Animals,
  ]);
  const optionsObjectDetections = [
    {
      id: ObjectDetectionType.Person,
      name: "Person",
    },
    { id: ObjectDetectionType.Vehicle, name: "Vehicle" },
    { id: ObjectDetectionType.Animals, name: "Animals" },
  ];

  function changePlaybackSpeed(speed: number) {
    if (videoRef.current) {
      videoRef.current.playbackRate = speed;
      setPlaybackRate(speed);
      setOpenSelectPlaybackRate(null);
    }
  }
  const [openFullScreen, setOpenFullScreen] = useState(false);

  function openFullscreen() {
    const element: any = document.getElementById("root_fullscreen"); //generalGridRef.current;
    setHeightProgressBar(showEvents ? progressBarFullscreenHeight : progressBarMiniHeight);
    setOpenFullScreen(true);
    if (element.requestFullscreen) {
      // W3C API
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      // Mozilla current API
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullScreen) {
      // Webkit current API
      element.webkitRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }
  useEffect(() => {
    if (document.addEventListener) {
      document.addEventListener("fullscreenchange", exitHandler, false);
      document.addEventListener("mozfullscreenchange", exitHandler, false);
      document.addEventListener("MSFullscreenChange", exitHandler, false);
      document.addEventListener("webkitfullscreenchange", exitHandler, false);
    }
    function exitHandler() {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        setOpenFullScreen(false);
        setHeightProgressBar(showEvents ? progressBarNormalHeight : progressBarMiniHeight);
      }
    }
    return () => {
      document.removeEventListener("fullscreenchange", exitHandler, false);
      document.removeEventListener("mozfullscreenchange", exitHandler, false);
      document.removeEventListener("MSFullscreenChange", exitHandler, false);
      document.removeEventListener("webkitfullscreenchange", exitHandler, false);
    };
  }, []);

  function closeFullscreen() {
    if (openFullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).webkitExitFullscreen) {
        /* Safari */
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        /* IE11 */
        (document as any).msExitFullscreen();
      }
      setOpenFullScreen(false);
      setHeightProgressBar(showEvents ? progressBarNormalHeight : progressBarMiniHeight);
    }
  }
  function taking() {
    if (videoRef.current) {
      videoRef.current.style.filter = "brightness(2)";
      setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.style.filter = "";
          takeSnapshoot();
        }
      }, 50);
    }
  }

  function takeSnapshoot() {
    if (videoRef.current) {
      const video = videoRef.current; //document.getElementById("videojs");
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(video as any, 0, 0, canvas.width, canvas.height);
      const dataURI = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.setAttribute("href", dataURI);
      link.setAttribute(
        "download",
        "alocity_snapshot_" + deviceName + "_" + new Date().toISOString() + ".png"
      );
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  // ----------------------------------------
  const offsetTime = timeZoneOffset?.length ? timeZoneOffset : "";
  const currentDate = startDate
    ? moment(startDate)
        .utcOffset(offsetTime)
        .local()
        .add(currentTime, "seconds")
        .format("L hh:mm:ss a")
    : "";
  const percentage = duration ? (currentTime / duration) * 100 : 0;
  const jumpTime = (timeInSeconds: number) => {
    if (isDynamicPlaylist) {
      if (isProcessedAllDynamic.current) {
        (videoRef.current as any).currentTime = timeInSeconds;
      } else {
        refTheTimeInSecondsJumped.current = timeInSeconds;
        const time = moment(startDate).add(timeInSeconds, "seconds").toISOString();
        requestDynamicUpload(time);
      }
    } else {
      (videoRef.current as any).currentTime = timeInSeconds;
    }
  };
  const theme = useTheme();

  const matchesMobile1 = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile2Screen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));

  const isLargeScreen = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const matchesMobile = matchesMobile1; //|| isMobile2Screen;

  let maxWidth = "md" as "xs" | "sm" | "md" | "lg" | "xl";
  if (matchesMobile) {
    maxWidth = "xs"; // For small screens
  } else if (isMediumScreen) {
    maxWidth = "sm";
  } else if (isLargeScreen) {
    maxWidth = "md"; // For large screens
  } else if (isExtraLargeScreen) {
    maxWidth = "lg"; // For XL screens
  }
  // ------------------
  useEffect(() => {
    if (seekToThisTime && loadedTheVideo && startDate.length) {
      jumpTime(moment(seekToThisTime).diff(moment(startDate), "seconds"));
      videoRef.current?.play();
    } else if (!seekToThisTime && loadedTheVideo && startDate.length) {
      videoRef.current?.play();
    }
  }, [seekToThisTime, loadedTheVideo, startDate]);

  const [hoverProgressBar, setHoverProgressBar] = useState<any | null>(null);
  const [isLoadedFirstImages, setIsLoadedFirstImages] = useState(false);
  useEffect(() => {
    //having fast some images
    const load = async () => {
      const count = 10;
      const equidistantDates: ThumbnailTimeObject[] = [];
      const timeRange = (moment(endDate).valueOf() - moment(startDate).valueOf()) / 1000;
      const interval = timeRange / count;

      for (let i = 0; i < count; i += 1) {
        const temp = moment(startDate).valueOf() / 1000 + i * interval;
        const newDate = moment(temp * 1000);
        // Round the seconds portion to the nearest 20 seconds
        const seconds = newDate.seconds();
        const roundedSeconds = seconds + (20 - (seconds % 20));
        newDate.seconds(roundedSeconds);

        if (newDate.milliseconds(0).isBefore(moment())) {
          const image: string | undefined = await getTheBase64Image(
            newDate.milliseconds(0).toISOString(),
            credentialCloudStorange as any
          );
          equidistantDates.push({
            time: newDate,
            image: image,
          });
        }
      }
      setTheThumbnailTime(equidistantDates);
      setIsLoadedFirstImages(true);
    };

    if (
      credentialCloudStorange &&
      loadedTheVideo &&
      !hasFatalErrorMessage &&
      loadedTheList &&
      !isPublic &&
      !isDynamicPlaylist &&
      !initPlayListReaders
    ) {
      load();
    }
  }, [
    credentialCloudStorange,
    loadedTheVideo,
    hasFatalErrorMessage,
    loadedTheList,
    isPublic,
    isDynamicPlaylist,
    initPlayListReaders,
  ]);

  useEffect(() => {
    const load = async () => {
      const count = 100;
      const equidistantDates: ThumbnailTimeObject[] = [];
      const timeRange = (moment(endDate).valueOf() - moment(startDate).valueOf()) / 1000;
      const interval = timeRange / count;

      for (let i = 0; i < count; i++) {
        const temp = moment(startDate).valueOf() / 1000 + i * interval;
        const newDate = moment(temp * 1000);
        // Round the seconds portion to the nearest 20 seconds
        const seconds = newDate.seconds();
        const roundedSeconds = seconds + (20 - (seconds % 20));
        newDate.seconds(roundedSeconds);

        //dont call if the time previously called or is greater than PC time
        if (
          !(
            theThumbnailTime.find((value) => value.time.isSame(newDate.milliseconds(0))) ||
            newDate.milliseconds(0).isAfter(moment())
          )
        ) {
          const image: string | undefined = await getTheBase64Image(
            newDate.milliseconds(0).toISOString(),
            credentialCloudStorange as any
          );
          equidistantDates.push({
            time: newDate,
            image: image,
          });
        }
      }
      if (equidistantDates.length && equidistantDates[0].image) {
        const img = new Image();
        img.src = equidistantDates[0].image;

        img.onload = () => {
          const width = img.width;
          const height = img.height;
          const newAspectRatio = width / height;

          setAspectRatio(newAspectRatio);
        };
      }
      setTheThumbnailTime(equidistantDates);
    };
    if (isLoadedFirstImages) {
      load();
    }
  }, [isLoadedFirstImages]);

  function findNearestDate(selectedDate: Moment, equidistantDates: Moment[]) {
    const selectedTimestamp = selectedDate.valueOf();
    let nearestDate = equidistantDates[0];
    let nearestDifference = Math.abs(selectedTimestamp - nearestDate.valueOf());
    for (const date of equidistantDates) {
      const difference = Math.abs(selectedTimestamp - date.valueOf());
      if (difference < nearestDifference) {
        nearestDifference = difference;
        nearestDate = date;
      }
    }

    return nearestDate;
  }

  const handleHover = async (event: any) => {
    const progressBar: any = document.getElementById("progressBar");
    if (progressBar) {
      const hoverTime = (event.nativeEvent.offsetX / event.currentTarget.offsetWidth) * duration;
      const { left, width } = progressBar.getBoundingClientRect();
      const hoverPositionX = event.clientX - left;
      const hoverPosition = (hoverPositionX / width) * 100;

      //check if the time is near to the thumbnail time
      if (theThumbnailTime.length && credentialCloudStorange) {
        const time = findNearestDate(
          moment(startDate).add(hoverTime, "seconds"),
          theThumbnailTime.map((value) => value.time)
        );
        let image: string | undefined = "";
        if (theThumbnailTime.find((value) => value.time.isSame(time))) {
          image = theThumbnailTime.find((value) => value.time.isSame(time))?.image;
        }

        setHoverProgressBar({
          time: hoverTime,
          position: hoverPosition,
          image: image,
        });
      } else {
        if (hoverPosition) {
          setHoverProgressBar({
            time: hoverTime,
            position: hoverPosition,
            image: "",
          });
        }
      }
    }
  };
  const handleTouchMove = async (event: any) => {
    const progressBar: any = document.getElementById("progressBar");
    if (progressBar) {
      const touchX = event.touches[0].clientX;

      const { left, width } = progressBar.getBoundingClientRect();
      const relativeX = touchX - left;
      // Calculate the position as a percentage of the timeline width
      const timelineWidth = width;
      const hoverTime = (relativeX / timelineWidth) * duration;
      const hoverPosition = (relativeX / width) * 100;
      //check if the time is near to the thumbnail time
      if (theThumbnailTime.length && credentialCloudStorange) {
        const time = findNearestDate(
          moment(startDate).add(hoverTime, "seconds"),
          theThumbnailTime.map((value) => value.time)
        );
        let image: string | undefined = "";
        if (theThumbnailTime.find((value) => value.time.isSame(time))) {
          image = theThumbnailTime.find((value) => value.time.isSame(time))?.image;
        }

        setHoverProgressBar({
          time: hoverTime,
          position: hoverPosition,
          image: image,
        });
      } else {
        if (hoverPosition) {
          setHoverProgressBar({
            time: hoverTime,
            position: hoverPosition,
            image: "",
          });
        }
      }
      jumpTime(hoverTime);
    }
  };
  const handleMouseLeave = () => {
    setHoverProgressBar(null);
  };
  const [heightProgressBar, setHeightProgressBar] = useState(
    showEvents && !isPublic ? progressBarNormalHeight : progressBarMiniHeight
  );

  const [aspectRatio, setAspectRatio] = useState<number>(1);

  let customWidth = customWidthProp || 640;

  if (!customWidthProp && !isPublic) {
    customWidth = useSelector((state: StoreStateModel) => state.layout.innerWidth, shallowEqual);
  }
  let customHeight1 = customHeightProp || 480;
  if (!customHeightProp && !isPublic) {
    customHeight1 = useSelector((state: StoreStateModel) => state.layout.innerHeight, shallowEqual);
  }

  const customHeight = customHeight1 - progressBarNormalHeight - extraBottomSpace;

  const variablesToCss: React.CSSProperties = {
    "--aspect-ratio": aspectRatio,
    "--available-width": openFullScreen ? "100%" : (customWidth as number),
    "--available-height": customHeight,
    "--hoverBackgroundColor": theme.palette.background.paper,
    "--primaryColor": theme.palette.primary.main,
    "--primaryColorLight": theme.palette.primary.light,
    "--secondaryColor": theme.palette.secondary.main,
    "--progressBarPercent": percentage + "%",
    "--hoverProgressBarPosition":
      hoverProgressBar &&
      (hoverProgressBar.position > 95 //avoid the text go out of the screen
        ? 95
        : hoverProgressBar.position < 5
        ? 5
        : hoverProgressBar.position) + "%",
    "--heightProgressBar":
      (isClickedTimespanSelectionButton ? progressBarNormalHeight : heightProgressBar) + "px",
    "--heightEvent": heightProgressBar / 10 + "px",
    "--progressBufferColor": theme.palette.grey[200],
  } as React.CSSProperties;

  const [theThumbnailTime, setTheThumbnailTime] = useState<ThumbnailTimeObject[]>([]);

  const initialQueryStateCamera: QueryFilter = {
    pageNumber: 1,
    pageSize: 100,
    // descending: true,
    // eventType: ObjectDetectionType.Person,
    fromDate: startDate,
    toDate: endDate,
    serialNumber: serialNumber,
  };

  const initialQueryStateVideoSource: QueryFilter = {
    pageNumber: 1,
    pageSize: 100,
    // descending: true,
    // eventType: ObjectDetectionType.Person,
    fromDate: startDate,
    toDate: endDate,
  };
  const [theEvents, setTheEvents] = useState<DeviceEventLogModel[]>([]);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const load = async (queryFilter: QueryFilter) => {
      try {
        let response = null;
        setLoadingEvents(true);
        if (isVideoSource) {
          const videoSourceId = serialNumber;
          response = await getEventArtifactsVideoSource(videoSourceId, queryFilter, signal);
        } else {
          response = await getCompleteEvents(queryFilter, signal);
        }

        if (!response.data.hasErrors) {
          const temp = response.data.payload;
          setTheEvents((prev) => prev.concat(temp));
          if (response.data.nextPage) {
            const page = (response.data.pageNumber as number) + 1;
            load({
              ...queryFilter,
              pageNumber: page,
            });
          }
        }
      } catch (error) {
        //
      } finally {
        setLoadingEvents(false);
      }
    };
    if (showEvents && loadedTheVideo && !isPublic) {
      if (isVideoSource) {
        load(initialQueryStateVideoSource);
      } else {
        load(initialQueryStateCamera);
      }
    }
    return () => {
      controller.abort();
    };
  }, [showEvents, loadedTheVideo, isPublic]);
  /////////////////////////////////////////
  function filterHLSPlaylistToDownload(playlist: string, start: string, end: string) {
    const searchStartD = moment(start).valueOf();
    const searchEndD = moment(end).valueOf();

    const lines = playlist.split("\n");
    let filteredPlaylist = "";

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];

      if (line.startsWith("#EXTINF:")) {
        const actualSegmentTime = lines[i + 1].split("#EXT-X-PROGRAM-DATE-TIME:")[1].trim(); // 2024-01-05T15:09:32.000Z
        const segDuration: number = parseInt(line.split("#EXTINF:")[1].split(".")[0]); //duration 20

        const segmentDateTime = moment(actualSegmentTime).valueOf();

        if (
          !(
            (segmentDateTime > searchStartD ||
              segmentDateTime + segDuration * 1000 > searchStartD) &&
            segmentDateTime < searchEndD
          )
        ) {
          i = i + (extensionSegmentVideoM4s.current ? 2 : 3);
          continue;
        }
      }

      filteredPlaylist += line + "\n";
    }

    return filteredPlaylist;
  }
  function filterSignedUrlsToDownload(signedUrls: string[], start: string, end: string) {
    const searchStartD = moment(start).valueOf();
    const searchEndD = moment(end).valueOf();

    const filteredSignedUrls = [];
    if (extensionSegmentVideoM4s.current) {
      filteredSignedUrls.push(signedUrls[0]);
    }

    for (let i = extensionSegmentVideoM4s.current ? 1 : 0; i < signedUrls.length; i++) {
      const url = signedUrls[i];

      const temp = url.split("/");
      const nameWithQueries = temp[temp.length - 1];
      const name = nameWithQueries.split("?")[0]; //
      const decodedName = decodeURIComponent(name); //2024-01-11T21:16:38.000Z_20.m4s  | black_screen_20s_1?time=2024-01-11T22:00:00.000Z
      let time = "";
      let duration = 0;
      if (decodedName.startsWith("black_screen")) {
        const tempBlack = nameWithQueries.split("?")[1];
        const decodedBlack = decodeURIComponent(tempBlack); //time=2024-01-11T22:00:00.000Z"
        time = decodedBlack.split("=")[1];
        const durationBlack = decodedName.split("_")[1];
        duration = parseInt(durationBlack.split("s")[0]);
      } else {
        time = decodedName.split("_")[0];
        duration = parseInt(decodedName.split("_")[1].split(".")[0]);
      }
      const actualSegmentTime = time;
      const segDuration: number = duration;

      const segmentDateTime = moment(actualSegmentTime).valueOf();

      if (
        !(
          (segmentDateTime > searchStartD || segmentDateTime + segDuration * 1000 > searchStartD) &&
          segmentDateTime < searchEndD
        )
      ) {
        continue;
      }
      filteredSignedUrls.push(url);
    }

    return filteredSignedUrls;
  }
  ////////////////////////////////////////
  const startDownloadTask = () => {
    if (!pendingDeviceInfoDownloadFromCloud) {
      toast.success(t("Started"));
      //////////////////////////
      const tempStartDateTime = moment(startDate)
        .add(
          getSecondsFromPixelPositionInProgressBar(dragPixelValueTimespanSelectionStart.x),
          "seconds"
        )
        .milliseconds(0)
        .toISOString();
      const tempEndDateTime = moment(startDate)
        .add(
          getSecondsFromPixelPositionInProgressBar(
            document.getElementById("progressBar")?.getBoundingClientRect().width +
              dragPixelValueTimespanSelectionEnd.x
          ),
          "seconds"
        )
        .milliseconds(0)
        .toISOString();

      /////////////////////////

      const newPlaylist = filterHLSPlaylistToDownload(
        refPlaylistToDownload.current,
        tempStartDateTime,
        tempEndDateTime
      );
      const newSignedUrls = filterSignedUrlsToDownload(
        refSignedUrls.current,
        tempStartDateTime,
        tempEndDateTime
      );
      dispatch(
        actions.downloadVideoFromCloud({
          localPlaylist: newPlaylist,
          serialNumber: serialNumber,
          signedSegments: newSignedUrls,
          typeExtension: extensionSegmentVideoM4s.current ? "m4s" : "ts",
          isReader: initPlayListReaders,
        })
      );
      // videoRef.current?.pause();
    } else {
      toast.info(t("ThereIsADownloadInProccess"));
    }
  };

  const getSecondsFromPixelPositionInProgressBar = (touchX: number) => {
    const progressBar: any = document.getElementById("progressBar");
    if (progressBar) {
      const { left, width } = progressBar.getBoundingClientRect();
      const relativeX = touchX - left;
      const timelineWidth = width;
      const hoverTime = (relativeX / timelineWidth) * duration;
      return hoverTime;
    }
    return 0;
  };
  const generateTickContainerHTML = useCallback(
    (dragPixelValueBar: number) => {
      if (document.getElementById("progressBar")) {
        const widthProgressBar: number =
          document.getElementById("progressBar")?.getBoundingClientRect().width * 2;
        const showLongEachSecond = 46800 / clipsTime; //every 4 min approximately
        const separationTick = 0.6;
        const ticks = [];
        const howManyVerticalLines =
          (widthProgressBar + Math.abs(dragPixelValueBar)) / separationTick;
        for (let i = widthProgressBar * -1; i <= howManyVerticalLines; i++) {
          const tickPosition = i * separationTick;
          ticks.push(
            <div
              key={i + "verticalLines"}
              className={styles.tick_short}
              style={{ left: tickPosition + "%" }}
            ></div>
          );
        }
        for (let i = widthProgressBar * -1; i < howManyVerticalLines; i += showLongEachSecond) {
          const tickPosition = i * separationTick;
          ticks.push(
            <div
              key={i + "verticalLinesLong"}
              className={styles.tick_long}
              style={{ left: tickPosition + "%" }}
            ></div>
          );
        }

        // const html_code = <div className={styles.tickContainer}>{ticks}</div>;
        return ticks;
      }
      return null;
    },
    [dragPixelValueBar]
  );
  function changeStartDateAndEndDate(startD: string, endD: string) {
    if (Hls.isSupported() && refHLS.current) {
      refHLS.current.stopLoad();
      refHLS.current.detachMedia();
      refHLS.current.destroy();
    }
    if (videoRef.current) {
      videoRef.current.src = "";
    }
    setStartDate(startD);
    setEndDate(endD);
    //initializing to default
    setFinishedToReadPathName(false);
    refTimePathBlob.current = [];
    setLoadedTheVideo(false);
    setIsLoadedFirstImages(false);
    setLoadedTheList(false);
    refSignedUrls.current = [];
    refPlaylistToDownload.current = "";
    setProgressBarBufferList([]);
  }
  const [changeDateWithDelay, setChangeDateWithDelay] = useState<undefined | any>(undefined);
  useEffect(() => {
    if (changeDateWithDelay) {
      changeStartDateAndEndDate(changeDateWithDelay.startD, changeDateWithDelay.endD);
      setIsActivatedBarDrag(false);
      setChangeDateWithDelay(undefined);
    }
  }, [changeDateWithDelay]);

  const nextVideoPlayback = () => {
    const startD = moment(startDate).clone().add(clipsTime, "seconds").toISOString();
    const endD = moment(endDate).clone().add(clipsTime, "seconds");
    const now = moment();
    if (endD.isAfter(now)) {
      toast.error(t("InsufficientDataAvailable"));
    } else {
      changeStartDateAndEndDate(startD, endD.toISOString());
    }
  };

  useEffect(() => {
    if (videoEnded && isPlaybackHistory) {
      nextVideoPlayback();
    }
  }, [videoEnded]);

  return (
    <Dialog open={open} fullScreen={true} maxWidth={false} fullWidth={true}>
      <Grid container className={styles.root} style={variablesToCss} id={"root_fullscreen"}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={styles.titleContainer}
        >
          <Grid item>
            <Typography className={styles.titleName}>{deviceName}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              className={styles.theIcons}
              onClick={async () => {
                await videoRef.current?.pause();
                setOpen(false);
                onClose && onClose();
              }}
            >
              <CloseOutlined />
            </IconButton>
          </Grid>
        </Grid>

        {!fatalErrorMessage.length ? (
          <div className={styles.contents}>
            <div
              className={styles.videoArea}
              onClick={() => {
                if (loadedTheVideo && !loadingWebRtcInfo && !isActivedBarDrag) {
                  handleClickPlayPauseButton();
                }
              }}
            >
              <div className={styles.videoColumnRow}>
                <div className={styles.videoColumnWrapper} style={{ width: customWidth }}>
                  <div className={styles.videoColumn} style={{ width: customWidth }}>
                    <div className={styles.gridArea}>
                      <div className={styles.DraggableGrid}>
                        <div className={styles.background} style={{ height: customHeight }}>
                          <span>
                            <div
                              className={styles.cell}
                              style={{
                                width: customWidth,
                                height: customHeight,
                              }}
                            >
                              <div className={styles.cameraCell}>
                                <div className={styles.videoPlayer}>
                                  <div className={styles.zoomArea}>
                                    <div className={styles.panZoomArea}>
                                      <div
                                        className={styles.content}
                                        style={{
                                          width: customWidth + "px",
                                          height: customHeight + "px",
                                          transformOrigin: `${customWidth / 2}px ${
                                            customHeight / 2
                                          }px`,
                                          transform: "translate(0px, 0px) scale(1)",
                                        }}
                                      >
                                        <canvas id={"canvas"} className={styles.previousFrame} />
                                        {loadingTheInfo ? (
                                          <Grid container className={styles.loading}>
                                            <Grid item xs={12}>
                                              <AlocityLoading />
                                            </Grid>
                                          </Grid>
                                        ) : null}
                                        {loadingCredentials ? (
                                          <Grid container className={styles.loading}>
                                            <Grid item xs={12}>
                                              <AlocityLoading />
                                            </Grid>
                                          </Grid>
                                        ) : null}
                                        {loadingWebRtcInfo ||
                                        (isDynamicPlaylist && !finishedToReadPathNameInDynamic) ? (
                                          <Grid container className={styles.loading}>
                                            <Grid item xs={12}>
                                              <AlocityLoading />
                                            </Grid>
                                          </Grid>
                                        ) : null}
                                        {refShowNoVideoDesign.current ? (
                                          <Grid container className={styles.noVideoIcon}>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ display: "flex", justifyContent: "center" }}
                                            >
                                              <CancelPresentationIcon
                                                color="disabled"
                                                style={{ fontSize: "62px", color: "white" }}
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              style={{ display: "flex", justifyContent: "center" }}
                                            >
                                              <Typography style={{ color: "white" }}>
                                                {t("ThereNotVideoSource")}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        ) : null}
                                        <div className={styles.videoContainer}>
                                          <video
                                            playsInline
                                            // crossOrigin="use-credentials"
                                            ref={videoRef as any}
                                            controls={false}
                                            onError={(event) => {
                                              // setErrorMessage("Error_MEDIA_ERR_SRC_NOT_SUPPORTED");
                                            }}
                                            className={styles.video}
                                            muted={videoRef.current?.muted}
                                            style={
                                              refShowNoVideoDesign.current
                                                ? { opacity: 0 }
                                                : undefined
                                            }
                                          >
                                            To view this video p lease enable JavaScript, and
                                            consider upgrading to a web browser that supports HTML5
                                            video
                                          </video>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {!playing && (
                                    <PauseIcon
                                      // fontSize="large"
                                      className={styles.iconWithAnimation}
                                      style={{
                                        fontSize: matchesMobile ? "32px" : "80px",
                                      }}
                                    />
                                  )}
                                  {playing && (
                                    <PlayArrowIcon
                                      className={styles.iconWithAnimation}
                                      style={{
                                        fontSize: matchesMobile ? "32px" : "80px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.timelineContainer}>
                {!isPublic && !initPlayListReaders && !isDynamicPlaylist ? (
                  <div className={styles.timeline}>
                    <div className={styles.timeline_buttons_container}>
                      <div
                        className={styles.timeline_button_container}
                        id={"timeline_buttonText_left"}
                      >
                        {isPlaylistFromLocal ? null : (
                          <IconButton
                            disabled={!loadedTheVideo}
                            aria-label="previous clip"
                            size="small"
                            color="primary"
                            className={styles.timeline_button_container_button}
                            onClick={(event) => {
                              event.stopPropagation();
                              const startD = moment(startDate)
                                .clone()
                                .subtract(clipsTime, "seconds")
                                .toISOString();
                              const endD = moment(endDate)
                                .clone()
                                .subtract(clipsTime, "seconds")
                                .toISOString();
                              changeStartDateAndEndDate(startD, endD);
                            }}
                          >
                            <ArrowBackIcon />
                          </IconButton>
                        )}
                        <div className={styles.timeline_button_container_textTime}>
                          <Typography variant="body2" color="textSecondary">
                            {isActivedBarDrag
                              ? moment(startDate)
                                  .utcOffset(timeZoneOffset)
                                  .local()
                                  .add(
                                    getSecondsFromPixelPositionInProgressBar(
                                      dragPixelValueBar * -1
                                    ),
                                    "seconds"
                                  )
                                  .format("hh:mm:ss a")
                              : moment(startDate)
                                  .utcOffset(timeZoneOffset)
                                  .local()
                                  .format("hh:mm:ss a")}
                          </Typography>
                        </div>
                      </div>
                      <div
                        className={styles.timeline_Drag_container}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {!isClickedTimespanSelectionButton ? (
                          <Draggable
                            axis="x"
                            nodeRef={nodeRefBarDrag}
                            onDrag={(e: any, position) => {
                              e.stopPropagation();
                              if (!videoRef.current?.paused) {
                                videoRef.current?.pause();
                              }
                              if (!isActivedBarDrag) {
                                setIsActivatedBarDrag(true);
                              }
                              setDragPixelValueBar(position.x);
                            }}
                            onStop={(e) => {
                              const startD = moment(startDate)
                                .add(
                                  getSecondsFromPixelPositionInProgressBar(dragPixelValueBar * -1),
                                  "seconds"
                                )
                                .toISOString();
                              const endDMoment = moment(endDate)
                                .add(
                                  getSecondsFromPixelPositionInProgressBar(dragPixelValueBar * -1),
                                  "seconds"
                                )
                                .clone();
                              const endD = endDMoment.toISOString();
                              //this delay must be here
                              setTimeout(() => {
                                setChangeDateWithDelay({ startD, endD });
                              }, 100);
                            }}
                            scale={1}
                          >
                            <div
                              ref={nodeRefBarDrag}
                              id={"barDrag"}
                              className={
                                isActivedBarDrag ? styles.Timeline_dragging : styles.Timeline
                              }
                            >
                              {generateTickContainerHTML(dragPixelValueBar)}
                            </div>
                          </Draggable>
                        ) : null}
                      </div>

                      {/* ///////////////////////  */}
                      <div
                        className={styles.timeline_button_container}
                        id={"timeline_buttonText_right"}
                      >
                        <div className={styles.timeline_button_container_textTime}>
                          <Typography variant="body2" color="textSecondary">
                            {isActivedBarDrag
                              ? moment(startDate)
                                  .utcOffset(timeZoneOffset)
                                  .local()
                                  .add(
                                    duration +
                                      getSecondsFromPixelPositionInProgressBar(
                                        dragPixelValueBar * -1
                                      ),
                                    "seconds"
                                  )
                                  .format("hh:mm:ss a")
                              : moment(startDate)
                                  .utcOffset(timeZoneOffset)
                                  .local()
                                  .add(duration, "seconds")
                                  .format("hh:mm:ss a")}
                          </Typography>
                        </div>
                        {isPlaylistFromLocal ? null : (
                          <IconButton
                            disabled={!loadedTheVideo}
                            aria-label="next clip"
                            size="small"
                            color="primary"
                            className={styles.timeline_button_container_button}
                            onClick={(event) => {
                              event.stopPropagation();
                              nextVideoPlayback();
                            }}
                          >
                            <ArrowForwardIcon />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className={styles.controlArea}>
              <div
                id={"progressBar"}
                className={styles.progressBarContainer}
                onClick={(event) => {
                  if (
                    loadedTheVideo &&
                    !loadingWebRtcInfo &&
                    !isClickedTimespanSelectionButton &&
                    !isActivedBarDrag
                  ) {
                    const progressTime =
                      (event.nativeEvent.offsetX / event.currentTarget.offsetWidth) * duration;
                    jumpTime(progressTime);
                    if (videoEnded) {
                      setPlaying(false);
                      setVideoEnded(false);
                    }
                  }
                }}
                onMouseMove={(event) => {
                  if (
                    loadedTheVideo &&
                    !loadingWebRtcInfo &&
                    !isClickedTimespanSelectionButton &&
                    !isActivedBarDrag
                  ) {
                    handleHover(event);
                  }
                }}
                onMouseLeave={() => {
                  handleMouseLeave();
                }}
                onTouchStart={(event) => {
                  if (
                    loadedTheVideo &&
                    !loadingWebRtcInfo &&
                    !isClickedTimespanSelectionButton &&
                    !isActivedBarDrag
                  ) {
                    videoRef.current?.pause();
                  }
                }}
                onTouchMove={(event) => {
                  if (
                    loadedTheVideo &&
                    !loadingWebRtcInfo &&
                    !isClickedTimespanSelectionButton &&
                    !isActivedBarDrag
                  ) {
                    handleTouchMove(event);
                  }
                }}
                onTouchEnd={() => {
                  if (
                    loadedTheVideo &&
                    !loadingWebRtcInfo &&
                    !isClickedTimespanSelectionButton &&
                    !isActivedBarDrag
                  ) {
                    videoRef.current?.play();
                    handleMouseLeave();
                  }
                }}
              >
                {loadingEvents ? (
                  <Grid container className={styles.loadingEvents}>
                    <Grid item xs={12}>
                      <CircularProgress size={20} />
                    </Grid>
                  </Grid>
                ) : null}
                {isActivedBarDrag && (
                  <div
                    className={styles.progressBar_timestamp_draggable}
                    style={{
                      left: 0,
                      width: "100%",
                    }}
                  ></div>
                )}
                <div className={styles.progressBar_verticalLine}></div>
                <div className={styles.progressBar_filled}></div>
                {isClickedTimespanSelectionButton && !isActivedBarDrag ? (
                  <>
                    <Draggable
                      axis="x"
                      nodeRef={nodeRefTimespanSelectionStart}
                      bounds={{
                        bottom: 0,
                        top: 0,
                        left: 0,
                        right:
                          document.getElementById("progressBar")?.getBoundingClientRect().width +
                          dragPixelValueTimespanSelectionEnd.x -
                          40,
                      }}
                      onDrag={(e: any, position) => {
                        setDragPixelValueTimespanSelectionStart((prev) => {
                          return {
                            x: position.x,
                            y: position.y,
                          };
                        });
                      }}
                      scale={1}
                    >
                      <div
                        ref={nodeRefTimespanSelectionStart}
                        id={"timestampStartDiv"}
                        className={styles.progressBar_lineTimestamp_left}
                      >
                        <>
                          <div
                            className={styles.progressBar_lineTimestamp_container_hover_time}
                            style={{
                              fontSize: matchesMobile ? "8px" : "14px",
                              transform:
                                dragPixelValueTimespanSelectionStart.x > 80
                                  ? "translateX(-84%)"
                                  : "translateX(0%)",
                            }}
                          >
                            {isPlaybackHistory
                              ? moment(startDate)
                                  .utcOffset(timeZoneOffset)
                                  .local()
                                  .add(
                                    getSecondsFromPixelPositionInProgressBar(
                                      dragPixelValueTimespanSelectionStart.x
                                    ),
                                    "seconds"
                                  )
                                  .format("hh:mm:ss a")
                              : moment(startDate)
                                  .utcOffset(timeZoneOffset)
                                  .local()
                                  .add(
                                    getSecondsFromPixelPositionInProgressBar(
                                      dragPixelValueTimespanSelectionStart.x
                                    ),
                                    "seconds"
                                  )
                                  .format("hh:mm:ss a")}
                          </div>
                          <div className={styles.progressBar_lineTimestamp_container}>
                            <SwapHorizIcon fontSize="small" color="secondary" />
                          </div>
                        </>
                      </div>
                    </Draggable>
                    <div
                      className={styles.progressBar_timestamp_draggable}
                      style={{
                        left: dragPixelValueTimespanSelectionStart.x,
                        width:
                          document.getElementById("progressBar")?.getBoundingClientRect().width +
                          dragPixelValueTimespanSelectionEnd.x -
                          dragPixelValueTimespanSelectionStart.x,
                      }}
                    ></div>
                    <Draggable
                      bounds={{
                        bottom: 0,
                        top: 0,
                        right: 0,
                        left:
                          -document.getElementById("progressBar")?.getBoundingClientRect().width +
                          dragPixelValueTimespanSelectionStart.x +
                          40,
                      }}
                      axis="x"
                      nodeRef={nodeRefTimespanSelectionEnd}
                      onDrag={(e: any, position) => {
                        setDragPixelValueTimespanSelectionEnd((prev) => {
                          return {
                            x: position.x,
                            y: position.y,
                          };
                        });
                      }}
                      scale={1}
                    >
                      <div
                        ref={nodeRefTimespanSelectionEnd}
                        id={"timestampEndDiv"}
                        className={styles.progressBar_lineTimestamp_right}
                        style={{
                          transform: "translateX(-100%)",
                          // right: 0 + "px",
                        }}
                      >
                        <>
                          <div
                            className={styles.progressBar_lineTimestamp_container_hover_time}
                            style={{
                              fontSize: matchesMobile ? "8px" : "14px",
                              transform:
                                dragPixelValueTimespanSelectionEnd.x > -80
                                  ? "translateX(-80%)"
                                  : "translateX(0%)",
                            }}
                          >
                            {isPlaybackHistory
                              ? moment(startDate)
                                  .utcOffset(timeZoneOffset)
                                  .local()
                                  .add(
                                    getSecondsFromPixelPositionInProgressBar(
                                      document
                                        .getElementById("progressBar")
                                        ?.getBoundingClientRect().width +
                                        dragPixelValueTimespanSelectionEnd.x
                                    ),
                                    "seconds"
                                  )
                                  .format("hh:mm:ss a")
                              : moment(startDate)
                                  .utcOffset(timeZoneOffset)
                                  .local()
                                  .add(
                                    getSecondsFromPixelPositionInProgressBar(
                                      document
                                        .getElementById("progressBar")
                                        ?.getBoundingClientRect().width +
                                        dragPixelValueTimespanSelectionEnd.x
                                    ),
                                    "seconds"
                                  )
                                  .format("hh:mm:ss a")}
                          </div>
                          <div className={styles.progressBar_lineTimestamp_container}>
                            <SwapHorizIcon fontSize="small" color="secondary" />
                          </div>
                        </>
                      </div>
                    </Draggable>
                  </>
                ) : null}
                {progressBarBufferList.map((buffer, index) => {
                  const start = (buffer.start / duration) * 100;
                  const end = (buffer.end / duration) * 100;
                  return (
                    <div
                      key={index}
                      id={"progressBar_buffer" + index}
                      className={styles.progressBar_buffer}
                      style={{
                        left: start + "%",
                        width: end - start + "%",
                      }}
                    ></div>
                  );
                })}

                {theEvents.map((event: DeviceEventLogModel, index) => {
                  if (selectedEventsToSee.includes(event.detectionType)) {
                    let extraMoveByTimelineDrag = 0;
                    if (isActivedBarDrag) {
                      extraMoveByTimelineDrag =
                        getSecondsFromPixelPositionInProgressBar(dragPixelValueBar);
                    }
                    const artifactRegistrationTime = moment(event.artifactRegistrationTime + "Z");
                    const initialTime =
                      ((moment(startDate).valueOf() / 1000 - extraMoveByTimelineDrag) / duration) *
                      100;
                    const startSecond = artifactRegistrationTime.valueOf() / 1000;
                    let temp = 10; //parseInt(process.env.REACT_APP_VideoEvent_DurationInSecond || "20"); //TODO: use complete time from event
                    const diff = moment(endDate).diff(startDate, "seconds");
                    if (diff <= 350 && diff > 90) {
                      temp = 1.96;
                    } else if (diff <= 90) {
                      temp = 0.35;
                    }
                    const endSecond =
                      artifactRegistrationTime.add(temp, "seconds").valueOf() / 1000;

                    const start = (startSecond / duration) * 100;
                    const end = (endSecond / duration) * 100;
                    return (
                      <div
                        id={event.artifactRegistrationTime}
                        key={index}
                        className={styles.progressBar_event}
                        style={{
                          left: start - initialTime + "%",
                          width: end - start + "%",
                          top:
                            (generateMarginTopPerObjectDetection(event.detectionType) *
                              heightProgressBar) /
                              10 +
                            "px",
                          background: generateColorPerObjectDetection(event.detectionType),
                        }}
                      ></div>
                    );
                  }
                })}

                {rangeTimesWithoutVideo.current.map((time, index) => {
                  let extraMoveByTimelineDrag = 0;
                  if (isActivedBarDrag) {
                    extraMoveByTimelineDrag =
                      getSecondsFromPixelPositionInProgressBar(dragPixelValueBar);
                  }
                  const initialTime =
                    ((moment(startDate).valueOf() / 1000 - extraMoveByTimelineDrag) / duration) *
                    100;
                  const startTimeInSecond = moment(time.startTime).valueOf() / 1000;
                  const endTimeInSecond = moment(time.endTime).valueOf() / 1000;
                  const start = (startTimeInSecond / duration) * 100;
                  const end = (endTimeInSecond / duration) * 100;

                  return (
                    <div
                      key={index}
                      className={styles.barWithoutVideo}
                      style={{
                        left: start - initialTime + "%",
                        width: end - start + "%",
                      }}
                    ></div>
                  );
                })}

                {hoverProgressBar !== null && (
                  <>
                    {hoverProgressBar.image ? (
                      <div>
                        <img
                          src={hoverProgressBar.image}
                          className={styles.thumbnailImage}
                          style={
                            matchesMobile
                              ? {
                                  height: "50px",
                                  // width: "50px",
                                  top: "-85px",
                                }
                              : {
                                  height: "100px",
                                  // width: "100px",
                                  top: "-135px",
                                }
                          }
                        />
                      </div>
                    ) : null}
                    <div
                      className={styles.hover_time}
                      style={{ fontSize: matchesMobile ? "8px" : "14px" }}
                    >
                      {moment(startDate)
                        .utcOffset(timeZoneOffset)
                        .local()
                        .add(hoverProgressBar.time, "seconds")
                        .format("hh:mm:ss a")}
                    </div>
                  </>
                )}
              </div>

              <Grid id="controlBar" container className={styles.controlsContainer}>
                {!isClickedTimespanSelectionButton ? (
                  !isActivedBarDrag ? (
                    <>
                      <Grid item xs={4} sm={4} md={3} className={styles.control_icons}>
                        <Grid container justify="center">
                          <IconButton
                            size={matchesMobile ? "small" : "medium"}
                            className={styles.theIcons}
                            style={matchesMobile ? { marginLeft: "5px", marginRight: "5px" } : {}}
                            onClick={() => {
                              if (loadedTheVideo && !loadingWebRtcInfo) {
                                handleClickPlayPauseButton();
                              }
                            }}
                            disabled={!loadedTheVideo || loadingWebRtcInfo}
                          >
                            {playing ? (
                              <PauseIcon fontSize="inherit" />
                            ) : videoEnded ? (
                              <ReplayIcon fontSize="inherit" />
                            ) : (
                              <PlayArrowIcon fontSize="inherit" />
                            )}
                          </IconButton>

                          <IconButton
                            size={matchesMobile ? "small" : "medium"}
                            className={styles.theIcons}
                            style={matchesMobile ? { marginLeft: "5px", marginRight: "5px" } : {}}
                            onClick={() => {
                              jumpTime((videoRef.current as any).currentTime - 10);
                            }}
                            disabled={!loadedTheVideo || loadingWebRtcInfo}
                          >
                            <Replay10Icon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            size={matchesMobile ? "small" : "medium"}
                            className={styles.theIcons}
                            style={matchesMobile ? { marginLeft: "5px", marginRight: "5px" } : {}}
                            onClick={() => {
                              jumpTime((videoRef.current as any).currentTime + 10);
                            }}
                            disabled={!loadedTheVideo || loadingWebRtcInfo}
                          >
                            <Forward10Icon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            size={matchesMobile ? "small" : "medium"}
                            className={styles.theIcons}
                            style={matchesMobile ? { marginLeft: "5px", marginRight: "5px" } : {}}
                            onClick={(event) => {
                              setOpenSelectPlaybackRate(event.currentTarget);
                            }}
                            disabled={!loadedTheVideo || loadingWebRtcInfo}
                          >
                            <SlowMotionVideo fontSize="inherit" />
                          </IconButton>

                          <Menu
                            id="playback-rate"
                            anchorEl={openSelectPlaybackRate}
                            keepMounted
                            open={Boolean(openSelectPlaybackRate)}
                            onClose={() => setOpenSelectPlaybackRate(null)}
                          >
                            <MenuItem
                              disabled={!loadedTheVideo || loadingWebRtcInfo}
                              dense
                              selected={playbackRate === 0.25}
                              onClick={() => changePlaybackSpeed(0.25)}
                              style={
                                playbackRate === 0.25
                                  ? {
                                      backgroundColor: theme.palette.primary.light,
                                    }
                                  : {}
                              }
                            >
                              0.25x
                            </MenuItem>
                            <MenuItem
                              disabled={!loadedTheVideo || loadingWebRtcInfo}
                              dense
                              selected={playbackRate === 0.5}
                              onClick={() => changePlaybackSpeed(0.5)}
                              style={
                                playbackRate === 0.5
                                  ? {
                                      backgroundColor: theme.palette.primary.light,
                                    }
                                  : {}
                              }
                            >
                              0.5x
                            </MenuItem>
                            <MenuItem
                              disabled={!loadedTheVideo || loadingWebRtcInfo}
                              dense
                              selected={playbackRate === 0.75}
                              onClick={() => changePlaybackSpeed(0.75)}
                              style={
                                playbackRate === 0.75
                                  ? {
                                      backgroundColor: theme.palette.primary.light,
                                    }
                                  : {}
                              }
                            >
                              0.75x
                            </MenuItem>

                            <MenuItem
                              disabled={!loadedTheVideo || loadingWebRtcInfo}
                              dense
                              selected={playbackRate === 1}
                              onClick={() => changePlaybackSpeed(1)}
                              style={
                                playbackRate === 1
                                  ? {
                                      backgroundColor: theme.palette.primary.light,
                                    }
                                  : {}
                              }
                            >
                              1x
                            </MenuItem>

                            <MenuItem
                              disabled={!loadedTheVideo || loadingWebRtcInfo}
                              dense
                              selected={playbackRate === 1.25}
                              onClick={() => changePlaybackSpeed(1.25)}
                              style={
                                playbackRate === 1.25
                                  ? {
                                      backgroundColor: theme.palette.primary.light,
                                    }
                                  : {}
                              }
                            >
                              1.25x
                            </MenuItem>
                            <MenuItem
                              disabled={!loadedTheVideo || loadingWebRtcInfo}
                              dense
                              selected={playbackRate === 1.5}
                              onClick={() => changePlaybackSpeed(1.5)}
                              style={
                                playbackRate === 1.5
                                  ? {
                                      backgroundColor: theme.palette.primary.light,
                                    }
                                  : {}
                              }
                            >
                              1.5x
                            </MenuItem>
                            <MenuItem
                              disabled={!loadedTheVideo || loadingWebRtcInfo}
                              dense
                              selected={playbackRate === 1.75}
                              onClick={() => changePlaybackSpeed(1.75)}
                              style={
                                playbackRate === 1.75
                                  ? {
                                      backgroundColor: theme.palette.primary.light,
                                    }
                                  : {}
                              }
                            >
                              1.75x
                            </MenuItem>
                          </Menu>
                          <IconButton
                            size={matchesMobile ? "small" : "medium"}
                            className={styles.theIcons}
                            style={matchesMobile ? { marginLeft: "5px", marginRight: "5px" } : {}}
                            onClick={() => {
                              if (videoRef.current) {
                                videoRef.current.muted = !videoRef.current.muted;
                              }
                            }}
                            disabled={!loadedTheVideo || loadingWebRtcInfo}
                          >
                            {videoRef.current?.muted ? (
                              <VolumeOffIcon fontSize="inherit" />
                            ) : (
                              <VolumeUpIcon fontSize="inherit" />
                            )}
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={4}
                        md={4}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            display="inline"
                            variant={matchesMobile ? "caption" : "body2"}
                            style={{ color: "white" }}
                          >
                            {currentDate}
                          </Typography>
                          {!isPublic && !matchesMobile1 && !initPlayListReaders ? (
                            <IconButton
                              className={styles.currentDate_button}
                              size={"small"}
                              onClick={() => {
                                setIsClickedIconButtonExpandLess(true);
                                handleClickPlayPauseButton();
                              }}
                            >
                              <ExpandLessIcon fontSize="small" style={{ color: "white" }} />
                            </IconButton>
                          ) : null}
                        </div>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} className={styles.options_icons}>
                        <Grid container justify="center">
                          <Tooltip title={t("TakePhoto")}>
                            <span>
                              <IconButton
                                size={matchesMobile ? "small" : "medium"}
                                className={styles.theIcons}
                                style={
                                  matchesMobile ? { marginLeft: "5px", marginRight: "5px" } : {}
                                }
                                onClick={() => taking()}
                                disabled={!loadedTheVideo || loadingWebRtcInfo}
                              >
                                <CameraEnhanceIcon fontSize="inherit" />
                              </IconButton>
                            </span>
                          </Tooltip>

                          {showEvents && (
                            <>
                              <Tooltip title={t("Events")}>
                                <span>
                                  <IconButton
                                    size={matchesMobile ? "small" : "medium"}
                                    className={styles.theIcons}
                                    style={
                                      matchesMobile ? { marginLeft: "5px", marginRight: "5px" } : {}
                                    }
                                    onClick={(event) => setOpenSetting(event.currentTarget)}
                                    disabled={!loadedTheVideo || loadingWebRtcInfo}
                                  >
                                    <LayersIcon fontSize="inherit" />
                                  </IconButton>
                                </span>
                              </Tooltip>
                              <Menu
                                id="openEventSelection"
                                anchorEl={openSettings}
                                keepMounted
                                open={Boolean(openSettings)}
                                onClose={() => setOpenSetting(null)}
                              >
                                {optionsObjectDetections.map((value, index) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      dense
                                      onClick={() => {
                                        const temp = [...selectedEventsToSee];
                                        if (temp.includes(value.id)) {
                                          temp.splice(
                                            temp.findIndex((selected) => selected === value.id),
                                            1
                                          );
                                        } else {
                                          temp.push(value.id);
                                        }
                                        setSelectedEventsToSee(temp);
                                      }}
                                    >
                                      <Grid container justify="space-evenly" alignItems="center">
                                        <Checkbox
                                          color="primary"
                                          size="small"
                                          checked={selectedEventsToSee.includes(value.id)}
                                          checkedIcon={<Check fontSize="small" />}
                                        />
                                        {value.name}

                                        <FiberManualRecordIcon
                                          fontSize="small"
                                          style={{
                                            color: generateColorPerObjectDetection(value.id),
                                          }}
                                        />
                                      </Grid>
                                    </MenuItem>
                                  );
                                })}
                              </Menu>
                            </>
                          )}
                          {isPublic || openFullScreen ? null : (
                            <Tooltip title={t("Share") + "/" + t("Download")}>
                              <span>
                                <IconButton
                                  size={matchesMobile ? "small" : "medium"}
                                  aria-label="select timespan"
                                  className={styles.theIcons}
                                  style={
                                    matchesMobile ? { marginLeft: "5px", marginRight: "5px" } : {}
                                  }
                                  onClick={() => {
                                    setIsClikedTimespanSelectionButton(true);
                                    if (!(videoRef.current?.paused || videoRef.current?.ended)) {
                                      handleClickPlayPauseButton();
                                    }
                                  }}
                                  disabled={!loadedTheVideo || loadingWebRtcInfo}
                                >
                                  <CompareArrowsIcon fontSize="inherit" />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                          {/* {isPublic ? null : (
                          <IconButton
                            size={matchesMobile ? "small" : "medium"}
                            aria-label="download"
                            className={styles.theIcons}
                            style={matchesMobile ? { marginLeft: "5px", marginRight: "5px" } : {}}
                            onClick={() => {
                              if (isDynamicPlaylist && !isProcessedAllDynamic.current) {
                                videoRef.current?.pause();
                                setShowDialogToShareUnderDynamicPlaylist(true);
                              } else {
                                setOpenShareDialog(true);
                                videoRef.current?.pause();
                              }
                            }}
                            disabled={!loadedTheVideo || loadingWebRtcInfo}
                          >
                            <ShareIcon fontSize="inherit" />
                          </IconButton>
                        )} */}
                          {/* {isPublic ? null : (
                          <IconButton
                            size={matchesMobile ? "small" : "medium"}
                            aria-label="download"
                            className={styles.theIcons}
                            style={matchesMobile ? { marginLeft: "5px", marginRight: "5px" } : {}}
                            onClick={() => {
                              if (isDynamicPlaylist && !isProcessedAllDynamic.current) {
                                videoRef.current?.pause();
                                setShowDialogToDownloadUnderDynamicPlaylist(true);
                              } else {
                                startDownloadTask();
                              }
                            }}
                            disabled={!loadedTheVideo || loadingWebRtcInfo}
                          >
                            {!pendingDeviceInfoDownloadFromCloud ? (
                              <CloudDownloadIcon fontSize="inherit" />
                            ) : (
                              <Tooltip title={t("ThereIsADownloadInProccess")}>
                                <div
                                  style={{
                                    display: "flex",
                                    position: "relative",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    // padding: 10,
                                  }}
                                >
                                  <CircularProgress
                                    color="primary"
                                    size={25}
                                    variant="static"
                                    value={downloadingPercent}
                                  />
                                  <Typography
                                    variant="caption"
                                    style={{ position: "absolute", left: 5, top: 3 }}
                                    color="inherit"
                                  >
                                    {downloadingPercent}
                                  </Typography>
                                </div>
                              </Tooltip>
                            )}
                          </IconButton>
                        )} */}
                          <Tooltip title={t("Fullscreen")}>
                            <span>
                              <IconButton
                                size={matchesMobile ? "small" : "medium"}
                                aria-label="fullscreen"
                                className={styles.theIcons}
                                style={
                                  matchesMobile ? { marginLeft: "5px", marginRight: "5px" } : {}
                                }
                                onClick={() => {
                                  if (openFullScreen) {
                                    closeFullscreen();
                                  } else {
                                    openFullscreen();
                                  }
                                }}
                                disabled={!loadedTheVideo || loadingWebRtcInfo}
                              >
                                <SettingsOverscanIcon fontSize="inherit" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Grid container justify="center" style={{ marginTop: "4px" }}>
                      <Grid item>
                        <Typography color="primary" variant="body1">
                          {t("DropToFindTheRangeOfTime")}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                ) : (
                  <Grid container justify="center" style={{ marginTop: "4px" }}>
                    <Grid item xs={12} sm={4}>
                      <Grid container justify="space-evenly">
                        <Grid item>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              setShowComponentToSelectOperationAfterTimespanSelection(true)
                            }
                          >
                            {t("Select")}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            size="small"
                            variant="contained"
                            color="default"
                            onClick={() => {
                              setIsClikedTimespanSelectionButton(false);
                              setDragPixelValueTimespanSelectionStart({
                                x: 0,
                                y: 0,
                              });
                              setDragPixelValueTimespanSelectionEnd({
                                x: 0,
                                y: 0,
                              });
                            }}
                          >
                            {t("Cancel")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
            <DialogWrapper
              open={showComponentToSelectOperationAfterTimespanSelection}
              onClose={() => {
                setShowComponentToSelectOperationAfterTimespanSelection(false);
              }}
              maxWidth="sm"
              fullWidth
              title={t("Operations")}
            >
              <OperationsOverTimespanSelection
                onShowDialogToShareUnderDynamicPlaylist={() =>
                  setShowDialogToShareUnderDynamicPlaylist(true)
                }
                onShowDialogToDownloadUnderDynamicPlaylist={() =>
                  setShowDialogToDownloadUnderDynamicPlaylist(true)
                }
                onStartDownloadTask={() => startDownloadTask()}
                onStartShareTask={() => setOpenShareDialog(true)}
                refSignedUrls={refSignedUrls.current}
                refPlaylistToDownload={refPlaylistToDownload.current}
                isPublic={Boolean(isPublic)}
                isDynamicPlaylist={isDynamicPlaylist && !isProcessedAllDynamic.current}
                startDateTime={moment(startDate)
                  .add(
                    getSecondsFromPixelPositionInProgressBar(
                      dragPixelValueTimespanSelectionStart.x
                    ),
                    "seconds"
                  )
                  .toISOString()}
                endDateTime={moment(startDate)
                  .add(
                    getSecondsFromPixelPositionInProgressBar(
                      document.getElementById("progressBar")?.getBoundingClientRect().width +
                        dragPixelValueTimespanSelectionEnd.x
                    ),
                    "seconds"
                  )
                  .toISOString()}
                serialNumber={serialNumber}
                deviceName={deviceName}
                onClose={() => {
                  setShowComponentToSelectOperationAfterTimespanSelection(false);
                }}
                timeZoneOffset={timeZoneOffset}
              />
            </DialogWrapper>
            <DialogWrapper
              open={openShareDialog}
              onClose={() => {
                setOpenShareDialog(false);
              }}
              maxWidth="xs"
              fullWidth
            >
              <ShareEventVideoComponent
                time={moment(startDate)
                  .add(
                    getSecondsFromPixelPositionInProgressBar(
                      dragPixelValueTimespanSelectionStart.x
                    ),
                    "seconds"
                  )
                  .toISOString()}
                serialNumber={serialNumber}
                deviceName={deviceName}
                to={moment(startDate)
                  .add(
                    getSecondsFromPixelPositionInProgressBar(
                      document.getElementById("progressBar")?.getBoundingClientRect().width +
                        dragPixelValueTimespanSelectionEnd.x
                    ),
                    "seconds"
                  )
                  .toISOString()}
                onClose={() => {
                  setOpenShareDialog(false);
                }}
              />
            </DialogWrapper>
            <DialogWrapper
              open={showDialogToShareUnderDynamicPlaylist}
              maxWidth="xs"
              fullWidth
              disableBackdropClick
            >
              <Grid container justify="center">
                <Grid item xs={12}>
                  <Typography variant={"body1"}>{t("PreparingVideo")}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}
                >
                  <Button
                    variant="outlined"
                    color="default"
                    onClick={() => {
                      if (deviceChannel && deviceChannel.readyState === "open") {
                        const temp: SendDataChannelWebRTC = {
                          operation: OperationDataChannelType.StartUploadToCloud,
                          segmentList: [],
                        };
                        deviceChannel && deviceChannel.send(JSON.stringify(temp));
                        refGroupRequestedOfTimesToUpload.current = []; //stop uploading in the device
                      } else {
                        console.error("Channel closed");
                        toast.error(t("Error"));
                      }
                      setShowDialogToShareUnderDynamicPlaylist(false);
                      isProcessingNewSegments.current = false;
                      if (
                        deviceDynamicSegmentListRef.current.find(
                          (segment) => segment.uploaded == true
                        )
                      ) {
                        //there is uploaded video not processed
                        setLoadingWebRtcInfo(true);
                        setArrivedNewSegment((prev) => !prev);
                      }
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "8px" }}>
                  <LinearProgress />
                </Grid>
              </Grid>
            </DialogWrapper>

            <DialogWrapper
              open={showDialogToDownloadUnderDynamicPlaylist}
              maxWidth="xs"
              fullWidth
              disableBackdropClick
            >
              <Grid container justify="center">
                <Grid item xs={12}>
                  <Typography variant={"body1"}>{t("PreparingVideo")}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}
                >
                  <Button
                    variant="outlined"
                    color="default"
                    onClick={() => {
                      if (deviceChannel && deviceChannel.readyState === "open") {
                        const temp: SendDataChannelWebRTC = {
                          operation: OperationDataChannelType.StartUploadToCloud,
                          segmentList: [],
                        };
                        deviceChannel && deviceChannel.send(JSON.stringify(temp));
                        refGroupRequestedOfTimesToUpload.current = []; //stop uploading in the device
                      } else {
                        console.error("Channel closed");
                        toast.error(t("Error"));
                      }

                      setShowDialogToDownloadUnderDynamicPlaylist(false);
                      isProcessingNewSegments.current = false;
                      if (
                        deviceDynamicSegmentListRef.current.find(
                          (segment) => segment.uploaded == true
                        )
                      ) {
                        //there is uploaded video not processed
                        setLoadingWebRtcInfo(true);
                        setArrivedNewSegment((prev) => !prev);
                      }
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "8px" }}>
                  <LinearProgress />
                </Grid>
              </Grid>
            </DialogWrapper>
          </div>
        ) : (
          <Grid container justify="center" className={styles.fatalErrorContainer}>
            <Grid item>
              <Typography variant="body1" className={styles.fatalErrorTitle}>
                {fatalErrorMessage}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <DialogWrapper
        title={t("SelectDateRange")}
        open={isClickedIconButtonExpandLess}
        onClose={() => {
          setIsClickedIconButtonExpandLess(false);
        }}
        aria-labelledby="select date"
        aria-describedby="select the date"
        fullWidth={true}
        maxWidth={"md"}
      >
        <CalendarFromPlaybackCloud
          startDate={startDateTime}
          serialNumber={serialNumber}
          onSelected={(date: string) => {
            const endD = moment(date).add(clipsTime, "seconds").toISOString();
            changeStartDateAndEndDate(date, endD);
            setIsClickedIconButtonExpandLess(false);
          }}
        />
      </DialogWrapper>
    </Dialog>
  );
};
export default PlaybackCloud;
