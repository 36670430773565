const translationEN = {
  //Users  /  Group
  Users: "Users",
  AddUsers: "Add Users",
  AddUser: "Add User",
  ResetAntiPassback: "Reset Anti-Passback",
  TextAddUSerStep1: "User Info",
  TextAddUSerStep2: "Assign to a Group (Optional)",
  TakePhoto: "Take Photo",
  ErrorWebCamera: "There is an error, try enabling camera permissions",
  AddPhoto: "Add Photo",
  Reset: "Reset",
  Zoom: "Zoom",
  Rotate: "Rotate",
  Accept: "Accept",
  Assign: "Assign",
  Created: "Created",
  Create: "Create",
  Assigned: "Assigned",
  Groups: "Groups",
  AddGroup: "Add Group",
  Name: "Name",
  Description: "Description",
  TextAddGroupStep1: "Group Info",
  TextAddGroupStep2: "Assign Users (Optional)",
  UsersInTheGroup: "Users in the Group",
  PortalAccess: "Portal Access",
  YES: "YES",
  NO: "NO",
  CannotDeleteUsersInTheGroup: "There are users assigned to the group",
  GroupInfo: "Group Info",
  ConfirmRemoveUserFromTheGroup: "Confirm Remove the User from the Group",
  Removed: "Removed",
  AddAll: "Add All",
  UsersThereNotInTheGroup: "Users that are not in the group",
  ConfirmAddAllUserToTheGroup: "Confirm Add All Users to the Group",
  AddedAll: "Added All",
  RemoveAll: "Remove All",
  RemovedAll: "Removed All",
  ConfirmRemoveAllUserFromTheGroup: "Confirm Remove All The Users from the Group",
  UserInfo: "User Info",
  EditPhoto: "Edit Photo",

  // Sign Up Page
  SessionExpire: "Your session has expired",
  UnknownError: "The server encountered an error processing the request.",
  ResourceNotFound: "Resource Not Found",
  ForbiddenAccess: "Forbidden Access",
  ForbiddenAccessSubtitle: "Sorry, you do not have access to this page.",
  ForbiddenAccessSubtitle2: "If you feel something is missing that should be here, contact us.",

  AccountWasCreated: "Your account has been created",
  YouWillReceiveAnEmail: "You will receive an email with further instructions shortly",
  PasswordNotMatch: "Passwords do not match",
  SignUp: "Sign Up",
  AccountName: "Account name",
  FirstName: "First name",
  LastName: "Last name",
  Email: "Email",
  Password: "Password",
  ConfirmPassword: "Confirm password",
  Subscription: "Subscription",
  SubscriptionDescription:
    "The selected subscription is based on your devices entry quantity. You can always upgrade, and upload more devices later on.",
  CVC: "CVC",
  // AgreeTerms: "By clicking “Next”, you agree to our",
  AgreeTerms: "By clicking next I agree to Alocity's",
  termsofservice: "terms of service",
  HaveAnAccount: "Already have an account?",
  TextSingUpStep1: "Contact Info",
  TextSingUpStep2: "Add Devices",
  TextSingUpStep3: "Subscription & Payment",
  OpenQRReader: "Open QR Reader",
  QRError: "No video input devices found",
  Serial: "Serial",
  AddMoreDevices: "Add more devices",
  Remove: "Remove",
  Manually: "Manually",
  QRReader: "QR Reader",
  ScanDeviceQRCode: "Scan Device QR Code",
  YouNeedToEnableYourCamera: "You need to enable your camera to scan the QR code",
  QRBoxInfo: "Example",
  EnterScanningQRCode: "Enter Numbers By Scanning QR Code",
  EnterDevicesManually: "Enter Numbers Manually",
  EnterDeviceNumbers: "Enter Device Numbers",
  SerialFieldBoxInfo: "Example 2",
  Add: "Add",
  DeviceAddedAlready: "The device was added already",
  DeviceAdded: "Device Added",
  AccountCreated: "Account created",
  year: "year",
  devices: "devices",
  for: "for",
  BillingAddress: "Billing Address",
  CardNumber: "Card Number",
  NameOnCard: "Name on card",
  Expiration: "Expiration",
  Payment: "Payment",
  DeviceInvalid: "Device invalid",
  ZipCode: "Postal code",
  AddressLine: "Address line",

  // Sign In Page
  SignIn: "Sign In",
  Saved: "Saved",
  Error: "Error",
  ForgotPassword: "Forgot password?",
  SignInToYourAccount: "Sign In",
  DontHaveAnAccount: "Need an account?",
  Yes: "Yes",
  No: "No",
  EmailNotVerified: "Email not verified",
  DoYouWantToResendEmail: "Do you want to resend the email?",
  EmailSent: "Email sent",
  SignOut: "Sign Out",
  //layout
  Settings: "Settings",

  // Forgot Password
  RecoveryPassword: "Password Recovery",
  TroubleForgotPasswordText:
    "Forgot your account’s password? Enter your email address and we will send you a recovery link.",
  Continue: "Continue",
  ReturnToSignIn: "Return to sign in",
  SuccessChangePassword: "You have successfully changed your password",
  AccountRecoveryEmailSent: "Account recovery email sent",
  TextContentSuccessForgotPassword:
    "Check your email for instructions to reset your password. If you do not see this email in your inbox within 15 minutes, look for it in your junk mail folder. If you find it there, please mark it as “Not Junk”. ",
  ResetPasswordText: "Reset your password",

  // Verify Email
  EmailVerified: "Your email has been verified",
  FailedEmailVerification: "Failed to verify email",
  YouCanSignInNewAccount: "You can now sign in with your new account",
  GoToSignInPage: "Go to Sign In page",

  // Validation Text
  Required: "Required",
  ShortText: "The text is too short",
  LongText: "The text is too long",
  InvalidEmail: "The email is not valid",
  ShortPassword: "The password is too short",
  InvalidPassword:
    "Passwords must contain at least eight characters, including at least 1 uppercase letter and 1 number",
  UnknowQR: "Unknown QR",
  ErrorConnectingWithPaymentProvider: "Failed to connect to payment provider",
  InvalidURL: "Invalid URL",

  // Profile
  Profile: "Profile",
  Save: "Save",
  NewPassword: "New password",
  CurrentPassword: "Current password",

  // Subscriptions
  Subscriptions: "Subscriptions",
  BillingSubscriptions: "Billing & Subscriptions",
  Transactions: "Transactions",
  Upcoming: "Upcoming",
  PaymentMethod: "Payment Method",
  InvoiceNumber: "Invoice Number",
  Date: "Date",
  Dates: "Dates",
  CreatedDate: "Created Date",
  DueDate: "Due Date",
  Amount: "Amount",
  Paid: "Paid",
  Unpaid: "Unpaid",
  Void: "Void",
  ProductName: "Product Name",
  Comments: "Comments",
  Invoices: "Invoices",
  Type: "Type",
  NewCard: "New Card",
  RenewalDate: "Renewal Date",
  Draft: "Draft",
  Uncollectible: "Uncollectible",
  Open: "Open",
  ConfirmCancelation: "Confirm Cancelation",
  AreYouSureWantContinues: "Are you sure you want to continue?",
  ConfirmDeletion: "Confirm Deletion",
  Default: "Default",
  Changed: "Changed",
  ConfirmChangeDefaultPaymentMethod: "Confirm default payment method change",
  ChangeDefaultPayment: "Change to Default Payment",
  DefaultPayment: "Default Payment",

  // Table
  FirstPage: "First Page",
  PreviousPage: "Previous Page",
  NextPage: "Next Page",
  LastPage: "Last Page",
  New: "New",

  // Router
  VideoWall: "Video Wall",
  People: "People",
  Cameras: "Cameras",
  Dashboard: "Dashboard",
  Empty: "Empty",

  // Settings
  GlobalSettings: "Global Settings",
  SaveChanges: "Save Changes",
  Sites: "Sites",
  Site: "Site",
  Zones: "Zones",
  Zone: "Zone",
  TextSummary1GlobalSettings:
    "Your sites and zones are locations in your company where your devices will be placed.",
  SampleDiagram: "Sample Diagram",
  Roles: "Roles",
  TextSummary2GlobalSettings: "Manage the roles in your account",
  TimeZone: "Time Zone",
  CreateNewSite: "Add New Site",
  ZonesQty: "Zones Qty",
  CamerasAssigned: "Cameras Assigned",
  Actions: "Actions",
  CreateANewSite: "Create a New Site",
  SiteName: "Site Name",
  EditSite: "Edit Site",
  DeleteSite: "Delete Site",
  AddZone: "Add Zone",
  ManageDevices: "Manage Doors",
  Deleted: "Deleted",
  Delete: "Delete",
  Updated: "Updated",
  Update: "Update",
  CreateZoneForThisSite: "Create Zone for this Site",
  ZoneName: "Zone Name",
  selected: "selected",
  AvailableCameras: "Available Cameras",
  SiteCameras: "Site Cameras",
  SubText_ManageSiteDevices:
    "A door cannot be assigned to two different sites or zones. If you cannot find a device from the list, it may be assigned to another site. Make sure to select the site and unassign it to become available",
  AccessControl: "Access Control",
  Sensors: "Sensors",
  More: "More",
  Less: "Less",
  EditZone: "Edit Zone",
  EditZones: "Edit Zones",
  AddDevice: "Add Device",
  AddCamera: "Add Camera",
  DeleteZone: "Delete Zone",
  Active: "Active",
  Inactive: "Inactive",
  Pending: "Pending",
  Suspended: "Suspended",
  Card: "Card",
  CurrentPeriodEnd: "Period End",
  Total: "Total",
  Canceled: "Canceled",
  Incomplete: "Incomplete",
  PastDue: "Past Due",
  All: "All",
  Trialing: "Trialing",
  Expired: "Expired",
  CanceledAt: "Canceled Date",
  StopRenewal: "Stop Renewal",
  ChangeDeviceLocation: "Change Location",
  DeleteDevice: "Delete Door",
  SelectASite: "Select a Site",
  SelectAZone: "Select a Zone",
  DevicesQty: "Door Qty",
  PaymentDue: "Payment Due",

  SiteSettingDescriptionText:
    "This is the location name and time zone for the facility, building, office, or residence.",
  ZoneSettingDescriptionText: "Group doors by zones to easily set schedules and analytics.",
  GroupSettingDescriptionText: "Easily set access levels for users by groups.",
  Privileges: "Privileges/Roles",
  PrivilegesSettingDescription: "Displays permissions for roles.",

  // Cameras
  Location: "Sites",
  Editable: "Editable",
  EnterName: "Enter Name",
  LicensesBoxesSubtitle: "The selected subscription is based on your device's entry quantity.",
  ListPaymentMethodSubtitle: "What card do you want to use?",
  UseANewCard: "Use a new card",
  Added: "Added",
  ErrorToConnectPaymentProvider: "Failed to connect to the payment provider",
  AddCameras: "Add Cameras",
  ChangeDeviceName: "Change Name",
  Back: "Back",
  CameraDetails: "Camera Details",
  SharedStreams: "Shared Streams",
  RebootCamera: "Reboot Camera",
  UnregisterCamera: "Unregister Camera",
  Info: "Info",
  Events: "Events",
  History: "History",
  Model: "Model",
  Motion: "Motion",
  HumanMovement: "Human Movement",
  FaceDetected: "Face Detected",
  Packages: "Packages",
  Disconnected: "Disconnected",
  Camera: "Camera",
  GoToLive: "Go to live",
  PreviousDay: "Previous Day",
  PersonDetected: "Person Detected",
  Vehicle: "Vehicle",
  AnyMovement: "Any Movement",
  NoMasterConnected: "The device is not connected to the server to transmit the video",

  // Doors
  Doors: "Doors",
  AddDoor: "Add Door",
  SecondaryZone: "Secondary Zone",
  PrimaryZone: "Primary Zone",
  DoorState: "Door State",
  LockState: "Lock State",
  Closed: "Closed",
  OpenVerified: "Open Verified",
  OpenRequestToExit: "Open Request to Exit",
  OpenForcedEntry: "Open Forced Entry",
  OpenTimedOut: "Open Timed Out",
  NoSensor: "No Sensor",
  Locked: "Locked",
  Unlocked: "Unlocked",
  Lockdown: "Lockdown",
  LockdownDoor: "Lockdown Door",
  KeepOpen: "Keep Door Unlocked",
  UnlockedWithSchedule: "Unlocked with Schedule",
  UnlockedRequestToExit: "Unlocked Request to Exit",
  OpenDoor: "Unlock The Door",
  AddDevices: "Add Devices",
  AccessControlInfo: "Access Control Info",
  Opened: "Opened",
  Status: "Status",
  Connected: "Connected",
  Online: "Online",
  Offline: "Offline",
  RemoteAccess: "Remote Access",
  Reboot: "Reboot",
  DeviceDisconnected: "Device Disconnected",
  LastKnownState: "Last Known State",
  Rebooted: "Rebooted",
  ConfirmOperation: "Confirm Operation",
  NetworkSettings: "Network Settings",
  IPAddress: "IP Address",
  IP: "IP",
  DefaultGateway: "Default Gateway",
  Gateway: "Gateway",
  Netmask: "Netmask",
  Reseted: "Reset",
  DHCP: "DHCP",
  RequiredSelectOneZone: "It is required to select at least one zone",
  CannotBePrimaryAndSecondaryAtTheSameTime:
    "Cannot belong to a primary zone and secondary zone at the same time",
  NotChangeDetected: "No change detected",
  Door: "Door",
  DoorInfo: "Door Info",

  // Notifications
  NotificationsDenied: "Notifications are denied",
  NotificationNoSupported: "This browser does not support desktop notifications",
  Error_NotificationNoRegistrationTokenAvailable:
    "No registration token available. Request permission to generate one.",
  Error_NotificationAnErrorOcurred: "An error occurred while retrieving token.",

  //AccessLogs
  AccessLogs: "Access Logs",
  Time: "Time",
  Result: "Result",
  AccessGranted: "Access Granted",
  NotScheduled: "Not Scheduled",
  UnknownUser: "Unknown User",
  Filter: "Filter",
  Filters: "Filters",
  Clear: "Clear",
  Done: "Done",
  From: "From",
  To: "To",
  Cancel: "Cancel",
  and: "and",
  SerialNumber: "Serial Number",
  Workflow: "Workflow",
  Face: "Face",
  Mobile: "Mobile",
  Web: "Web",
  //Schedules
  Schedules: "Schedules",
  AddSchedule: "Add Schedule",
  ScheduleInfo: "Schedule Info",
  Automatic: "Automatic",
  When: "When",
  Resync: "Resync",
  TextAddScheduleStep1: "Basic Info",
  TextAddScheduleStep2: "Assign WHEN",
  TextAddScheduleStep3: "Assign WHO",
  TextAddScheduleStep4: "Assign WHERE",
  NeverEnds: "Never Ends",
  InvalidDate: "Invalid Date",
  InvalidTime: "Invalid Time",
  Repeating: "Repeating",
  BasicInfo: "Basic Info",
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  StartTime: "Start Time",
  EndTime: "End Time",
  MON: "MON",
  TUE: "TUE",
  WED: "WED",
  THU: "THU",
  FRI: "FRI",
  SAT: "SAT",
  SUN: "SUN",
  Holidays: "Holidays",
  StartDate: "Start Date",
  EndDate: "End Date",
  Next: "Next",
  AddWhen: "Add When",
  Other: "Other",
  ActiveOnHolidays: "Active On Holidays",
  InvalidTimeComparison: "The time has to be greater than the start time",
  InvalidDateComparison: "The end date has to be greater than the start date",
  ErrorTimeMustBeDifferent: "The times must be different",
  WhoTitleText:
    "Select for WHO the schedule will be working. The option 'Users' will give the possibility to assign per user, the option 'Groups' will give the possibility to assign per group of users and the 'Automatic Open Schedule' will give the possibility to open without a user.",
  AutomaticOpenSchedule: "Automatic Open Schedule",
  GroupsInTheSchedule: "Groups in the Schedule",
  ConfirmRemoveGroupFromTheSchedule: "Confirm Remove the Group from the Schedule",
  ConfirmAddAllGroupsToTheSchedule: "Confirm Add All Groups to the Schedule",
  ConfirmRemoveAllGroupsFromTheSchedule: "Confirm Remove All Groups from the Schedule",
  GroupsThereNotInTheSchedule: "Groups that are not in the schedule",
  Set: "Set",
  UnSet: "Un Set",
  Activate: "Activate",
  Deactivate: "Deactivate",
  Activated: "Activated",
  Deactivated: "Deactivated",
  ActivatedAutomatic: "Activated automatic open schedule",
  DeactivatedAutomatic: "Deactivated automatic open schedule",
  RemovedAllGroups: "Removed All Groups",
  RemovedAllUsers: "Removed All Users",
  UsersInTheSchedule: "Users in the Schedule",
  ConfirmRemoveUserFromTheSchedule: "Confirm Remove User from the Schedule",
  ConfirmAddAllUserToTheSchedule: "Confirm Add All Users to the Schedule",
  ConfirmRemoveAllUsersFromTheSchedule: "Confirm Remove All Users from the Schedule",
  UsersThereNotInTheSchedule: "Users that are not in the schedule",
  RemovedAllSites: "Removed All Sites",
  RemovedAllZones: "Removed All Zones",
  RemovedAllDoors: "Removed All Doors",
  WhereTitleText:
    "Select to WHERE the schedule will be working. The option 'Sites' will give the possibility to assign per site, the option 'Zones' will give the possibility to assign per group of doors and the 'Doors' will give the possibility to assign per door.",
  Finalize: "Finalize",
  DoorsInTheSite: "Doors in the Site",
  SitesInTheSchedule: "Sites in the Schedule",
  AddSite: "Add Site",
  ConfirmRemoveSiteFromTheSchedule: "Confirm Remove the Site from the Schedule",
  SitesThereNotInTheSchedule: "Sites that are not in the schedule",
  ConfirmAddAllSitesToTheSchedule: "Confirm Add All Sites to the Schedule",
  ConfirmRemoveAllSitesFromTheSchedule: "Confirm Remove All Sites from the Schedule",
  DoorsInTheZone: "Doors in the Zone",
  ZonesInTheSchedule: "Zones in the Schedule",
  ConfirmRemoveZoneFromTheSchedule: "Confirm Remove Zone from the Schedule",
  ZonesThereNotInTheSchedule: "Zones that are not in the schedule",
  ConfirmAddAllZonesToTheSchedule: "Confirm Add All Zones to the Schedule",
  ConfirmRemoveAllZonesFromTheSchedule: "Confirm Remove All Zones From The Schedule",
  DevicesInTheSchedule: "Doors In The Schedule",
  DevicesThereNotInTheSchedule: "Doors that are not in the schedule",
  ConfirmAddAllDevicesToTheSchedule: "Confirm Add All Doors to the Schedule",
  ConfirmRemoveAllDevicesFromTheSchedule: "Confirm Remove All Doors from the Schedule",
  ScheduleCreationFinalized: "Schedule creation finalized",
  ThereAreNotUsersToRemove: "There are not users to remove",
  ThereAreNotUsersToAdd: "There are not users to add",
  ThereAreNotGroupsToAdd: "There are not groups to add",
  ThereAreNotGroupsToRemove: "There are not groups to remove",
  ThereAreNotSitesToAdd: "There are not sites to add",
  ThereAreNotSitesToRemove: "There are not sites to remove",
  ThereAreNotZonesToAdd: "There are not zones to add",
  ThereAreNotZonesToRemove: "There are not zones to remove",
  ThereAreNotDoorsToAdd: "There are not doors to add",
  ThereAreNotDoorsToRemove: "There are not doors to remove",
  Completed: "Completed",
  IncompleteMissingTheWhen: "Incomplete: Missing the When",
  IncompleteMissingTheWho: "Incomplete: Missing the Who",
  IncompleteMissingTheWhere: "Incomplete: Missing the Where",
  NotDetectedAnyChange: "No changes detected",
  ShowOptions: "Show Options",
  Warning: "Warning",
  ChangeToAnotherOptionWillDeleteTheUserOrGroupSelected:
    "Changing to this option will delete the users or groups added  in this schedule previously. Are you sure you want to continue?",

  // Zones
  PrimaryDevices: "Primary Devices",
  SecondaryDevices: "Secondary Devices",
  ZoneInfo: "Zone Info",
  ThisZoneHasDevicesUpdate:
    "This zone has doors, so change the doors from the zone before moving the zone to another site",
  ThisZoneHasDevicesDelete:
    "This zone has doors, so change the doors from the zone before deleting the zone",
  ActionDenied: "Action Denied",
  EditZoneName: "Edit Zone Name",

  /* Applications Response Errors*/

  // Account Errors
  error_account_not_created: "Failed to create account",
  error_account_read_error: "Failed to retrieve account",
  error_account_delete_error: "Failed to delete account",
  error_account_not_found: "Account not found",
  error_account_not_updated: "Failed to update account",
  error_account_name_required: "Account name is required",
  error_account_at_least_one_device_required: "At least one device is required",
  error_account_pending: "Account is pending",
  error_account_subscription_not_found: "Subscription not found",
  error_account_verification_link_not_sent: "Failed to send verification link",
  error_account_email_required: "Email is required",
  error_account_settings_not_updated_in_devices: "Failed to update account settings in device",
  error_account_settings_not_found: "Account settings not found",
  error_not_found_any_connected_device: "No connected devices found",

  // License Errors
  error_license_not_found: "License not found",
  error_license_read_error: "Failed to retrieve license",

  // Device Errors
  error_device_not_created: "Failed to create device",
  error_device_read_error: "Failed to retrieve device",
  error_device_delete_error: "Failed to delete device",
  error_device_invalid_serial_number: "Error, invalid serial",
  error_device_not_found: "Device not found",
  error_device_provisioning_error: "Failed to provision device",
  error_device_not_available: "Device is not available",
  error_device_already_registered_in_same_account:
    "Error, the device is already registered on this account",
  error_device_assign_error: "Failed to assign device",
  error_device_update_error: "Failed to update device",
  error_device_reboot_error: "Device reboot error", ///
  error_device_network_setting: "Network settings error",
  error_device_network_setting_not_found: "Network settings not found",
  error_device_notification: "Notification error", ////
  error_invalid_time_interval: "Invalid time interval",
  error_require_zones: "Zones are required", ////
  lock_state_type_not_permitted: "Lock state type not allowed",
  error_lock_state_type: "Lock state type error", ///
  error_setting_a_device: "Failed to set device", ///
  error_zones_not_in_same_site: "Zones are not in the same site", ///
  error_wrtc: "WebRTC Error",
  error_account_Id_is_different_in_the_request: "Error, account ID is different in the request",
  error_ble_key: "BLE Key Error",
  error_is_not_access_control_device: "Error, that is not an access control device",
  error_saving_access_control_settings: "Error saving access control settings",

  // Site Errors
  error_site_not_created: "Failed to create site",
  error_site_read_error: "Failed to retrieve site",
  error_site_delete_error: "Failed to delete site",
  error_site_not_found: "Site not found",
  error_site_update_error: "Failed to update site",
  error_site_has_zones: "Site has zones", ///

  // Zone Errors
  error_zone_not_created: "Failed to create zone",
  error_zone_read_error: "Failed to read zone",
  error_zone_update_error: "Failed to update zone",
  error_zone_delete_error: "Failed to delete zone",
  error_zone_not_found: "Error, zone not found",
  error_zone_assign_devices: "Error assigning zone to devices",
  error_equal_primary_and_secondary_zone: "Error, primary and secondary zones are equal",

  // Payment Error (and success messages)
  success_payment_get_customer_portal_url: "Successfully retrieved customer portal url", ///,
  error_payment_get_customer_portal_url: "Failed to retrieve customer portal url",
  success_payment_webhook_invocation: "Successfully invoked payment webhook", ///
  error_payment_webhook_invocation: "Failed to invoke payment webhook",
  success_payment_customer_created: "Successfully created customer payment", ///
  error_payment_customer_not_found: "Customer payment not found", ////
  error_payment_setup_intent_error: "Failed to setup payment intent",
  error_payment_subscription_create_error: "Failed to create payment subscription",
  error_payment_subscription_delete_error: "Failed to delete payment subscription",
  error_payment_countries_not_updated: "Failed to update countries",
  error_payment_public_key_not_found: "Public key not found",
  error_payment_customer_not_created: "Failed to create customer", ///
  error_payment_customer_without_account: "Found no account associated with customer", ///
  error_payment_unknow_error: "An unknown error has occurred while processing your payment", ///
  payment_succeeded: "Payment was successful", ///
  incorrect_zip: "Incorrect zip",
  incorrect_cvc: "Incorrect cvc",
  card_declined: "Your card was declined",
  insufficient_funds: "You do not have sufficient funds",
  expired_card: "Your card has expired",
  processing_error: "Failed to process payment",
  incorrect_number: "The provided number is incorrect",
  error_payment_detach_payment_method: "detach payment method error", /// ??
  error_payment_read_error: "Failed to read payments",
  error_payment_payment_method_not_found: "Payment method not found", /// invalid?
  error_payment_set_default_payment_method: "Failed to set default payment method",
  error_payment_subscription_read_error: "Failed to retrieve subscription", ///
  error_payment_subscription_not_found: "No subscription found", ///
  error_payment_invoice_read_error: "Failed to retrieve invoice", ////
  error_payment_upcoming_invoice_not_found: "Upcoming invoice not found",

  // Phone Number
  error_phonenumber_invalid_format: "Invalid phone number format",
  error_phonenumber_duplicated: "The provided phone number is already used by another user.",
  error_require_phone_number: "Phone number required",

  // System Error (and success messages)
  succeed_system_operation: "Successfully performed system operation", ////
  failed_system_operation: "Failed to perform system operation",
  error_system_disable_email_and_sms_notifications: "Failed to disable email and sms notifications",
  error_invalid_value: "Invalid value",

  // Password Reset Errors
  failed_passwordreset_operation: "Failed to reset password",

  // Account Switch Errors
  failed_accountswitch_operation: "Account switch operation failed",

  // Token Errors
  error_token_invalid: "Invalid token",
  error_token_not_found: "Token not found",
  error_token_inactive: "Inactive token",
  error_token_expired: "Expired token",
  error_token_update_failed: "Failed to update token",
  error_token_delete_failed: "Failed to delete token",
  info_token_delete: "Delete token info",
  error_token_create_failed: "Failed to create token",
  info_token_create: "Create token info",

  // User Errors
  error_user_not_created: "Failed to create user",
  error_user_not_deleted: "Failed to delete user",
  error_user_not_found: "User not found",
  error_user_error_deleting_account_owner: "You cannot delete account owner",
  error_user_not_read: "Failed to retrieve user",
  error_user_not_updated: "Failed to update user",
  error_user_error_updating_account_owner: "Failed to update account owner", ///?
  error_user_confirm_email_verification: "Confirmation email verification failed",
  error_user_change_password: "Failed to change passsword", ///
  error_user_verify_password_reset_code: "Failed to verify password reset code", ///
  error_user_get_accounts: "Failed to retrieve user accounts",
  error_user_accounts_not_found: "Accounts not found",
  error_user_email_already_used_on_same_account: "Email already in use on the same account",
  error_user_invalid_settings: "Invalid user settings",
  error_user_external_system_id_not_created: "Failed to create external system ID",
  error_user_external_system_id_not_deleted: "Failed to delete external system ID",
  error_user_invalid_external_system_id: "Invalid external system ID",
  error_require_role_id: "Role ID is required",
  error_invalid_role_id: "Invalid role ID",
  error_credential: "Credentials Error", ///
  error_face_not_found: "Face not found",

  // Locale Errors
  error_locale_not_found: "Locale not found",
  error_locale_iso2_required: "Locale ISO2 is required",

  // Role Errors
  error_role_not_found: "Role not found",
  error_reading_role: "Failed to retrieve role",
  error_creating_role: "Failed to create role",
  error_reading_scope: "Failed to retrieve scope",
  error_deleting_role: "Failed to delete role",
  error_updating_role: "Failed to update role",

  // Identity Errors
  error_identity_user_not_deleted: "Failed to delete user identity", ///
  error_identity_user_already_exist: "User identity already exists", ///
  error_identity_user_not_created: "Failed to create user identity", ///
  error_identity_user_not_updated: "Failed to update user identity", ///
  error_identity_not_generated_link: "Failed to generate identity link", ///
  error_identity_credential_too_old_login_again: "Old credentials. Please login again", ///
  error_identity_unknow_response: "Unknown identity error", ///
  error_identity_wrong_password: "Wrong identity password", ///

  // Country Errors
  error_country_read_error: "Failed to retrieve country",

  // State Errors
  error_state_read_error: "Failed to retrieve state",

  // Timezone Errors
  error_timezone_not_found: "Timezone not found",
  error_timezone_read_error: "Failed to retrieve timezone",

  // QrCode
  error_qr_code_create_error: "Failed to create QR Code",

  // Notifications Error (and messages)
  error_notification_required_parameter: "Missing required parameter",
  error_notification_error_sending: "Failed to send notification",
  notification_person_detected: "Person detected",
  notification_vehicle_detected: "Vehicle detected",
  notification_motion_detected: "Motion detected",
  notification_status_connected: "Connected",
  notification_status_disconnected: "Disconnected",
  notification_status_updating: "Updating",
  notification_lock_state: "Lock state changed",
  notification_door_state: "Door state changed",
  notification_face_enrollment_result: "Received face enrollment result",
  notification_wrtc_result: "Received WebRTC result",
  notification_access_log: "Received access log",
  notification_firmware_upgrade: "Received firmware upgrade",
  notification_door_bell_ring: "Door Ringing",

  // Schedule Errors
  error_schedule_processing: "Schedule processing error",
  error_unknown_schedule_type: "Unknown schedule type",
  error_schedule_not_found: "Schedule not found",
  error_schedule_has_duplicated_entities: "Schedule has duplicated entities",
  error_schedule_is_null: "Schedule is null",
  error_schedule_has_no_cronschedules: "Schedule has no cron schedules",
  error_reading_schedule_info: "Failed to retrieve schedule information",
  error_group_required: "Group is required",
  error_user_required: "User is required",
  error_device_required: "Device is required",
  error_zone_required: "Zone is required",
  error_site_required: "Site is required",

  // Cron Schedule Errors
  error_cron_has_no_duration: "Cron has no duration",
  error_cron_invalid_interval: "Invalid cron interval",
  error_cron_invalid_expression: "Invalid cron expression",

  // Group Errors
  error_group_not_created: "Failed to create group",
  error_group_not_read: "Failed to retrieve group",
  error_group_not_updated: "Failed to update group",
  error_group_not_deleted: "Failed to delete group",
  error_group_not_found: "Group not found",

  // Card Errors
  error_card_not_created: "Failed to create card",
  error_card_not_read: "Failed to retrieve card",
  error_card_not_updated: "Failed to update card",
  error_card_not_deleted: "Failed to delete card",
  error_card_not_found: "Card not found",

  // Access Log Errors
  error_access_log_not_created: "Failed to create access log",
  error_access_log_read: "Failed to retrieve access log",
  error_invalid_access_result: "Invalid access result",

  // Cloud Key Errors
  error_cloud_key_not_found: "Cloud key not found",
  error_cloud_key_expired: "Your cloud key has expired",
  error_cloud_key_out_of_time_rage: "Cloud Key is out of time range",
  error_invalid_time_scope: "Invalid time scope",
  error_creating_cloud_key: "Failed to create cloud key",
  error_first_name_and_last_name_required: "First name and last name are required",
  error_open_the_door: "Failed to open the door",
  error_reading_cloud_keys: "Failed to retrieve cloud keys",
  error_updating_cloud_key: "Failed to update cloud key",

  // Face
  error_deleting_face: "Failed to delete face",
  error_not_found: "Error, face not found",
  error_low_quality: "Error, low quality",
  error_image_notsaved: "Error, image not saved",
  error_face_does_not_match: "Error, face does not match",
  error_user_has_3d_face:
    "User with face registered already. Please remove before sending Selfie invitation.",

  // Audit Trail
  error_create_audittrail: "Failed to create audit trail",

  error_empty_identification_workflow_list: "Error, the list of identification workflow is empty",

  None: "None",
  Unknown: "Unknown",
  TimedOut: "Timed out",
  Cancelled: "Cancelled",
  FaceAlreadyEnrolled: "Face has already been enrolled",
  CardAlreadyEnrolled: "Card has already been enrolled",
  NoFacesInImage: "No face was detected in the image",
  TooManyFacesInImage: "Too many faces were detected in the image",
  FaceTooClose: "Face is too close",
  FaceTooFar: "Face is too far",
  FaceNotStraightForward: "Please, bring your face forward and look straight ahead",

  // Firebase Authentication Errors
  auth_invalid_email: "Invalid email",
  error_email_invalid_format: "Invalid email format",
  auth_wrong_password: "Incorrect password",
  // auth_user_not_found: "The user not corresponding the link. This may have happened if the user was deleted between when the link was issued and when this page was called",
  auth_user_not_found:
    "User not found. This can occur if the user is deleted in between the time when the link was issued and the page was called",
  auth_user_disabled: "The user has been disabled", //
  auth_weak_password: "The new password is not strong enough",
  auth_invalid_action_code: "Expired or malformed link",
  auth_expired_action_code: "Expired link",
  auth_user_mismatch: "The supplied credentials do not correspond to the previously signed in user",
  auth_invalid_credential: "Invalid credentials",
  auth_reauthenticate_user_not_found:
    "The supplied credentials do not correspond to any existing user",
  auth_invalid_verification_code: "Invalid verification code",
  auth_invalid_verification_id: "Invalid verification id",
  auth_requires_recent_login: "User's last sign-in time does not meet the security threshold",
  auth_web_storage_unsupported: "The browser does not support web storage",
  auth_too_many_requests: "Request blocked due to unusual activity. Please try again later",
  auth_operation_not_allowed:
    "The supplied provider is not enabled. Please enable it before proceeding", //
  auth_email_already_in_use: "Email is already in use",
  auth_network_request_failed:
    "A network error (such as timeout, interrupted connection or unreachable host) has occurred",

  //Users  /  User Details
  Admin: "Admin",
  User: "User",
  AddCredential: "Add Credential",
  PhoneNumber: "Phone Number",
  Role: "Role",
  TypesOfCredentials: "Type of Credential",
  FacilityCode: "Facility Code",
  CardId: "Card Id",
  Edit: "Edit",
  Capture: "Capture",
  Submit: "Submit",
  Retake: "Retake",
  AreYouSureYouWantToContinue: "Are you sure you want to continue?",
  AddCard: "Add Card",
  EditCard: "Edit Card",

  GroupsWithTheUser: "Groups with the user",
  ConfirmAddTheUserToAllGroups: "Confirm add the user to all groups",
  ConfirmRemoveTheUserFromAllGroups: "Confirm remove the user from all groups",
  ConfirmRemoveTheUserFromTheGroup: "Confirm remove the user from the group",
  TheNumbersOfUsersThatTheGroupContain: "Number of users the group contains",
  GroupsThatNotContainTheUser: "Groups that do not contain the user",
  ImageSent: "Image sent. Analyzing the image",
  ImageAddedForTheUser: "Image added for the user",
  FailAddImageForTheUser: "Failed to add image for the user",
  becauseOf: "because of",
  //cloudkey
  DoorWithAccess: "Door With Access",
  UseOneTime: "One Time Use",
  ValidFrom: "Valid From",
  ExpireOn: "Expires On",
  SentBy: "Sent By",
  Phone: "Phone",
  CannotDeleteInactiveCloudKey: "Can not delete inactive cloud key",
  CloudKey: "Cloud Key",
  ExternalPersonInfo: "External person information",
  SendInvitationByEmail: "Send invitation by email",
  SendInvitationByText: "Send invitation by text",
  SendInvitationBy: "Send invitation by",
  UseOneTimeOnly: "Use one time only",
  InvalidPhoneNumber: "Invalid phone number format",
  AccessTo: "Access to",
  Inactivated: "Inactivated",
  Inactivate: "Inactivate",
  ConfirmInactivation: "Confirm Inactivation",
  ResyncDevice: "Resync Device",
  DoorOpened: "Door Opened",
  OpenAgain: "Open Again",
  CloudKeyAccess: "Cloud key access",
  InvitationType: "Invitation Type",
  Link: "Link",
  QRCode: "QR code",
  QrCode: "QR code",

  TheUserIsNotAssociatedWithASchedule:
    "The user is not associated with any schedule and for that reason does not have access to any doors.",
  //roles
  AddRole: "Add Role",
  TotalPermissions: "Total Permissions",
  RoleInfo: "Role Info",
  TextAddRoleStep1: "Basic Info",
  TextAddRoleStep2: "Permissions",
  Preset: "Preset",
  AddPermissionTitleText: "Select the permissions for the new role",

  //sites
  TotalDevices: "Total Devices",
  TotalZones: "Total Zones",
  ThisSiteHasZones: "This site has zones, so delete the zones first.",
  DevicesInTheZone: "Devices in the Zone",
  SiteInfo: "Site Info",
  AddNewZone: "Add New Zone",
  PayAndComplete: "Pay & Complete",

  //500 page error
  SystemError: "Sorry, unexpected error",
  SystemErrorSubtitle: "We are not exactly sure what happened, but our team has been notified.",
  OurTeamNotified:
    "Our team has been notified. If you'd like to help, tell us what happened below.",

  Language: "Language",
  English: "English",
  Spanish: "Spanish",

  // Page Not Found
  PageNotFound: "404",
  PageNotFoundSubtitle: "Sorry, we couldn't find the page you were looking for.",
  GoDashboard: "Return to Dashboard",

  //SupportArea
  Firmware: "Firmware",
  SupportArea: "Support Area",
  AccountAreaDescriptionText: "Control and take actions over different areas.",
  FirmwareDescriptionText: "Upgrade the firmware of the devices.",
  UpgradeAllAccounts: "Upgrade all accounts",
  UpgradePerAccount: "Upgrade per account",
  UpgradePerDevices: "Upgrade per devices",
  ConfirmUpgrade: "Confirm Upgrade",
  ConfirmUpgradeAllAccountsText:
    "You are upgrading all the devices of all accounts. Are you sure that you want to continue?.",
  Upgrade: "Upgrade",
  Accounts: "Accounts",
  Account: "Account",
  Confirm: "Confirm",
  Devices: "Devices",
  IdentificationWorkflow: "Identification Workflow",
  ExternalCard: "External Card",
  Clean: "Clean",
  Options: "Options",
  NewIdentificationWorkflow: "New Identification Workflow",
  RelayState: "Relay State",
  InternalRelay: "Internal Relay",
  IORelay1: "I/O Relay 1",
  IORelay2: "I/O Relay 2",
  LockReleaseTime: "Lock Release Time",
  DoorOpenTimeout: "Door Open Timeout",
  Internal: "Internal",
  External: "External",
  DoorContactPosition: "Door Contact Position",
  DoorContactType: "Door Contact Type",
  NormallyOpen: "Normally Open",
  NormallyClosed: "Normally Closed",
  ExitButtonContactPosition: "Exit Button Contact Position",
  ExitButtonContactType: "Exit Button Contact Type",
  WiegandInputEnabled: "Wiegand Input Enabled",
  WiegandOutputEnabled: "Wiegand Output Enabled",
  WiegandFormat: "Wiegand Format",

  AddNewPermission: "Add New Permission",
  AddNew: "Add New",
  ThisRoleOnlyHas1ReadPermission: "This role has only 1 read permission",
  ConfirmRemove: "Confirm Remove",
  Permissions: "Permissions",
  ThisRoleHasAllPermissions: "This role already has all the permissions.",
  Close: "Close",

  //Scopes - Source Alocity Roles and Scopes spreadsheet
  accesslogs_r: "Read Access Logs",
  account_r: "Read Account information",
  account_w: "Update Account information",
  account_all: "Read or Update Account Information",
  accountall_r: "Read or Update Account information",
  accountsettings_r: "Read Account settings",
  accountsettings_w: "Update Account settings",
  accountpaymethod_r: "Read Account payment methods",
  accountpaymethod_w: "Update Account Payment Methods",
  accountsubscription_r: "Read Account Subscription information",
  accountsubscription_w: "Update Account Subscription",
  card_r: "Read Cards",
  card_w: "Update Cards",
  cardall_r: "Read all Cards",
  face_r: "Read enrolled Faces",
  face_w: "Update enrolled Faces",
  cloudkey_r: "Read Cloud Keys",
  cloudkey_w: "Update Cloud Keys",
  cloudkeyall_r: "Read all Cloud Keys",
  support_r: "Read Support Data",
  device_r: "Read Devices",
  device_w: "Update Devices",
  devicesettings_w: "Read Device Settings",
  deviceweb_w: "Initiate Device Support",
  deviceall_r: "Read all Devices",
  group_r: "Read Groups",
  group_w: "Update Groups",
  schedule_r: "Read Schedules",
  schedule_w: "Update Schedules",
  credential_r: "Read Credentials",
  role_r: "Read Roles",
  role_w: "Update Roles",
  site_r: "Read Sites",
  site_w: "Update Sites",
  timezone_r: "Read Time Zones",
  user_r: "Read Users",
  user_w: "Update Users",
  usersettings_r: "Read User settings",
  usersettings_w: "Update User settings",
  zone_r: "Read Zones",
  zone_w: "Update Zones",

  accesslogs_r_description: "This permission allows a user to read access logs.",
  account_r_description: "This permission allows a user to read account information.",
  account_w_description: "This permission allows a user to change account data.",
  account_all_description: "This permission allows the user to read all accounts.",
  accountall_r_description: "This permission allows a user to read all accounts.",
  accountsettings_r_description: "This permission allows a user to read the account's settings.",
  accountsettings_w_description: "This permission allows a user to update the account's settings.",
  accountpaymethod_r_description: "This permission allows a user to read the payment methods.",
  accountpaymethod_w_description:
    "This permission allows a user to update the account's payment methods.",
  accountsubscription_r_description:
    "This permission allows a user to read the account's subscription information.",
  accountsubscription_w_description:
    "This permission allows a user to update the account's subscription.",
  card_r_description: "This permission allows a user to read card information",
  card_w_description: "This permission allows a user to update cards.",
  cardall_r_description: "This permission allows a user to read all cards.",
  face_r_description: "This permission allows a user to view enrolled faces.",
  face_w_description: "This permission allows a user to update enrolled faces.",
  cloudkey_r_description: "This permission allows a user to read Cloud Keys.",
  cloudkey_w_description: "This permission allows a user to update Cloud Keys.",
  cloudkeyall_r_description:
    "This permission allows a user to read all the Cloud Keys for an organization.",
  support_r_description: "This permission allows a user to read data from Customer Support.",
  device_r_description: "This permission allows a user to read device data.",
  device_w_description: "This permission allows a user to update device information.",
  devicesettings_w_description: "This permission allows a user to update a device's settings.",
  deviceweb_w_description: "This permission allows a user to initiate Customer Support requests.",
  deviceall_r_description:
    "This permission allows a user to view information about all the devices for an account.",
  group_r_description: "This permission allows a user to view groups.",
  group_w_description: "This permission allows a user to update groups.",
  schedule_r_description: "This permission allows a user to view schedules.",
  schedule_w_description: "This permission allows a user to update schedules.",
  credential_r_description: "This permission allows a user to read credentials.",
  role_r_description: "This permission allows a user to view roles.",
  role_w_description: "This permission allows a user to update roles.",
  site_r_description: "This permission allows a user to read site information.",
  site_w_description: "This permission allows a user to update site information.",
  timezone_r_description: "This permission allows a user to view the timezones.",
  user_r_description: "This permission allows a user to read user information.",
  user_w_description: "This permission allows a user to update a user's information.",
  usersettings_r_description: "This permission allows a user to view a user's settings.",
  usersettings_w_description: "This permission allows a user to update a user's settings.",
  zone_r_description: "This permission allows a user to view zone information.",
  zone_w_description: "This permission allows a user to update zone information.",

  ConfirmRemoveZone: "Confirm Remove Zone",

  error_card_duplicated: "Error, card duplicated",
  Credentials: "Credentials",
  Device: "Device",
  Text: "Text",
  Valid: "Valid",

  Country: "Country",
  City: "City",
  State: "State",
  Expires: "Expires",
  Duplicated: "Duplicated",
  Download: "Download",
  Expire: "Expires",
  MacAddress: "MAC Address",
  DeviceSetup: "Device Setup",
  DeviceNumbersInvalid: "Device numbers are invalid",

  parameter_missing: "Error, there are missing parameters", //error by Stripe when there are missing parameters
  Review: "Review",
  Select: "Select",
  error_duplicated_work_flow_secuence: "Error, duplicated workflow sequence",
  ChargeToDefaultCard: "The total amount is going to be charged to the default card.",
  Success: "Success",
  Change: "Change",
  AddNewCloudkey: "Add new cloud key",
  AddCloudkey: "Add Cloud Key",
  List: "List",
  CaptureTheFace: "Capture the face",
  EnableCameraAccess: "Please, enable camera access",
  ThisUserIsNotScheduledToUseAnyDoors: "This user is not scheduled to use any doors",
  AddUsingADevice: "Add using a device",
  AddUsingWebCam: "Add using the webcam",
  CaptureTheFaceByDevice: "Capture the face using a device",
  ThisUserIsNotScheduledToUseAnyDoorsORThereAreNotConnectedDoors:
    "This user is either not scheduled to use any doors or there are not any connected doors",
  StandInFrontOfDevice: "Stand in front of the device",
  PublishUnknownUsers: "Publish Image Unknown Users",
  ON: "ON",
  OFF: "OFF",
  ControlTheAutomaticOpenSchedule: "Control the automatic open schedule",
  error_user_without_schedule: "This user is not scheduled to use any doors",
  error_not_connected_device_found: "There are not any connected doors",
  Local: "Local",
  Details: "Details",
  Recommended: "Recommended",
  EditName: "Edit Name",
  ThisGroupHasUsers: "This group has users, so remove the users first.",
  DoorDetails: "Door Details",
  AudioSettings: "Audio Settings",
  Microphone: "Microphone",
  Speaker: "Speaker",
  Volume: "Volume",
  OperationSent: "Operation sent",
  NetworkChangedSuccessfullyForDevice: "Network changed successfully for the door",
  FailedChangeNetworkForDevice: "Failed to change network for the door",
  error_importing_file_too_large: "Error, the size of the file is bigger than 2MB",
  Connection: "Connection",
  AddToAGroup: "Add to a Group",
  AddAUser: "Add a User",
  AddToAll: "Add to All",
  RemoveFromAll: "Remove from All",
  error_site_name_empty: "The Site name cannot be empty",
  error_zone_name_empty: "The Zone name cannot be empty",
  HolidaysSettingDescriptionText: "Add/Remove your holidays",
  AddHolidays: "Add Holidays",
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  Month: "Month",
  Day: "Day",
  EditHoliday: "Edit Holiday",
  error_holiday_not_created: "Holiday not created",
  error_holiday_not_deleted: "Holiday not deleted",
  error_invalid_date: "Invalid date",
  error_holiday_not_found: "Holiday not found",
  error_holiday_not_updated: "Holiday not updated",
  Search: "Search",
  AdvancedFilter: "Advanced Filter",
  AssignToAllDoors: "Assign to all doors in the site",
  ActivateAutomaticOpenDoor: "Open door automatically",
  Who: "Who",
  Where: "Where",
  AddToSchedule: "Add to a Schedule",
  SchedulesWithTheUser: "User Assigned to Schedules", //"Schedules with the User",
  ConfirmRemoveTheUserFromTheSchedule: "Confirm remove the user from the schedule",
  ScheduleName: "Schedule Name",
  Repeated: "Repeated",
  GroupName: "Group Name",
  RemoveFromGroupSchedule: "Remove it from the Group if you want to remove it from the schedule",
  AddManually: "Add manually",
  DoorWithoutWebAccess: "Door without permission to open by web",
  CaptureTheCardByDevice: "Capture the card using a device",
  PutTheCardOverTheDevice: "Put the card over the device",
  CardAddedForTheUser: "Card added for the user",
  FailAddCardForTheUser: "Failed to add card for the user",
  FacilityCodeNotAllowed: "Facility Code not allowed",
  CardFormatNotAllowed: "Card Format not allowed",
  ThisSiteHasDevicesDelete:
    "This site has doors, so change the doors from the site before deleting the site",
  error_minimum_number_of_zones: "You cannot delete all zones",
  ThisSiteHasOnly1Zone:
    "This site has only 1 zone. You have to delete the Site if you want to delete the zone.",
  ProceedToDeleteTheSite: "Proceed to delete the Site",
  PublishNonLiveUsers: "Publish Image Spoof Attacks",
  EnableEmailNotification: "Enable email notification",
  TotalAccessLog: "Total access logs",
  Reports: "Reports",
  TotalUsers: "Total users",
  TotalSites: "Total sites",
  TotalDoors: "Total doors",
  TotalSchedules: "Total schedules",
  PortalAccessUsers: "Portal access users",
  AccessLogPerDayChart: "Access logs per day",
  RankingDevicesChart: "Ranking devices",
  RankingUsersChart: "Ranking users",
  AccessLogCardLegend: "last 24 hours",
  AccessLogPerType: "Access logs per type",
  NoDataChart: "Not enough data to graph",
  DevicesConnected: "Doors connected",
  DoorsPerSite: "Doors per site",
  ScheduleStatus: "Schedule status",
  UserAccessPerDoor: "Access logs user per door",
  ChartTitle: "Charts",
  SchedulesWithTheDoor: "Schedules with the Door",
  SchedulesWithTheIntercom: "Schedules with the Intercom",
  SchedulesWithTheLock: "Schedules with the Wireless Lock",
  RemoveFromZoneSchedule:
    "Move the door to another Zone if you want to remove it from the schedule. In the tab 'Zone' you can do it.",
  ConfirmRemoveTheDoorFromTheSchedule: "Confirm removing the door from the schedule",
  GoToTheZone: "Go to the zone",
  Alarm: "Alarm",
  AccessLogDescription: "This contains complete information and analytics about the access logs",
  error_device_assign_same_location:
    "Error, you are trying to change the device to the same location",
  UserActive: "Active Users",
  Granted: "Granted",
  NotGranted: "Not granted",
  Credential: "Credential",
  DoorControl: "Door Control",
  LiveEventsFeed: "Live Events Feed",
  TotalEvents: "Total Events",
  DeniedNotScheduled: "Denied (Not Scheduled)",
  DeniedUnknownUser: "Denied (Unknown User)",
  DeniedDeviceInLockdown: "Denied (Door In Lockdown)",
  DeniedNonLiveUser: "Denied (Spoof Attack)",
  DeniedCardFormatError: "Denied (Error in Card Format)",

  UnlockedAlready: "Unlocked Already",
  Denied: "Denied",
  Picture: "Picture",
  TheDoorDoesntHaveTheWebCredential: "The door does not have the WEB credential",
  Copied: "Copied",
  CanNotCopyText: "Could not copy the text, do it manually",
  OpenVideo: "Open Video",
  Video: "Video",
  error_invalid_access_result_for_save_images: "We are not registering this type of access",
  DoorVideo: "Door Video",
  LostConnectionWithTheDoor: "Lost connection with the door",
  Failed: "Failed",
  PublishAuthenticatedUsers: "Publish Image Authenticated Users",
  EnrollFaceViaCard: "Enroll Face",
  SendPushNotifications: "Send Push Notifications",
  VideoDrawFocusArea: "Video Draw Focus Area",
  VideoDrawFaceStats: "Video Draw Face Stats",
  VideoResolution: "Video Resolution",
  Pin: "PIN",
  EnterPinSentToYou: "Enter PIN sent to you",
  Slidetounlockdoor: "Slide to unlock door",
  error_invalid_pin: "Invalid PIN",
  UserPicture: "User Picture",
  GuestPicture: "Visitor Picture",
  ChangeToUserPicture: "Change to User Picture",
  EventPicture: "Event Picture",
  ChangeToEventPicture: "Change to Event Picture",
  EnableSmsNotification: "Enable SMS Notifications",
  Low: "Low",
  Medium: "Medium",
  High: "High",
  Extreme: "Extreme",
  error_cannot_delete_site_with_devices: "You cannot delete the site because contains devices",
  UnsupportedCountryCode: "Unsupported phone country code.",
  SupportAreaDescriptionText: "Review communication with Customer Support representatives.",
  SupportAccess: "Support Access",
  CustomerSupportRepresentative: "Customer Support Representative",
  GiveAccess: "Give Access",
  Reference: "Reference",
  Initiated: "Initiated",
  SupportName: "Support Name",
  SupportEmail: "Support Email",
  SupportPhone: "Support Phone",
  ConnectingLiveView: "Connecting Live View",
  LiveView: "Live View",
  error_group_already_exists: "That group already exists",
  ProvisioningFaceInvitation: "Selfie enrollment invitation",
  ProvisioningFace: "Selfie Enroll",
  ValidatePin: "Validate PIN",
  AddProvisioningFaceInvitation: "Add Selfie Enrollment Invitation",
  CannotDeleteInactiveProvisioningFaceInvitation:
    "Can not delete inactive provisioning face invitation",
  CreateProvisioningAlert: "Adding a new invitation will invalidate the previous one.",
  error_creating_provisioning_invitation: "Error to create invitation",
  error_failed_to_send_sms: "Failed to send SMS",
  error_user_name_required: "User name required",
  error_provisioning_expiration_already_passed:
    "The selfie enroll link has expired, please contact your administrator.",
  error_start_datetime_before_end: "The start datetime must be less than expiration",
  error_provisioning_invitation_not_found: "Invitation not found",
  error_not_valid_date_range: "Invalid date range",
  error_not_deactivated: "Invitation not deactivated",
  error_invalid_provisioning_pin: "Invalid provisioning PIN",
  error_phone_number_used_on_same_account: "The phone number is already used on the same account.",
  EnableLivenessCheck: "Enable Liveness Check",
  ProvisioningKeySuccess: "Your photo has been successfully uploaded!",
  ProvisioningKeyFail:
    "Maximum number of attempts have been reached. Request another provisioning invitation.",
  CardORKeyFOB: "Card or Key FOB",
  CreateAccount: "Create Account",
  AddAccount: "Add Account",
  error_provisioning_max_pin_attempts: "Max PIN Attempts Exceeded",
  error_cloud_key_max_pin_attempts: "Max PIN Attempts Exceeded",

  // Captcha
  captcha_verification_error:
    "We have detected unusual activity, you need to try again or change your browser.",
  error_captcha_token_required: "Captcha token is required",
  Send: "Send",
  AddSelfieEnrollmentDescription:
    "The self enrollment / selfie link will be sent to the following email and mobile number registered for this user",
  MobileNumber: "Mobile Number",
  AddSelfieEnrollmentTimePeriod: "Set the valid time frame for this selfie link",
  SelfieEnrollDescrption:
    "This self enrollment link will allow you to take a selfie picture of your face, in order to use your face as a credential to gain instant and secure access to the facility/building.",
  SelfieEnrollmentProvidePin:
    "To proceed to the next step, enter the PIN provided in the email or text.",
  Enter: "Enter",
  SelfieEnrollStepInstruction:
    "For optimal performance when taking a selfie of your face follow these steps",
  SelfieEnrollStep1: "Use a well-lit room",
  SelfieEnrollStep2: "Preferably, use a solid wall behind you.",
  SelfieEnrollStep3: "Do not take selfie with sunlight behind you.",
  SelfieEnrollStep4: "Make sure there are no other faces in the selfie photo.",
  SelfieEnrollStep5: "Do not use glasses (when enrolling only)",
  SelfieEnrollStep6: "Clear hair away from face.",
  SelfieEnrollReadyToTakePhoto: "When you're ready to take the photo, click below",
  AnalyzingThePhoto: "Analyzing the photo, please wait...",
  UnableProcessThePhoto: "We were unable to process the photo. Please try again.",
  SelfieEnrollReady:
    "You are now ready to gain instant and secure access to the building/facility using your face.",
  ImageReadFailed: "Failed to Image Read",
  ImageConversionFailed: "Failed to Image Conversion",
  SDKWrapperNotInit: "SDK Wrapper Not Init",
  DetectFacesFailed: "Failed to Detect Faces",
  CalculateFacesQualityFailed: "Failed to Calculate Faces Quality",
  GetMostProminentFacesFailed: "Failed to Obtain Most Prominent Faces",
  DiscardFacesProminenceFailed: "Failed to Discard the Most Prominent Faces",
  DiscardFacesAcceptabilityFailed: "Failed to Discard Face Due to Low Acceptability",
  DiscardFacesQualityFailed: "Failed Because Face Quality is Low",
  DiscardFacesWayTooSmallFailed: "Failed Because the Face is Small",
  DiscardFacesTooSmallFailed: "Failed Because the Face is Small",
  DiscardFacesTooSmall: "Failed Because the Face is Small",
  CalculateFacesLandmarksFailed: "Failed to Calculate Faces Landmarks",
  CalculateFacesEmbeddingFailed: "Failed to Calculate Faces Embedding",
  CalculateFrontalityFailed: "Failed to Calculate Frontality",
  GetMostProminentFacesSecondFailed: "Failed to Obtain Most Prominent Faces",
  NotFrontFacing: "Not Front Facing",
  SelfieEnrollmentTerms: "By taking enrollment photo, you agree to the following",
  termsandconditions: "terms and conditions",
  LetsGetStarted: "Let`s get started",
  WelcomeToAlocityAccessIntelligence: "Welcome to Alocity Access Intelligence!",
  WelcomeDescription:
    "You’re on your way to modernizing your door security. To buy Alocity readers and subscriptions for your account click below.",
  AlocityAccessIntelligenceSubscription: "Alocity Access Intelligence Subscription",
  F3D100IntelligentReader: "F3D100 intelligent reader",
  CloudManagementSoftware: "Cloud management software",
  LiveVideoStreaming: "Live video streaming",
  FreeUpdates: "Free updates",
  WarrantyLifetimeService: "Warranty for the lifetime of the service",
  SelectYourSubscription: "Select your subscription",
  monthly: "monthly",
  BilledYearly: "Billed yearly",
  YearSubscription: "Year Subscription",
  HowManyDevicesDoYouWant: "How many devices do you want?",
  Continues: "Continues",
  ContactUsForThisAmount: "Please, contact us directly for device orders of this size",
  error_company_address_line1_is_required: "The address line 1 of the company is required",
  error_company_city_is_required: "The city location of the company is required",
  error_company_state_is_required: "The state location of the company is required",
  error_company_country_is_required: "The country location of the company is required",
  error_company_postal_code_is_required: "The postal code of the company is required",
  error_company_country_is_invalid: "The company country is invalid",
  error_company_state_is_invalid: "The company state location is invalid",

  error_shipping_address_line1_is_required: "The address line 1 for shipping is required",
  error_shipping_city_is_required: "The city location for shipping is required",
  error_shipping_state_is_required: "The state location for shipping is required",
  error_shipping_country_is_required: "The country location for shipping is required",
  error_shipping_postal_code_is_required: "The postal code for shipping is required",
  error_shipping_country_is_invalid: "The shipping country is invalid",
  error_shipping_state_is_invalid: "The shipping state location is invalid",
  error_cannot_fillShipping_from_account_address:
    "Failed to update the shipping address from the company address",
  ShippingAddress: "Shipping Address",
  Pay: "Pay",
  Opening: "Opening",
  Loading: "Loading",
  Waiting: "Waiting",
  error_device_not_connected: "The device is disconnected",
  Unlocking: "Unlocking",
  ThanksForYourPayment: "Thanks you for your payment!",
  ReceiptThisTransaction:
    "A receipt for this transaction has been sent via email for your records.",
  UnableToProcessPayment: "Unable to Process Payment",
  WeAreSorryUnableToProccess:
    "We're sorry, we were unable to process your payment. Please try again later.",
  IfYouNeedHelp: "If you need help, please contact us at",
  OK: "OK",
  Buy: "Buy",
  error_creating_checkout_session: "Error creating the checkout session",
  error_creating_new_payment_provider_user: "Error creating a new user in our payment provider",
  error_price_is_required: "The price ID is required",
  error_qty_is_required: "The quantity is required",
  error_calculating_shipping: "There is an error calculating the shipping",
  error_no_other_user_on_site:
    "A site cannot be removed from a user if they are the only one on that site",
  CompanyAddress: "Company Address",
  SameCompanyAddress: "Use Company Address as Shipping Address",
  ErrorSameAsCompanyAddress: "First add the company address",
  FaceOnCard: "Face on Card",
  error_conflict_identification_workflow:
    "Face On Card cannot be used with the Card or External Card workflows.",
  WorkflowStartsWith:
    "A workflow that starts with that option already exists. If you want to start a new workflow with that option, please delete the existing workflow.",
  error_invalid_credential_type: "Error Invalid Credential Type",
  ClickToUnlock: "Click to unlock door",
  error_campaign_not_all_devices_updated: "Not all devices are updated to the latest firmware",
  DeviceUpdate: "Device Update",
  TextUpdateEnhancementsCampaign:
    "Hitting the 'Update' button will send an update to all connected devices on your account. Please ensure that all your devices are connected so that they may receive the update.",
  DeviceUpdateCampaign: "Device Update Campaign",
  Enterpinsentintheemail: "Enter PIN sent in the email",
  Clicktounlockdoor: "Click to unlock door",
  ScheduleUpdate: "Schedule Device Update Before",
  error_account_campaign_not_found: "Account Campaign Not Found ",
  Integration: "Integration",
  IntegrationDescription: "Integrations enable you to make third-party applications.",
  APIKeys: "API Keys",
  StandardKeys: "Standard Keys",
  StandardKeysDescription: "These keys will allow you to authenticate API requests.",
  LearnMore: "Learn more",
  Token: "Token",
  RevealKey: "Reveal Key",
  HideKey: "Hide Key",
  LastUsed: "Last Used",
  RollKey: "Roll Key",
  ViewRequestLogs: "View Request Logs",
  ErrorMicrophonePermissionDenied:
    "You should enable microphone permissions if you want to send audio.",

  // ApiKeys
  error_access_log_not_found: "Access Log not Found",
  ConfirmPasswordToAccessKeys: "To access the key, please confirm your password.",
  Key: "Key",
  AdditionalAuthenticationRequired: "Additional authentication required",
  ConfirmPasswordToContinue: "To continue, please enter your password.",
  AddFirstAPIKey: "Create the first API key",
  UseOfApiKey: "Use of API Key",
  UseOfApiKeyDescription: "To get a token to authorize your API calls, use this endpoint.",
  UseOfApiKeyDescription2: "You can obtain more information here: ",
  error_identity_user_login: "Error: Wrong identity password",
  Copy: "Copy",

  // Webhooks
  Webhooks: "Webhooks",
  AddWebhooks: "Add Webhooks",
  AddWebhook: "Add Webhook",
  WebhookInfo: "Webhook Info",
  URL: "URL",
  SelectEvents: "Select Events",
  SelectedEvents: "Selected Events",
  SelectEventsToSend: "Select Events to Send",
  SelectAllEventsOf: "Select All Events Of",
  SearchEvents: "Search Events",
  AddEvents: "Add Events",
  SelectAllEvents: "Select All Events",
  Listening: "Listening",
  Secret: "Secret Key",
  RevealSecret: "Reveal",
  EventsUpdated: "Events Updated",
  error_account_webhook_url_in_use: "URL is already in use",
  error_webhook_not_found: "Webhook not found",

  ErrorWithTheServiceToValidateAddress:
    "Error with the service to validate the address. Please, try again",
  AddressNotFound: "Address not found, Please, verify it",
  Unlock: "Unlock",
  error_reading_max_on_wall_capacity: "Error reading the video intercom setting capacity",
  error_wall_intercom_capacity_is_full: "Video itercom capacity full",
  error_device_already_on_wall: "Device Already On Wall",
  error_device_already_off_wall: "Device Already Off Wall",
  AlreadyOnTheWall: "Already On The Wall",
  ErrorDetails: "Error Details",
  AllowMicrophone: "Allow to use your microphone",
  AlocityNeedAccessToYourMic:
    "Alocity needs access to your microphone so that the person in front of the device can hear you.",
  MicrophoneBlocked: "Microphone is blocked",
  ClickTheCameraIcon: "Click the camera blocked icon",
  inYourBrowserAddressBar: "in your browser`s address bar",
  Retry: "Retry",
  CanNotUseMicro: "Can`t use your microphone",
  YourBrowserMightNotHaveAccess:
    "Your browser might not have access to your microphone. To fix this problem, open",
  AnotherApplicationUsingYourMic:
    "Another application (Zoom, Webex) or browser tab (Google Meet, Messenger Video) might already be using your microphone. Please turn off other microphone before proceeding.",
  DoorRinging: "Door Ringing",
  Check: "Check",
  VideoIntercom: "Video Wall",
  VideoIntercomSidebar: "Video Wall",
  DeviceSelection: "Device Selection",
  Live: "Live",
  error_user_with_same_phone_number: "This phone number is already in use",
  YouDontHavePermissionToSetThisRole: "You do not have permission to set this role",
  ReissueAccessKey: "Reissue Access Key",
  Visitors: "Visitors",
  Visitor: "Visitor",
  AddVisitor: "Add Visitor",
  TextAddVisitorStep1: "Visitor Info",
  TextAddVisitorStep2: "Doors Access",
  ArrivalDate: "Arrival Date",
  DepartureDate: "Departure Date",
  PleaseSelectTheVisitors:
    "Please select the schedules to bind to the new visitor. By doing so, you will provide access to doors at the specified times.",
  Reuse: "Reuse",
  VisitorsSchedules: "Visitor's Schedules",
  VisitorScheduleIsEmpty:
    "There are not any visitor' schedules created if you want to create the user without schedule click this",
  CreateAVisitorWithoutSchedule: "Create a visitor without schedule",
  CheckVisitorsSchedules: "Review Visitor's Schedules",
  VisitorInfo: "Visitor Info",
  SchedulesWithTheVisitor: "Visitor Assigned to Schedules", //Schedules with the Guest
  RequiredTheEmailOrPhoneIsEmpty: "Required an email or phone number. Please, add these values.",
  VisitorsInTheSchedule: "Visitors in the Schedule",
  ThereAreNotVisitorsToRemove: "There are not visitors to remove",
  ThereAreNotVisitorsToAdd: "There are not visitors to add",
  ConfirmRemoveVisitorFromTheSchedule: "Confirm Remove Visitor from the Schedule",
  ConfirmAddAllVisitorsToTheSchedule: "Confirm Add All Visitors to the Schedule",
  ConfirmRemoveAllVisitorsFromTheSchedule: "Confirm Remove All Visitors from the Schedule",
  VisitorsThereNotInTheSchedule: "Visitors that are not in the schedule",
  AddVisitorSchedule: "Add Visitors' Schedule",
  VisitorScheduleSettingDescriptionText: "Add/Remove your visitor's schedules",
  ConfirmRemoveTheVisitorFromTheSchedule: "Confirm remove the visitor from the schedule",
  VisitorSchedulesWithTheDoor: "Visitor's Schedules with the Door",
  VisitorSchedulesWithTheIntercom: "Visitor's Schedules with the Intercom",
  VisitorSchedulesWithTheLock: "Visitor's Schedules with the Wireless Lock",

  error_guest_not_created: "Failed to create the visitor",
  error_guest_not_deleted: "Failed to delete the visitor",
  error_guest_email_already_used_on_same_account: "The email is already used on the account",
  error_invalid_dates: "Invalid dates",
  error_guest_not_found: "Visitor not found",
  error_guest_not_updated: "Visitor not updated",
  AssignedAlready: "Assigned already",
  AddUserToASchedule: "Add the user to a schedule",
  AddVisitorToASchedule: "Add the visitor to a schedule",
  Workflows: "Workflows",
  AddWorkflow: "Add Workflow",
  Trigger: "Trigger",
  wf_personofinterest_observed: "Person of interest observed",
  wf_accesslog_unauthorized: "Unauthorized Access Attempt",
  wf_door_keepopen: "Door Left Open",
  wf_door_offline: "Door Online Status Changed",
  wf_door_openremote: "Door Opened Remotely",
  wf_door_settingschanged: "Door Settings Changed",
  wf_door_forceopen: "Door Forced Open",
  wf_accesslog_authorized: "Authorized Access",
  wf_lockdown_start: "Lockdown Plan",
  wf_lockdown_end: "End Lockdown Plan",
  wf_sensor_triggered: "Sensor Triggered",
  wf_ioerror_detected: "IoT Error Detected",
  wf_mobile_accessnotgranted: "Mobile Access Not Granted",

  ActiveFrom: "Active From",
  ActiveTo: "Active To",
  ActorTypes: "Action Types",
  ConfirmActivation: "Confirm Activation",
  AccessLogTypes: "Access log types",
  TitleTextAddWorkflowOption1: "Select the trigger for the workflow.",
  Triggers: "Triggers",
  GeneralLockDown: "General Lockdown",
  GeneralSoundAlarm: "General Sound Alarm",
  LockDownDoor: "Lockdown Door",
  SendEmail: "Send Email",
  SendSMS: "Send Text Message",
  SoundDoorAlarm: "Sound Door Alarm",
  TitleTextAddWorkflowOption2: "Select the doors for this workflow",
  SelectDoorThatYouWantListening: "Select the doors that you want be listening",
  SelectTypeAccess: "Select the type of access",
  TitleTextAddWorkflowOption3: "Choose the time range for this workflow.",
  Time24hours: "24 hours",
  TitleTextAddWorkflowOption4: "Select one or more actions for this workflow.",
  TitleTextAddWorkflowOption5: "Write a description that will help others understand this workflow",
  WorkflowInfo: "Workflow Detail",
  ActiveTime: "Active Time",
  Editing: "Editing",
  Avatar: "Avatar",
  error_deleting_cloud_key: "Deleting cloud keys for this user has failed.",
  error_not_active_yet: "Visitor is not currently active.",
  Definition: "Definition",
  OR: "OR",
  ItIsForLockdown: "This is a lockdown card",
  LockdownPlans: "Lockdown Plans",
  LockdownPlans2: "Lockdown Plans",
  Revert: "Revert",
  Disabled: "Disabled",
  Reverted: "Reverted",
  error_lockdown_plan_not_enabled: "The lockdown plan is not enabled on this account.",
  error_workflow_invalid_trigger_actor: "Invalid action(s) found for this trigger.",
  error_workflow_notfound: "The workflow was not found.",
  error_workflow_update_failed: "The workflow update failed.",
  error_account_workflow_exists: "A workflow of this trigger type already exists.",
  error_external_card_can_not_lockdown: "An external card cannot be used as a lockdown card.",
  error_workflow_code_notfound: "Workflow trigger code was not found.",
  error_allow_unencrypted_cannot_lockdown:
    "You cannot register a lockdown card if you are allowing unencrypted cards.",
  error_guest_user_cannot_enroll_lockdown_card: "Visitors cannot have lockdown cards.",
  error_workflow_not_authorized_user: "You are not authorized to start/stop a lockdown.",
  error_expiration_already_passed: "The expiration date already passed.",
  ErrorTheLockdownActorNotFound:
    "The workflow action code 'Lockdown Door' or 'General Lockdown' was not found.",
  LockdownStarted: "Lockdown Started",
  LockdownEnded: "Lockdown Ended",
  TitleTextAddWorkflowOptionTargetUserGroup: "Select the target users of this workflow",
  TitleTextAddWorkflowOptionTargetDoors: "Select the target doors of this workflow",
  TargetDoors: "Target Doors",
  TargetZones: "Target Zones",
  TargetSites: "Target Sites",
  TargetUsers: "Target Users",
  TargetGroups: "Target Groups",
  ActionTargets: "Action Targets",
  TitleTextAddWorkflowOptionLockDownPlan: "Select the users allowed to trigger a lockdown.",
  TitleTextAddWorkflowOptionExpiresOnLockdownPlan: "(Optional) Select the expiration time.",
  UsersWillTrigger: "Users will trigger",
  GroupsWillTrigger: "Groups will trigger",
  error_card_already_exists: "A card with this facility code and card number already exists.",
  WorkflowAreaDescriptionText:
    "Set up workflows to automate tasks and sending notification to users after a specified event occurs.",
  AllowUnencryptedCards: "Allow Unencrypted Cards",
  TextExplainReasonNotLockdownCardWithDefault:
    "Lockdown Cards cannot be made when unencrypted cards are allowed in the account settings.",
  EnableLockdownPlan: "Enable Lockdown Plan",
  LockdownPlanDuration: "Lockdown Plan Duration (minutes)",
  HelperLockdownPlanDuration: "The plan will be active until someone stops it.",
  CardEndianType: "Card Endian Type",
  Little: "Little",
  Big: "Big",
  CardOffsetNumber: "Card Number Bit Length",
  CardOffsetFacility: "Card Facility Code Bit Length",
  CardOffsetNumberStart: "Card Number Starting Bit",
  CardOffsetFacilityStart: "Card Facility Code Starting Bit",
  TriggerTargets: "Trigger Targets",
  AddLockdownPlanCard: "Add a lockdown card",
  error_cannot_create_lockdown_card_for_user: "This user is not authorized to have a lockdown card",
  error_lockdown_workflow_required_make_card:
    "A lockdown workflow is required to make a lockdown card",
  error_device_no_cloud_key_access: "This device does not allow cloud key access",
  error_workflow_missing_trigger_targets: "Missing the triggers targets",
  GeneralLockdownActive: "General lockdown is active",
  Duration: "Duration",
  error_workflow_delete_failed: "Failed to delete workflow",
  error_workflow_missing_trigger_targets_1: "No user/group trigger targets found",
  error_workflow_missing_trigger_targets_2: "No door trigger targets found",
  error_workflow_missing_trigger_targets_3: "No sensor trigger targets found",
  ErrorMissingTypeAccess: "Missing the type of access",
  ErrorMissingActions: "Missing the actions",
  error_workflow_missing_action_targets_1: "No user/group action targets found",
  error_workflow_missing_action_targets_2: "No door action targets found",
  error_workflow_missing_action_targets_3: "No sensor action targets found",
  MicrophoneInUseBy: "Microphone in use by",
  ErrorGenericWebRTC: "We can not connect to the device or there are 10 connections already",
  Reconnect: "Reconnect",
  ExactEmail: "Email (must be exact)",
  error_group_has_assigned_users: "The group still has some users.",
  error_user_already_on_group: "One of the users is already in that group.",
  PublishAccesslogVideoEvents: "Publish access log video events",
  WatchVideoEvent: "Watch Video Event",
  ThereIsnoEventVideo: "There is no event video",
  TheEventVideoExpire: "The event video expired",
  EventVideo: "Event Video",
  Started: "Started",
  error_video_notfound: "Video not found in the cloud",
  Share: "Share",
  ExpirationInMinutes: "Expiration in minutes",
  Contact: "Contact",
  GreaterThan5: "Greater Than 5",
  LessThan720: "Less than 720",
  Error_MEDIA_ERR_NETWORK: "A network error occurred while fetching the video.",
  Error_MEDIA_ERR_SRC_NOT_SUPPORTED: "The video is missing or the authorization expired.",
  YouChangedYourEmailTo: "Sign in again because you changed your email to",
  EventHistory: "Event History",
  EventHistoryDescription: "You can search all the events happening at your doors",
  TheDeviceIsUploadingTheVideo: "The device is uploading the video.",
  TheSettingToSaveTheVideoIsOff: "The setting to save the video is off.",
  error_provisioning_max_attempts:
    "Maximum number of attempts have been reached. Request another provisioning invitation.",
  error_one_face_required: "The system require 1 face",
  error_mask_detected: "Failed, a mask was detected",
  TheLinkIsInvalid: "The link is invalid",
  NFCTag: "NFC Tag",
  EnableNFCTagRead: "Enable Read NFC Tag",
  SelectTheVisitorHostDescription: "Select the user that is going to be the host of the visitor",
  UserCreatedDate: "User Created Date",
  GuestCreatedDate: "Visitor Created Date",
  Host: "Host",
  MobileCredential: "Mobile Credential",
  error_user_no_mobile: "The user does not have mobile credential",
  PublishAccesslogAuthenticatedVideoEvents: "Publish Video Authenticated Users",
  PublishAccesslogUnknowUserVideoEvents: "Publish Video Unknown Users",
  PublishAccesslogSpoofVideoEvents: "Publish Video Spoof Attack",
  AddFace: "Add Face",
  FaceEnrollStepInstruction:
    "For optimal performance when taking a photo of the face follow these steps",
  FaceEnrollStep1: "Use a well-lit room",
  FaceEnrollStep2: "Preferably, use a solid wall behind the user.",
  FaceEnrollStep3: "Do not take photo with sunlight behind the user.",
  FaceEnrollStep4: "Make sure there are no other faces in the photo.",
  FaceEnrollStep5: "The user can not use glasses (when enrolling only)",
  FaceEnrollStep6: "Clear hair away from face.",
  FaceEnrollReadyToTakePhoto: "When you're ready to take the photo, click below",
  AddMobileCredential: "Add Mobile Credential",
  RefreshMobileCredential: "Refresh the Mobile Credential",
  Refresh: "Refresh",
  WirelessConnection: "Wireless Connection",
  EnableHandsFree: "Enable Hands Free",
  DayOfWeek: "Days of the Week",
  Days: "Days",
  Catalog: "Catalog",
  CatalogDescription: "Create a directory where people can contact users.",
  AddEntry: "Add Entry",
  DisplayName: "Display Name",
  AddDirectoryEntry: "Add Directory Entry",
  Doorbell: "Doorbell",
  DeviceDisconnectedCanNotNotify:
    "This device is currently offline and cannot send the notification. Please try again later.",
  error_catalog_not_found: "Catalog not found",
  error_account_catalog_update_error: "Catalog no updated",
  error_account_add_catalog: "Catalog no added",
  error_account_catalog_delete_error: "Failed the deletion",
  error_device_under_lockdown: "Failed, the door is under lockdown",
  Contain8Characters: "- Contain at least eight characters",
  Contain1Number: "- Contain at least 1 number",
  Contain1Uppercase: "- Contain at least 1 uppercase letter",
  error_device_hands_free_disabled: "Failed, device hand free disabled",
  DNSServer: "DNS Server",
  TheLastNumberCannotByZero: "The last number cannot be zero",
  TheFirstNumberGreaterThan128: "The first number >= 128 ",
  WebRTCEnabled: "Enable Live Video",
  WebRTCAudioEnabled: "Enable Live Video Audio",
  RTSPSettings: "RTSP Settings",
  error_device_webrtc_disabled: "Live connection is disabled for this device",
  LiveVideoAudioDisabled: "Live video audio is disabled for this device",
  error_last_site: "Cannot delete the last site on an account",
  error_account_catalog_user_has_already: "The user already has a catalog entry",
  ExpirationDateCannotBeInThePast: "Expiration date cannot be in the past",
  error_provisioning_face_matched_different_user: "Your face matches the face of another user",
  error_card_lost_or_stolen:
    "This card number and facility code combination is marked as lost or stolen and is no longer usable",
  ReportLostOrStolen: "Report lost or stolen",
  ThisOperationPermantlyDelete:
    "This operation will permenantly delete that card and make it so that the facility code and card number combination cannot be used again. Type your password to confirm.",
  DeletedPermanently: "Deleted permenantly",
  AddSensor: "Add Sensor",
  DeviceSerialNumber: "Door Serial Number",
  SensorType: "Sensor Type",
  Relay: "Relay",
  Switch: "Switch",
  SensorInfo: "Sensor Info",
  error_sensor_not_found: "Failed, sensor not found",
  error_creating_sensor: "Error creating the sensor",
  error_updating_sensor: "Error updating the sensor",
  error_deleting_sensor: "Error deleting the sensor",
  error_max_reached:
    "Maximum allowed sensors reached in this door, you cannot add more than 10 sensors",
  RelayAndSwitch: "Relay and Switch",
  GetURL: "Show the URL",
  Zigbee: "Zigbee",
  SelectZigbeeContact: "Select Zigbee Contact",
  HelpZigbeeAppearList: "You have to enroll a Zigbee contact to this Door to appear in this list",
  error_null_access_control_settings:
    "Contact sensor cannot be empty when sensor position is Zigbee",
  error_null_exit_button: "Exit button cannot be empty when sensor position is Zigbee",
  error_null_contact_sensor: "Door settings cannot be null",
  ZigbeeRelay: "Zigbee Relay",
  SelectZigbeeSwitch: "Select Zigbee Switch",
  error_sensor_log_artifact_not_image: "This artifact is not an image",
  error_sensor_log_artifact_not_video: "This artifact is not a video",
  error_sensor_log_artifact_not_found: " Artifact not found",
  CaptureImage: "Capture Image",
  CaptureVideo: "Capture Video",
  IssueSensorCommand: "Issue Sensor Command",
  SelectTheSensorsWillTrigger: "Select the sensors that are going to trigger the workflow",
  TitleTextAddWorkflowOptionTargetSensors: "Select the target sensors of this workflow",
  TriggerSensors: "Trigger Sensors",
  TargetSensors: "Target Sensors",
  Paired: "Paired",
  SelectTypeOfZigbee: "Select the type of Zigbee sensor you would like to add",
  DoorWindowContactSensor: "Door/Window Contact Sensor",
  TrackEveryTimeDoorOpened: "Tracks every time a door/window is opened or left ajar.",
  AssociateVideoFromSelectedCamerasDoorOpened:
    "Associate video from selected cameras when the door/window is opened.",
  CreateCustomizedWorkflowsToTriggerEvents:
    "Create customized workflows to trigger events, alarms, and notifications.",
  MotionSensor: "Motion Sensor",
  TractEveryTimeMotion: "Tracks every time motion is detected.",
  AssociateVideoFromSelectedCameras:
    "Associate video from selected cameras when motion is detected.",
  ConfigureAsAutomaticRex: "Configure as an automatic REX (request to exit)",
  CreateCustomizedWorkflowsToTrigger:
    "Create customized workflows to trigger events, alarms, and notifications.",
  WirelessRelay: "Wireless Relay",
  SendOpenCommandWhen:
    "Sends open command when a user is authenticated on the alocity F3D100 reader.",
  EliminateTheNeedsToRunWires: "Eliminates the needs to run wires.",
  ComunicationIsEncryptedAndSecure: "Communication is encrypted and secure.",
  PushPanicExitButton: "Push / Panic / Exit Button",
  CustomizeTheButtonTrigger: "Customize the button to trigger various methods:",
  REXRequestToExit: "- REX (Request to Exit).",
  PanicICaseOfEmergency: "- Panic, in case of emergency notify administrators and authorities.",
  LockdownCreatePlans: "- Lockdown, create plans to instantly lockdown doors.",
  OpenUseAsRemoteToOpenDoor: "- Open, use as a remote to open door",
  FollowTheStepsBelowToAddTheZigbeeSensor: "Follow the steps below to add the Zigbee sensor:",
  AddSensorStep1:
    "1. Select the alocity door reader that will be used to pair with the Zigbee sensor.",
  AddSensorNote1: "Note: The sensor and the alocity reader must be within 30-5 feet in range.",
  AddSensorStep2: "2. Place the Zigbee sensor in pairing mode.",
  AddSensorNote2: "Note: Follow the pairing instructions from the zigbee sensor manufacturer.",
  AddSensorStep3: "3. Click the Connect Now button to start the pairing process.",
  AddSensorNote3:
    "Note: Once the alocity device starts searching for sensors, it will start blinking green.",
  ConnectNow: "Connect Now",
  SearchingDots: "Searching ...",
  Pairing: "Pairing",
  PleaseAddNameDescriptionSensor:
    "Please, add a name and description to help you to identify your sensor:",
  PairedDeviceSerialNumber: "Paired Device Serial Number",
  Image: "Image",
  SensorLogs: "Sensor Logs",
  error_sensor_in_use: "Sensor is being used as a contact, exit button, or relay",
  error_sensor_log_not_found: "Sensor log not found",
  LightSwitch: "Light Switch",
  TurnLightsOnOffRemotely: "Turn lights on/off from the application and remotely.",
  SetSchedulesAutomaticallyLightsOnOff: "Set schedules to automatically turn lights on/off",
  CreateCustomizedWorkflowsTurnLight:
    "Create customized workflows (e.g. turn lights on if an alarm or motion is triggered after-hours",
  SirenAlarm: "Siren / Alarm",
  SetWorkflowsToTriggerTheSirenWhenACriticalEventHappens:
    "Set workflows to trigger the siren when a critical event happens (e.g. Door is forced open, motion is detected after-hours",
  Artifacts: "Artifacts",
  Images: "Images",
  Videos: "Videos",
  ReadMore: "Read More",
  MainServicesAgreement: "Main Services Agreement",
  BiometricPrivacyConsent: "Biometric Privacy Consent",
  Legal: "Legal",
  Battery: "Battery",
  SignalIntensity: "Signal Intensity",
  error_max_internal_exit_button: "You cannot have more than 1 internal exit button",
  error_max_external_exit_button: "You cannot have more than 1 external exit button",
  ExitButtons: "Exit Buttons",
  error_max_exit_button: "You cannot have more than 6 exit buttons",
  HelpZigbeePushButtonAppearList:
    "You have to enroll a Zigbee push button or motion sensor to this Door to appear in this list",
  HelpZigbeeRelayAppearList:
    "You have to enroll a relay Zigbee sensor to this Door to appear in this list",
  error_zigbee_exit_button_used_more_than_once: "You cannot use the same sensor",
  NetworkError: "Network Error",
  Playback: "Playback",
  PlaybackSettings: "Playback Settings",
  Find: "Find",
  error_devicetype_has_noevents: "There are not events",
  error_device_log_artifact_not_image: "There are not images in the log",
  error_device_log_artifact_not_video: "There are not videos in the log",
  error_device_log_artifact_not_found: "There are not artifacts",

  FaceDetection: "Face Detection",
  MotionDetection: "Motion Detection",
  ObjectDetection: "Object Detection",
  PersonDetection: "Person Detection",
  PetDetection: "Animal Detection",
  SoundDetection: "Sound Detection",
  VehicleDetection: "Vehicle Detection",
  EventNoavailable:
    "This device is not recording this event because in the account settings are turned off.",
  to: "to",
  VideoNotFoundInDevice: "Video not found in the device",
  SelectDateToReview: "Select the date and time for the video playback you wish to review",
  DeviceIsNotRecording: "This device is not recording events because the settings are turned off.",
  Person: "Person",
  Pet: "Animals",
  Object: "Object",
  Sound: "Sound",
  ChangeEventSettings: "Change Event Settings",
  EventThatYouWant: "You can activate or deactivate the events that you want to record",
  ChangeHardwareZoom: "Change Hardware Zoom",
  HardwareZoom: "Hardware Zoom",
  EventSettings: "Event Settings",
  ZoomIn: "Zoom In",
  ZoomOut: "Zoom Out",
  Screenshot: "Screenshot",
  Fullscreen: "Fullscreen",
  MissingPermissions: "Missing Permissions",
  Connecting: "Connecting",
  NumberOfArtifacts: "Total Artifacts",
  NumberOfEvents: " Total Events",
  ActivityPerCategory: "Total Activity",
  EventLogs: "Event logs",
  TableofEventLogsPerDevice: "Table of event logs per device",
  TableofArtifactsPerDevice: "Table of artifacts per device",
  CamerasDashboard: "Cameras Dashboard",
  EventImage: "Event Image",
  ConnectingToDevice: "Connecting to the Device",
  AlocityDevice: "Alocity Device",
  EnterTheSerialNumber: "Enter the serial number",
  Upload: "Upload",
  error_device_partnerid: "Device not associated with a partner",
  error_device_not_eligible: "Device not eligible for the operation",
  SelectDateRange: "Select the range of time to check",
  TimeDifferenceExceeds1Hour: "Time difference exceeds 1 hour",
  Top: "Top",
  LastImage: "Last Image",
  Preview: "Preview",
  AutoFocus: "Auto Focus",
  RowsPerPage: "Rows per page",
  BatchSelfie: "Batch Selfie",
  BatchSelfieDescription: "Send selfie enrollment invitation to all the users.",
  TextConfirmationSelfieBatch:
    "Pressing the 'Send' button will dispatch an enrollment invitation to all presently active users linked to your account who do not have a registered facial profile.",
  Text2ConfirmationSelfieBatch:
    "Kindly make certain that all your users possess functioning email addresses or phone numbers to facilitate the reception of the invitation link.",
  Text3ConfirmationSelfieBatch:
    "It's important to note for security reasons that this link will remain valid for a duration of 24 hours.",
  CameraInfo: "Camera Info",
  Activity: "Activity",
  RTSP: "RTSP",
  Chunks: "Chunks",
  Hours: "Hour (s)",
  Minutes: "Minutes",
  MEDIA_ERR_SRC_NOT_SUPPORTED: "ERROR SRC NOT SUPPORTED",
  MEDIA_ERR_NETWORK: "ERROR NETWORK ",
  ERROR_VIDEO: "ERROR",
  ErrorHLSSupport: "Some codecs are not supported in this browser",
  SelectDevice: "Choose one of the cameras associated with the zone of the event for playback",
  CameraVideoEvent: "Cameras Video Event",
  DevicesNoFound: "Devices not found in this zone",
  NotFound: "Not Found",
  ActiveCamera: "Active",
  DesactiveCamera: "Disconnected",
  ActivityByCameras: "Activity by Camera",
  RecentActivity: "Recent Activity Feed",
  NoConnectCamera:
    "At this moment, there are not connected cameras, please switch options in the filter.",
  NoDisconnectCamera:
    "At this moment, there are not disconnected cameras, please switch options in the filter.",
  ThereAreNotCameras: "There are not cameras available",
  TableAccessLogs: "User Access Logs Table",
  ModeVideoPlayBack: "Playback Modes:",
  Last10Log: "(Last 10 Logs)",
  NA: "N/A",
  SDLow: "SD Low",
  CloudHigh: "Cloud High",
  CloudLow: "Cloud Low",
  EventArtifacts: "Event Artifacts",
  Downloading: "Downloading",
  ShowEvents: "Show Events",
  ThereIsADownloadInProccess: "There is a download in process",
  NotHadwareZoom: "The camera does not have a zoom functionality.",
  Clips: "Clips:",
  Hour: "Hour",
  ModeSDLow: "The video playback is in SD mode",
  PreparingVideo: "Preparing video",
  FailedConnectionWithDevice: "Failed the connection with the device",
  LimitCharacters: "Maximum character limit is 36",
  NoEmptyFields: "There cannot be empty fields",
  Autogenerate: "Autogenerate",
  Export: "Export",
  SelectTheDateRange: "Select the date range",
  GreaterThan30Days: "Greater than 30 days",
  DateRange: "Date Range",
  ExportToCSV: "Export to CSV",
  IncludeAvatar: "Include User Picture",
  NoDataFound: "No data found",
  HideURL: "Hide the URL",
  SettingsAreOff: "Recording video is disabled.",
  UploadingVideoNow: "Uploading video, please wait ...",
  Operations: "Operations",
  SelecWhatOperationYouWantOverTheRangeOfTimes:
    "Select what operation you want to perform over the range of times",
  Start: "Start",
  Reactivate: "Reactivate",
  Suspend: "Suspend",
  SuspendedUserInformation:
    "The user is suspended. Please reactivate their account to view the information",
  ConfirmReactivation: "Confirm Reactivation",
  ConfirmSuspension: "Confirm Suspension",
  AreYouSureDelete: "Are you sure you want to delete this",
  AreYouSureDelete1: "Are you sure you want to delete these",
  ToConfirmText: "Confirm that you want to delete this information by typing the word",
  DeniedSuspended: "Denied (Suspended)",
  TheLinkExpired: "The link expired",
  TheLinkExpiredInLive: "There is no access to the live stream.",
  Appliances: "Appliances",
  AppliancesDetails: "Appliances Details",
  VideoSources: "Video Sources",
  AppliancesInfo: "Appliances Info",
  StreamURL: "Stream URL",
  AddVideoSource: "Add Video Source",
  AppendURL: "Append URL",
  IPCannotLetters: "The IP cannot contain letters",
  UnmodifiedFields: "None of the fields were modified",
  error_gateway_hasno_streams: "Gateway has no streams",
  ChangeGateway: "Change Gateway",
  ChangeVideoSource: "Change Camera",
  SelectNameAnotherGateway: "Select the name of the gateway where you want to move the camera",
  Enabled: " Enabled",
  SameSerialNumber: "The entered gateway is currently associated with the video source",
  error_invalid_expiration: "Error invalid expiration",
  ThereNotVideoSource: "There is no video available",
  Directory: " Directory",
  EditDirectory: "Edit Directory",
  DropToFindTheRangeOfTime: "Drop to find the range of time",
  intercom_call_request: "Intercom call request",
  UnitType: "Unit Type",
  EntryId: "Entry Id",
  Unit: "Unit",
  Apt: "Apartment",
  Home: "Home",
  Intercoms: "Intercom",
  ThisOperationCanTakeTime: "This operation can take time",
  InPorgress: "In porgress",
  Lobby: "Lobby",
  FirmwareVersion: "Firmware Version",
  ThereAreNotCatalog: "There is not directory with that name",
  NoAudio: "The camera does not have an audio option",
  Mute: "Mute",
  Unmute: "Unmute",
  InsufficientDataAvailable: "Insufficient data available to reproduce the next segment",
  ExpirationDate: "Expiration Date",
  Until: "Until",
  AddPin: "Add PIN",
  error_pin_expiration: "Error, PIN expired",
  AddNewPIN: "Add new PIN",
  OutDoorDevice: "Outdoor Device",
  PlaybackAudioEnabled: "Enable Playback Audio",
  wf_integration_error: "Integration Error",
  DeviceDetails: "Device Details",
  IntercomInfo: "Intercom Info",
  Qrcode: "QR Code",
  AddQrCode: "Add QR Code",
  AddNewQrCode: "Add new QR Code",
  playbackAudioDisabled: "At least one of the video options needs to be enabled",
  DeviceName: "Device Name",
  HealthCheck: "Health check",
  EnableTheInputWeingand: "Please enable the wiegand input option in the device settings",
  NoneUnitType: "N/A",
  WIFISettings: "WIFI Settings",
  SSID: "SSID",
  CannotExceed: "Cannot exceed more than 25 characters",
  WIFIConnected: "WIFI Connected",
  OSDP: "OSDP",
  DefaultKey: "Default Key",
  CurrentKey: "Current Key",
  BaudRates: "BaudRates",
  Adress: "Address",
  error_invalid_osdp_key: "error invalid osdp key",
  DisableRelay: "OSDP enabled. No relay options are available",
  DisableDoorContactPosition: "OSDP enabled. Only option None is available",
  DisableExitButtonPositionExternal: "OSDP enabled. No Exit Buttons options are available",
  DisableOptions: "Please verify the mentioned options for the update",
  WifiDeviceConnected: "Device connected to WIFI",
  WifiDeviceDisconnected: "Device disconnected from WIFI",
  RangeAddressOSDP: "Range [0-126]",
  StartDateLaterThanExpirationDate: "The start date cannot be later than the expiration date",
  InvalidKey: "The value must be equal to 32 characters",
  DeliveryEntry: "Delivery Entry",
  OptionIntercom: "Option only for Intercom devices",
  OnlyOneVisitors: "Visitors can only have one valid ",
  error_pin_guest_expiration_inconsistent:
    "The valid PIN times must be within the visitor's active time range",
  SetupIntercomPresentation: "Setup intercom welcome message.",
  MessageMax100: "Message (Max. 100 characters)",
  IntercomMessage: "Intercom Message",
  UploadImage: "Upload Image",
  MessageEmpty: "Message name cannot be empty",
  UnmodifiedMessage: "The message was not modified",
  WebhookInactive: "Webhook inactive",
  GenerateQRCode: "Generate QR Code",
  Print: "Print",
  VirtualDirectory: "Virtual Directory QR Code",
  Cloud: "Cloud",
  error_user_missing_required_schedule: "Error, user has no access to one or more devices",
  ConnectionStatus: "Connection status",
  Secure: "Secure ",
  Messages: "Messages",
  MassMessaging: "Mass Messaging",
  MassMessagingSetup: "Mass Messaging using text, email or voice message.",
  VoiceAndText: "Voice & Text",
  IntercomWelcomeMessage: "Intercom Welcome Message",
  Message: "Message",
  ErrorMax300: "Max. 300 characters",
  Sent: "Sent",
  Communication: "Communication",
  Definemessagerecipients: "Define message recipients",
  Selecthowyouwillsendthemessage: "Select how you will send the message",
  Writethemessagethatyouwantsend: "Write the message that you want send (Max. 300 characters)",
  Elevator: "Elevator",
  ElevatorSettings: "Elevator settings",
  Floors: "Floors",
  CreateOne: "Create One",
  CreateMultiple: "Create Multiple",
  AddFloors: "Add Floors",
  CreateOneFloor: "Create One Floor",
  EditFloor: "Edit Floor",
  NumberOfFloors: "Number of floors",
  error_relay_in_use:
    "Error, the relay is in use, you must remove the relationship with the floors",
  error_relays_attached: "Error, there are floor assigned to ECUs",
  ElevatorController: "Elevator Controller Unit (ECU)",
  error_invalid_relay_panel_address: "Error, invalid relay panel address",
  NumberOfRelays: "Number of Relays",
  PanelAddress: "ECU Address",
  RelayReleaseTime: "Relay Release Time",
  AddElevator: "Add ECU",
  AssignRelaysToFloors: "Assign Relays to Floors",
  EditRelayPanel: "Edit Relay Panel",
  PanelAddressRange: "ECU Address Range: [1-7]",
  RelaysPerFloors: "Relay per Floors",
  error_floor_not_found: "Error, floor not found",
  ConfirmAutogenerate: "Confirm Autogenerate",
  DeleteAll: "Delete All",
  RelaysTable: "Relays Table",
  error_invalid_number_of_floors: "Error,invalid number of floors",
  error_floor_create: "Error, floor create",
  error_schedules_attached: "Error, schedules attached",
  error_floor_delete: "Error, floor delete",
  error_floor_update: "Error, floor update",
  error_reused_relay_panel_address: "Error, reused relay panel address",
  error_relay_panel_not_created: "Error, relay panel not created",
  error_relay_panel_not_found: "Error, relay panel not found",
  error_relays_not_deleted: "Error, relays not deleted",
  error_relay_panel_not_deleted: "Error, relay panel not deleted",
  error_relay_panel_not_updated: "Error, relay panel not updated",
  error_relay_floor: "Error, relay floor",
  FloorsInTheSchedule: "Floors In The Schedule",
  ConfirmRemoveTheFloorFromTheSchedule: "Confirm removing the floor from the schedule",
  FloorsThereNotInTheSchedule: "Floors that are not in the schedule",
  ThereAreNotFloorsToAdd: "There are not floors to add",
  ThereAreNotFloorsToRemove: "There are not floors to remove",
  ConfirmAddAllFloorsToTheSchedule: "Confirm add all floors to the schedule",
  ConfirmRemoveAllFloorsFromTheSchedule: "Confirm remove all floors from the Schedule",
  UsersAssignedToTheFloor: "Users assigned to the floor",
  Notifications: "Notifications",
  PassRequiresApproval: "Passes requires approval",
  ApprovalRequest: "Approval Request",
  ApprovalRequests: "Approval Requests",
  Autoassign: "Autoassign",
  Floor: "Floor",
  RemoveFromFloorSchedule:
    "You need to go to the 'Info' tab in the device details. Then, go to the elevators section and remove the relationship between relays and floors by clicking the 'Assign relays by floors' button",
  error_catalogs_attached: "Error, catalogs attached",
  error_relay_information: "Error, relay information",
  error_relay_time: "Error, relay time",
  RangeReleaseTime: "Floor activation time range: [1-60]",
  ActivationTime: "Activation Time (seconds)",
  PendingApprovalRequest: "Pending Approval Requests",
  Request: "Request",
  Requests: "Requests",
  error_request_not_found: "Error request not found",
  error_request_not_changeable: "Error request not changeable",
  error_invalid_request_type: "Error invalid request type",
  error_invalid_request_status: "Error invalid request status",
  error_request_expired: "Error request expired",
  ApproveRejectUserAccessToTheirVisitors: "Approve / Reject the user requests",
  Approve: "Approve",
  Reject: "Reject",
  Approved: "Approved",
  Rejected: "Rejected",
  Requester: "Requester",
  ExcludeExpired: "Exclude Expired",
  IncludeExpired: "Include Expired",
  ConfirmApproval: "Confirm Approval",
  ConfirmRejection: " Confirm Rejection",
  ModifiedAt: "Processed at",
  SentQRCode: "Sent QR Code",
  Delivery: "Delivery",
  Requested: "Requested",
  FloorsNotWorkWithOpenAutomatic: "(floors are disabled when 'Open door automatically' is enabled)",
  InformationToDelete: "Information to delete",
  AllFloors: "All Floors",
  ChooseYourSite: "Choose your site",
  error_floor_duplicated: "Error, floor duplicated",
  error_pin_not_created: "Error, PIN not created",
  error_pin_not_deleted: "Error, PIN not deleted",
  error_pin_not_found: "Error, PIN not found",
  error_pin_not_updated: "Error, PIN not updated",
  error_venue_not_found: "Error, PIN not found",
  LivenessLevel: "Liveness Level",
  OutdoorActive: "The outdoor option in settings is active",
  ExpiredLockdown: "Expired Lockdown",
  error_company_address_state_is_invalid: "Error, company address state is invalid",
  CallLandline: "Mobile Phone/Landline",
  CallMode: "Call Mode",
  MobileApp: "Mobile App",
  AllowDoorCalls: "Allow calls",
  TimeAllowCalls: "Allowed calls",
  LobbyCallaMode: "The lobby call mode is available only on the mobile app",
  CurrentNetwork: "Current Network",
  Ethernet: "Ethernet",
  AddNetwork: "Add Network",
  OnlyCredentialsToAccesApp:
    "You only have credentials to access the Alocity App. Please, download our app and sign in there.",
  EventVolume: "Event Volume",
  CallVolume: "Call Volume",
  WirelessLock: "Wireless Lock",
  WirelessLockInfo: "Wireless Lock Info",
  DoNotDisturb: "Do Not Disturb",
  DeviceAlocity: "Device Alocity",
  FrontDesk: "Front Desk",
  PropertyInfo: "Property Info",
  EveryDay: "Every Day",
  Weekdays: "Weekdays",
  Weekends: "Weekends",
  Every: "Every",
  DNDEnabled: "Enabled",
  CallHistory: "Mobile Phone/Landline Call History",
  error_device_floors_relationship: "Error, device floors relationship",
  CallHistoryDescription: "(Mobile App Calls are not included in this report)",
  ShouldHaveIntercomDevices: "(You should have intercom devices to see the call history)",
  Ringing: "Ringing",
  Answered: "Answered",
  Hangup: "Hangup",
  HangupDevice: "Hangup Device",
  TextInfoCallVolume: "The minimum call volume value is 5",
  Paint: "Paint",
  Walls: "Walls",
  VideoWalls: "Video Walls",
  VideoWallWelcomeText:
    "Video walls enable you to monitor multiple live cameras simultaneously, allowing you to create layouts that incorporate cameras from various locations.",
  LicensePlate: "License Plate",
  LicensePlateDetection: "License Plate Detection",
  NoStreamingAvailable: "No streaming available",
  SelectAWall: "Select a Wall",
  VideoWallName: "Video Wall Name",
  CreateNewWall: "Create New Wall",
  SelectTheNumberOfVideoYouWantInyourWall:
    "Select how many cameras you would like to view at once.",
  ClickOrDragAndDropCameras: "Drag and drop cameras to add to the wall",
  AddWall: "Add Wall",
  error_user_videowall_not_found: "Video Wall not found",
  error_user_videowall_has_streams: "You cannot delete a Video Wall that has assigned cameras.",
  error_user_videowallstream_not_found: "Camera not found in the Video Wall",
  error_user_videowallstream_position_conflict: "Camera assignment conflict due to position",
  error_user_videowall_is_full: "Video Wall is full",
  EditVideoWall: "Edit Video Wall",
  NetworkErrorOccurred: "Network error occurred.",
  NoCamerasAvailable: "No Cameras Available",
  Scan: "Scan",
  CharacterLimit: "Cannot be longer than 50 characters",
  ONVIFIPs: "ONVIF IPs",
  NoONVIFfound: "No ONVIF IPs available",
  RTSPIPs: "RTSP IPs",
  NoRTSPfound: "No RTSP IPs available",
  error_gateway_stream_inuse: "RTSP Stream registered already",
  Audio: "Audio",
  Ptz: "Ptz",
  RtspUrl: "RTSP URL",
  Encoding: "Encoding",
  Resolution: "Resolution (WxH)",
  BothSelected: "Both types of URLs have already been selected",
  InferenceURL: "Inference URL",
  BadStatus: "Review connection credentials and Stream URL",
  Scanning: "Scanning...",
  CameraSlots: "Camera Slots",
  Menu: "Menu",
  ApplianceDisconnected: "Appliance Disconnected",
  Undelete: "Undelete",
  DeletedUsers: "Deleted Users",
  RecoverUser: "Recover user",
  RequiresAuthentication: "Password confirmation is required to proceed with user recovery",
  IncorrectPassword: "Error: Incorrect password",
  RequestBlocked: "Error: Request blocked due to unusual activity. Please try again later",
  Verify: "Verify",
  RecoveringUser: "Recovering User",
  ToConfirmTextUndelete: "Confirm that you want to recover this user by typing the word",
  CloseEdit: "Close Edit",
  Manufacturer: "Manufacturer",
  NotUsed: "Not Used",
  Used: "Used",
  ScanOnvif: "Scan ONVIF streams of the IP",
  TypeCredentials: "Type your credentials",
  ONVIF: "ONVIF",
  ScannedCameras: "Scanned cameras",
  CameraAdded: "Camera is already added",
  ErrorCredentials: "Credentials error",
  UnknownSite: "Unknown Site",
  UnknownZone: "Unknown Zone",
  AdvancedSearch: "Advanced Search",
  General: "General",
  Past: "Past",
  days: "days",
  PersonOfInterest: "Person of Interest",
  SearchPOIName: "Search POI name",
  AddPOI: "Add POI",
  TypeNameAndLastName: "Type your first and last name",
  UploadPhotoVisible: "Please upload a photo where your face is visible",
  ChoosePhoto: "Choose a photo to upload",
  CameraOnlineAppliances: "Camera online in appliances ",
  Faces: "Faces",
  LicensePlates: "License Plates",
  DownloadImage: "Download Image",
  error_poi_notfound: "Person of Interes not found",
  Alerts: "Alerts",
  Appearances: "Appearances",
  Selected: "Selected",
  AddPersonOfInterest: "Add Person of Interest",
  AllFaces: "All Faces",
  SelectPOI: "Select the person of interest",
  MissingData: "Missing Data",
  PoiTrigger: "POIs will trigger",
  error_workflow_missing_trigger_targets_4: "No person of interest trigger target found",
  error_gateway_slots_used: "Error, slots used",
  error_artifactface_notfound: "Error, face not found",
  InvalidDateTimeRangeProvided: "Invalid date/time range provided",
  Coincidences: "Coincidences",
  error_poi_has_workflow: "Person of interest has active workflows",
};
export default translationEN;
