import { axiosCall, axiosInstance } from "./axiosConfig";
import { axiosMethods, endPoints, QueryFilter } from "../Models/api";
import { CombinedArtifactFaceModel, UserModel, VisitorModel } from "../Models/users";
import qs from "qs";

export const addPushNotificationId = (idConnection: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/external-system-id",
    method: axiosMethods.post,
    data: {
      externalSystemId: idConnection,
      externalSystemType: 0,
    },
  });
};

export const getCheckedId = (idConnection: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/external-system-id",
    method: axiosMethods.get,
    params: {
      externalId: idConnection,
    },
  });
};

export const deletePushNotificationId = (idConnection: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/external-system-id",
    method: axiosMethods.delete,
    data: {
      externalSystemId: idConnection,
      externalSystemType: 0,
    },
  });
};

export const getUsers = (filter: QueryFilter, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/page",
    method: axiosMethods.get,
    params: filter,
    signal: signal,
  });
};

export const getUsersDeleted = (filter: QueryFilter, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/inactive/page",
    method: axiosMethods.get,
    params: filter,
    signal: signal,
  });
};

export const getUser = (
  userId: string,
  includeCards?: boolean,
  anyStatus?: boolean,
  signal?: AbortSignal
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/" + userId,
    method: axiosMethods.get,
    params: {
      includeCards,
      anyStatus,
    },
    signal,
  });
};

export const updateUserInfo = (data: UserModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/" + data.id,
    method: axiosMethods.put,
    data: data,
  });
};

export const addNewUser = (data: UserModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user",
    method: axiosMethods.post,
    data: data,
  });
};

export const deleteAUser = (id: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/" + id,
    method: axiosMethods.delete,
  });
};

export const updateLanguage = (language: string) => {
  const data = {
    localeISO2: language,
  };
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/" + "language",
    method: axiosMethods.put,
    data,
  });
};

export const getUserAccess = (token: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user-access",
    method: axiosMethods.get,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getLoggedUserDetails = (appToken?: string, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user",
    method: axiosMethods.get,
    headers: appToken
      ? {
          Authorization: `Bearer ${appToken}`,
        }
      : undefined,
    signal,
  });
};
////////VISITOR////////////
export const addNewVisitor = (data: VisitorModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.guestManagement + "/guest",
    method: axiosMethods.post,
    data: data,
  });
};

export const addNewVisitorWithSchedules = (data: VisitorModel) => {
  delete data.visitingUserFullName;
  const idsSch: number[] | null = data.schedules ? data.schedules?.map((value) => value.id) : null;
  delete data.schedules;
  return axiosCall(axiosInstance, {
    url: endPoints.guestScheduleManagment + "/schedules/guest",
    method: axiosMethods.post,
    data: {
      ...data,
      scheduleIds: idsSch,
      visitingUserId: data.visitingUserId ?? null,
    },
  });
};

export const getVisitors = (filter: QueryFilter, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.guestManagement + "/guest/page",
    method: axiosMethods.get,
    params: filter,
    signal: signal,
  });
};

export const deleteAVisitor = (id: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.guestManagement + "/guest/" + id,
    method: axiosMethods.delete,
  });
};

export const updateVisitorInfo = (data: VisitorModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.guestManagement + "/guest/" + data.id,
    method: axiosMethods.put,
    data: data,
  });
};

export const getVisitor = (id: string, includeCards?: boolean) => {
  return axiosCall(axiosInstance, {
    url: endPoints.guestManagement + "/guest/" + id,
    method: axiosMethods.get,
    params: {
      includeCards,
    },
  });
};

export const getTheListOfUsers = (userIds: string[]) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/by-ids",
    method: axiosMethods.post,
    data: userIds,
  });
};

export const addMobileCredentialToUser = (userId: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/mobile-credential/" + userId,
    method: axiosMethods.post,
  });
};

export const deleteMobileCredentialToUser = (userId: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/mobile-credential/" + userId,
    method: axiosMethods.delete,
  });
};

export const suspendUser = (id: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/" + id + "/suspend",
    method: axiosMethods.put,
  });
};

export const reactivateUser = (id: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/" + id + "/reactivate",
    method: axiosMethods.put,
  });
};

export const addNewVideoWall = (videoWallName: string, videoWallCount: number) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/videowall",
    method: axiosMethods.post,
    data: {
      videoWallName,
      videoWallCount,
    },
  });
};
export const updateVideoWall = (id: string, videoWallName: string, videoWallCount: number) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/videowall/" + id,
    method: axiosMethods.post,
    data: {
      videoWallName,
      videoWallCount,
    },
  });
};
export const deleteVideoWall = (id: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/videowall/" + id,
    method: axiosMethods.delete,
  });
};
export const getTheVideoWall = (id: string, includeStreams?: boolean, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/videowall/" + id,
    method: axiosMethods.get,
    params: {
      includeStreams,
    },
    signal: signal,
  });
};
export const getMyVideoWalls = (includeStreams?: boolean, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/videowalls",
    method: axiosMethods.get,
    params: {
      includeStreams,
    },
    signal: signal,
  });
};
export const addVideoStreamToWall = (
  videoWallId: string,
  position: number,
  video_source_id?: string,
  serialNumber?: string
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/videowall/stream",
    method: axiosMethods.post,
    data: {
      videoWallId,
      position,
      video_source_id,
      serialNumber,
    },
  });
};
export const deleteVideoStream = (id: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/videowall/stream/" + id,
    method: axiosMethods.delete,
  });
};

export const putUndeleteUser = (userId: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/" + userId + "/undelete",
    method: axiosMethods.put,
  });
};

export const getAllPersonOfInterest = (queryFilter: QueryFilter, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/poi/paged",
    method: axiosMethods.get,
    signal: signal,
    params: queryFilter,
  });
};

export const postAddPOI = (firstName: string, lastName: string, picture: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/poi",
    method: axiosMethods.post,
    data: {
      firstName,
      lastName,
      picture,
    },
  });
};

export const deletePOI = (id: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/poi/" + id,
    method: axiosMethods.delete,
  });
};

export const getArtifactsFaces = (queryFilter: QueryFilter, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/event-logs/face-artifacts/paged",
    method: axiosMethods.get,
    signal: signal,
    params: queryFilter,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const getPOIDetail = (id: string, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/poi/" + id,
    method: axiosMethods.get,
    signal: signal,
  });
};
export const getPOIArtifacts = (id: string, queryFilter: QueryFilter, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/poi/" + id + "/artifacts",
    method: axiosMethods.get,
    signal: signal,
    params: queryFilter,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const getTheListPOIs = (pois: string[]) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/poi/multiple",
    method: axiosMethods.get,
    params: {
      pois,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};
