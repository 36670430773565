import { AccessLogResultType } from "./accessLog";
import { DeviceModel } from "./devices";
import { GroupModel } from "./groups";
import { SensorModel } from "./sensor";
import { SiteModel, ZoneModel } from "./settings";
import { PersonOfInterestModel, UserModel } from "./users";

interface AccountWorkflowVM {
  id: string;
  code: string; //this is the trigger id
  description: string;
  workflow: WorkflowDefinition;
  isActive: boolean;
  activeOnHolidays: boolean;
  activeFrom?: string; //only the time part
  timeDuration?: number; //in seconds
  expiresOn?: string;
  createdAt: string;
  activeDaysExpression?: string;
}
export interface WorkflowModel extends AccountWorkflowVM {
  usersData?: UserModel[];
  groupsData?: GroupModel[];
  devicesData?: DeviceModel[];
  sitesData?: SiteModel[];
  zonesData?: ZoneModel[];
  targetUsersData?: UserModel[];
  targetGroupsData?: GroupModel[];
  targetDevicesData?: DeviceModel[];
  targetSitesData?: SiteModel[];
  targetZonesData?: ZoneModel[];
  poiData?: PersonOfInterestModel[];
  sunday?: boolean;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  sensorsData?: SensorModel[];
  targetSensorsData?: SensorModel[];
}
export interface WorkflowDefinition {
  devices?: string[];
  users?: string[];
  zones?: number[];
  sites?: number[];
  guests?: string[];
  groups?: number[];
  accessLogTypes?: AccessLogResultType[];
  actorTypes: EWorkflowActorType[];
  actorTargets: ActorTargetVM;
  sensors?: string[];
  poIs?: string[];
}
interface ActorTargetVM {
  devices?: string[];
  zones?: number[];
  sites?: number[];
  users?: string[];
  groups?: number[];
  sensors?: string[];
}
export enum EWorkflowActorType {
  SendSMS = 0,
  SendEmail = 1,
  LockDownDoor = 2,
  GeneralLockDown = 3,
  SoundDoorAlarm = 4,
  GeneralSoundAlarm = 5,
  CaptureImage = 6,
  CaptureVideo = 7,
  IssueSensorCommand = 8,
}
interface WorkflowActorVM {
  actorType: EWorkflowActorType;
  description: string;
}
export interface WorkflowActorModel extends WorkflowActorVM {
  //
}
interface WorkflowCodeVM {
  code:
    | EWorkflowCodeTypes.wf_personofinterest_observed
    | EWorkflowCodeTypes.wf_accesslog_unauthorized
    | EWorkflowCodeTypes.wf_door_keepopen
    | EWorkflowCodeTypes.wf_door_offline
    | EWorkflowCodeTypes.wf_door_openremote
    | EWorkflowCodeTypes.wf_door_settingschanged
    | EWorkflowCodeTypes.wf_door_forceopen
    | EWorkflowCodeTypes.wf_accesslog_authorized
    | EWorkflowCodeTypes.wf_lockdown_start
    | EWorkflowCodeTypes.wf_lockdown_end
    | EWorkflowCodeTypes.wf_sensor_triggered
    | EWorkflowCodeTypes.wf_ioerror_detected
    | EWorkflowCodeTypes.wf_integration_error
    | EWorkflowCodeTypes.wf_mobile_accessnotgranted;
  description: string;
}
export enum EWorkflowCodeTypes {
  wf_personofinterest_observed = "wf.personofinterest.observed", //Person Of Interest Observed
  wf_accesslog_unauthorized = "wf.accesslog.unauthorized", //unauthorized Access
  wf_door_keepopen = "wf.door.keepopen", //Door Is Kept Open
  wf_door_offline = "wf.door.offline", //Door Is Offline
  wf_door_openremote = "wf.door.openremote", //Door Opened Remotely
  wf_door_settingschanged = "wf.door.settingschanged", //Door Settings Changed
  wf_door_forceopen = "wf.door.forceopen", //Door Force Open
  wf_accesslog_authorized = "wf.accesslog.authorized", //Authorized Access
  wf_lockdown_start = "wf.lockdown.start",
  wf_lockdown_end = "wf.lockdown.end",
  wf_sensor_triggered = "wf.sensor.triggered", //Sensor Triggered
  wf_ioerror_detected = "wf.ioterror.detected", //IoT Error Detected
  wf_integration_error = "wf.integration.error",
  wf_mobile_accessnotgranted = "wf.mobile.accessnotgranted",
}
export interface WorkflowCodeModel extends WorkflowCodeVM {
  //
}

export interface LockdownPlanInfo {
  isLockdownActive: boolean;
  anyLockdownWorkflows: boolean;
  startTimeOfLockdown: string;
  lockdownPlanDuration: number; //in minutes => if =0 => infinite
}
