const DEFAULT_AVATAR = "/images/people-placeholder-img.png";
const CONTACT_SENSOR = "images/contact-sensor.jpg";
const MOTION_SENSOR = "images/motion-sensor.jpg";
const PUSH_BUTTON = "images/panic-button-sensor.png";
const RELAY = "images/relay-sensor.png";
const ALARM = "images/alarm-sensor.png";
const LIGHT_SWITCH = "images/light-switch-sensor.png";
const DOM_CAMERA = "/images/dom-camera.png";
const ALOCITY_READER = "/images/Alocity_reader_perspective front02_HD.png";
const DEFAULT_IMAGE_CAMERA = "/images/default-image-camera.jpg";
const ALOCITY_LOGO = "/images/designed/alocity-logo.svg";
const ALOCITY_LOGO_SHORT = "/images/alocity-mobile-logo-small.png";
const ALOCITY_IMAGE_CAMERA = "/images/Alocity_camera_perspective front_HD.png";
const ALOCITY_INTERCOM = "/images/Alocity_Intercom_AI-1000.png";
const ALOCITY_LOGO_COLOR = "/images/alocity-logo-color.png";
const ALOCITY_LOGO_WHITE = "/images/alocity-logo-white.png";

const Images = {
  DEFAULT_AVATAR,
  CONTACT_SENSOR,
  MOTION_SENSOR,
  PUSH_BUTTON,
  RELAY,
  ALARM,
  LIGHT_SWITCH,
  DOM_CAMERA,
  ALOCITY_READER,
  DEFAULT_IMAGE_CAMERA,
  ALOCITY_LOGO,
  ALOCITY_LOGO_SHORT,
  ALOCITY_IMAGE_CAMERA,
  ALOCITY_INTERCOM,
  ALOCITY_LOGO_COLOR,
  ALOCITY_LOGO_WHITE,
};
export default Images;
