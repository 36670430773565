import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import PlaybackCloud from "../../Utils/Custom/VideoDoorWebRTC/PlaybackCloud";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
interface Props {
  url: string;
  deviceName: string;
  from: string;
  to: string;
  innerWidth: number;
  innerHeight: number;
  timeZoneOffset: string;
}
export default function ShowPublicPlaylistVideo({
  url,
  deviceName,
  from,
  to,
  innerWidth,
  innerHeight,
  timeZoneOffset,
}: Props) {
  const { t } = useTranslation();
  //https://dev-portal.alocity.com/action?mode=cloudVideo&url=https%3A%2F%2Fstreaming-test-4.s3.wasabisys.com%2F4%2Fmisc%2F0006687290CB-S21401039%2F01414b3f-8b43-4110-98b9-eb880dd7c817-shared-2023-10-18T12%3A00%3A00Z-2023-10-18T12%3A59%3A59Z.m3u8%3FAWSAccessKeyId%3D7Q9OK8INREN2B09W34J9%26Expires%3D1697767312%26Signature%3Dvjs%252FfulV0Pz4dcSUeByFmqQvNq4%253D&deviceName=dome camera 8Mp&from=2023-10-18T12%3A00%3A00&to=2023-10-18T12%3A59%3A59
  const [show, setShow] = useState(true);
  const serialNumber = url?.split("/")[5]; //https://streaming-test-4.s3.us-east-1.wasabisys.com/4/misc/3C62F004232D-2109DLT000014

  return (
    <Grid container justify="center" style={{ width: "100%" }}>
      <Grid container justify="center">
        <img
          src={"/images/alocity-logo-color.png"}
          alt="Alocity logo"
          style={{ objectFit: "cover", width: "15%", marginTop: "8px" }}
        />
      </Grid>
      <Button
        style={{ marginTop: "16px" }}
        variant="contained"
        color="primary"
        onClick={() => setShow(true)}
      >
        {t("OpenVideo")}
      </Button>
      <Grid item xs={12}>
        {show && (
          <PlaybackCloud
            serialNumber={serialNumber}
            deviceName={deviceName}
            identifierId={serialNumber}
            startDateTime={from + "Z"}
            endDateTime={to + "Z"}
            // onNoVideo={() => setAwsVideoNotFound(true)}
            onClose={() => {
              setShow(false);
            }}
            showEvents={false}
            publicUrl={url}
            isPublic={true}
            customHeightProp={innerHeight}
            customWidthProp={innerWidth}
            timeZoneOffset={timeZoneOffset}
          />
        )}
      </Grid>
    </Grid>
  );
}
