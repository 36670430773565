import { axiosCall, axiosInstance } from "./axiosConfig";
import { axiosMethods, endPoints } from "../Models/api";
import { DeviceAndVideoSourceModel, VideoSourceModel } from "../Models/devices";
import { CancelToken } from "axios";

export const getTheVideoSourcesFromAccount = () => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/videosources",
    method: axiosMethods.get,
  });
};

export const getTheVideoSourcesFromGateway = (serialNumber: string, all: boolean) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/videosources",
    method: axiosMethods.get,
    params: {
      all,
    },
  });
};

export const postAddVideoSource = (data: VideoSourceModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/videosource",
    method: axiosMethods.post,
    data: data,
  });
};

export const getVideoSourceDetails = (videoSourceId: string, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/videosource/" + videoSourceId,
    method: axiosMethods.get,
    signal,
  });
};

export const putUpdateVideoSource = (
  data: DeviceAndVideoSourceModel | VideoSourceModel,
  videoSourceId: string
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/videosource/" + videoSourceId,
    method: axiosMethods.put,
    data: data,
  });
};

export const getSyncVideoSource = (serialNumber: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/videosources/sync",
    method: axiosMethods.get,
  });
};

export const getChangeStatusVideoSource = (videoSourceId: string, enable: boolean) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/videosource/" + videoSourceId + "/status",
    method: axiosMethods.put,
    params: {
      enable,
    },
  });
};

export const putChangeVideoSourceToAnotherGateway = (
  serialNumber: string,
  videoSourceId: string
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/videosource/" + videoSourceId,
    method: axiosMethods.put,
  });
};

export const getVideoSourceWebRTCCredentials = (
  videoSourceId: string,
  serialNumber: string,
  signal?: AbortSignal
) => {
  return axiosCall(axiosInstance, {
    url:
      endPoints.securableManagement +
      "/credentials/" +
      serialNumber +
      "/videosource/" +
      videoSourceId,
    method: axiosMethods.get,
    signal: signal,
  });
};

export const postVideosourceScan = (serialNumber: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/videosources/scan",
    method: axiosMethods.post,
  });
};

export const postScanOnvif = (serialNumber: string, xaddr: string, user: string, pass: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/device/" + serialNumber + "/onvif/scan",
    method: axiosMethods.post,
    data: [
      {
        xaddr: xaddr,
        user: user,
        pass: pass,
      },
    ],
  });
};

export const connectToLocalPlayback = (
  id: string,
  playlistName: string,
  from: string,
  to: string
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.deviceManagement + "/videosource/" + id + "/localplaylist",
    method: axiosMethods.post,
    params: {
      playlistName,
      from,
      to,
    },
  });
};
