import { UserModel } from "./users";

export interface AccountProvisioningVM {
  account: AccountModel;
  user: UserModel;
  captchaToken?: string;
}
interface account {
  id: number;
  name: string;
  licenseId: number;
}
export interface AccountModel extends account {}

export enum StatusType {
  Inactive = 0,
  Active = 1,
  Pending = 2,
  Suspended = 3,
  PaymentDue = 4,
  Expired = 5,
}

export interface SubscriptionProductsModel {
  products: ProductModel[];
  publicKey: string;
}

export interface ProductModel {
  id: string;
  x_displayName: string;
  description: string;
  x_pricePaymentId: string;
  price: number;

  priceId: string;
  productId: string;
  interval: "year" | "month";
  intervalCount: number;
  extraInfo: string;
}

export const PortalIds = {
  AccessControl: "AccessControl",
  Cameras: "Cameras",
  Sensors: "Sensors",
  Gateway: "Gateway",
};
interface AccountCampaignVM {
  createdAt: string;
  isCompleted: boolean;
  expiration: string;
}
export interface AccountCampaignModel extends AccountCampaignVM {}
export interface UpdateFirmwareModel {
  accountId?: number;
  scheduleDateTime?: Date;
}

interface AccountSettingsVM {
  emailNotificationsEnabled: boolean;
  smsNotificationsEnabled: boolean;
  notification_email?: string;
  notification_number?: string;
  notification_email2?: string;
  notification_number2?: string;
  allowUnencryptedCards: boolean;
  lockdownPlanDuration?: number; //null or 0 is infinite
  lockdownPlanEnabled: boolean;
  cardEndianType: ECardEndianType;
  cardOffsetFacility: number;
  cardOffsetFacilityStart: number;
  cardOffsetNumber: number;
  cardOffsetNumberStart: number;
  two_factor_open_door?: number;
  accountLogo?: string | null;
  broadcast?: string | null;
  passRequiresApproval: boolean;
}
export interface AccountSettingsModel extends AccountSettingsVM {}

export enum ECardEndianType {
  None = 0,
  Little = 1,
  Big = 2,
}

export interface MassMessagingModel {
  groups: number[];
  users: string[];
  guests: string[];
  sendText: boolean;
  sendVoice: boolean;
  sendEmail: boolean;
  message: string;
}
export interface AddonsModel {
  accountLevel: boolean;
  code: AddonsEnum;
  created: string;
  description: string;
  deviceLevel: boolean;
  id: string;
  isActive: boolean;
}
export enum AddonsEnum {
  MASSNOTIFY = "MASSNOTIFY",
  ELEVATOR = "ELEVATOR",
}

export enum TypePeopleDetection {
  All = "All",
  PeopleOfInterest = "PeopleOfInterest",
}
